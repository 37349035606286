import { createTrans } from 'i18n/trans';
import { Trans } from 'react-i18next';

export const TransTitle = createTrans({
  bookNewTrip: <Trans i18nKey="title.bookNewTrip" defaults="Book a new trip" />,
  emailTemplate: (
    <Trans i18nKey="title.emailTemplate" defaults="Email template" />
  ),
  passengers: <Trans i18nKey="title.passengers" defaults="Passengers" />,
  passengersAllocation: (
    <Trans
      i18nKey="title.passengersAllocation"
      defaults="Passengers allocation"
    />
  ),
  passengersReAccommodated: (
    <Trans
      i18nKey="title.passengersReAccommodated"
      defaults="passengers re-accommodated"
    />
  ),
  passengersReAccommodatedError: (
    <Trans
      i18nKey="title.passengersReAccommodatedError"
      defaults="errors occurred while re-accommodating passengers"
    />
  ),
  passengersReAccommodatedQty: (
    <Trans
      i18nKey="title.passengersReAccommodatedQty"
      defaults="(0-1)[{{count}} passenger re-accommodated];(2-inf)[{{count}} passengers re-accommodated];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  passengersUnresolvedQty: (
    <Trans
      i18nKey="title.passengersUnresolvedQty"
      defaults="(0-1)[{{count}} passenger remains unresolved];(2-inf)[{{count}} passengers remain unresolved];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  problematicPassengers: (
    <Trans
      i18nKey="title.problematicPassengers"
      defaults="Problematic passengers"
    />
  ),
  pushTemplate: (
    <Trans i18nKey="title.pushTemplate" defaults="Push notification template" />
  ),
  reAccommodatePassengers: (
    <Trans
      i18nKey="title.reAccommodatePassengers"
      defaults="Re-accommodate passengers"
    />
  ),
  searchByTrips: (
    <Trans i18nKey="title.searchByTrips" defaults="Search by trips" />
  ),
  selectAncillaries: (
    <Trans i18nKey="title.selectAncillaries" defaults="Select ancillaries" />
  ),
  selectInventoryClasses: (
    <Trans
      i18nKey="title.selectInventoryClasses"
      defaults="Select inventory classes"
    />
  ),
  selectLines: <Trans i18nKey="title.selectLines" defaults="Select lines" />,
  selectServices: (
    <Trans i18nKey="title.selectServices" defaults="Select services" />
  ),
  selectStop: <Trans i18nKey="title.selectStop" defaults="Select stop" />,
  selectStops: <Trans i18nKey="title.selectStops" defaults="Select stops" />,
  selectTrip: <Trans i18nKey="title.selectTrip" defaults="Select trip" />,
  selectTrips: <Trans i18nKey="title.selectTrips" defaults="Select trips" />,
  sendNotifications: (
    <Trans i18nKey="title.sendNotifications" defaults="Send notifications" />
  ),
  smsTemplate: <Trans i18nKey="title.smsTemplate" defaults="SMS template" />,
  stopMismatchWarning: (
    <Trans
      i18nKey="title.stopMismatchWarning"
      defaults="Stop mismatch warning"
    />
  ),
  templateValidation: (
    <Trans i18nKey="title.templateValidation" defaults="Template validation" />
  ),
  tripPassengerOverview: (
    <Trans
      i18nKey="title.tripPassengerOverview"
      defaults="Trip passenger overview"
    />
  ),
});
