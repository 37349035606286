import { createSelector, selector } from 'store/utils';

const selectReAccommodatePassengersList = selector(
  (state) => state.reAccommodate.passengersList
);
export const reAccommodatePassengersListSelector = createSelector(
  selectReAccommodatePassengersList
);

const selectReAccommodateActiveStep = selector(
  (state) => state.reAccommodate.activeStep
);
export const reAccommodateActiveStepSelector = createSelector(
  selectReAccommodateActiveStep
);

const selectReAccommodateNewTripsFilter = selector(
  (state) => state.reAccommodate.newTripsFilter
);
export const reAccommodateNewTripsFilterSelector = createSelector(
  selectReAccommodateNewTripsFilter
);

const selectReAccommodateNewTripsList = selector(
  (state) => state.reAccommodate.newTripsList
);
export const reAccommodateNewTripsListSelector = createSelector(
  selectReAccommodateNewTripsList
);

const selectReAccommodateNewDetailedTrips = selector(
  (state) => state.reAccommodate.newDetailedTrips
);
export const reAccommodateNewDetailedTripsSelector = createSelector(
  selectReAccommodateNewDetailedTrips
);

const selectReAccommodateNewTrip = selector(
  (state) => state.reAccommodate.newTrip
);
export const reAccommodateNewTripSelector = createSelector(
  selectReAccommodateNewTrip
);

const selectReAccommodateNewDetailedTrip = selector(
  ({ reAccommodate: { newTrip, newDetailedTrips } }) =>
    newTrip && newDetailedTrips?.[newTrip.tripId]
);
export const reAccommodateNewDetailedTripSelector = createSelector(
  selectReAccommodateNewDetailedTrip
);

const selectReAccommodateMismatchesDictionary = selector(
  (state) => state.reAccommodate.mismatchesDictionary
);
export const reAccommodateMismatchesDictionarySelector = createSelector(
  selectReAccommodateMismatchesDictionary
);

const selectReAccommodateTrackingId = selector(
  (state) => state.reAccommodate.trackingId
);
export const reAccommodateTrackingIdSelector = createSelector(
  selectReAccommodateTrackingId
);
