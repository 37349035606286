import type { FC } from 'react';
import { useCallback } from 'react';
import {
  Button,
  FormProvider,
  makeClassificationOptions,
  useBusinessEntitiesByType,
  useForm,
} from '@fleet/shared';
import { Box, Stack } from '@mui/material';
import { NotificationFiltersFields } from './NotificationFiltersFields';
import { TransButton } from 'i18n/trans/button';
import { useDispatch, useSelector } from 'store/utils';
import {
  getPotentialAddressees,
  setActiveStep,
  setNotificationFilters,
} from 'features/notifications/notificationsActions';
import { Step } from 'consts';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import _map from 'lodash/map';
import { notificationFiltersSelector } from 'features/notifications/notificationsSelectors';
import { useCarrierInitValue } from 'hooks/useCarrierInitValue';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { AddresseesFilter } from 'dto/notificationContent';
interface NotificationFiltersProps {}

export const NotificationFilters: FC<NotificationFiltersProps> = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const formValues = useSelector(notificationFiltersSelector);
  const businessEntities = useSelector(businessEntitiesSelector);
  const { CARRIER } = useBusinessEntitiesByType(businessEntities);
  const carrierOptions = makeClassificationOptions(CARRIER);
  const currentBusinessEntityId = useCarrierInitValue(carrierOptions);

  const onSubmit = useCallback(
    // Remove serviceCodes overwrite once endpoint fixed
    async (values: AddresseesFilter) => {
      const mappedValues = {
        ...values,
        tripIds: _map(values.tripIds || [], 'id'),
        lineIds: _map(values.lineIds || [], 'id'),
        fromStopIds: _map(Array.from((values.fromStops ?? []).values()), 'id'),
        toStopIds: _map(Array.from((values.toStops ?? []).values()), 'id'),
        ancillaryIds: _map(values.ancillaryIds || [], 'id'),
        limit: 9999,
        offset: 0,
      };

      const { departureFrom, departureTo, tripIds } = mappedValues;
      const departureTimesOrTripsFilled = Boolean(
        (departureFrom && departureTo) || tripIds.length
      );

      if (!departureTimesOrTripsFilled) {
        alert.error(<TransAlert i18nKey="departureTimesOrTripsFilled" />);
        return;
      }

      const potentialAddressees = await dispatch(
        getPotentialAddressees(mappedValues)
      ).unwrap();

      if (!potentialAddressees.length) {
        alert.error(<TransAlert i18nKey="noPassengersFound" />);
        return;
      }

      dispatch(setNotificationFilters(values));
      dispatch(setActiveStep(Step.Addressees));
    },
    [dispatch, alert]
  );

  const { form, handleSubmit } = useForm<AddresseesFilter>({
    onSubmit,
    initialValues: formValues || {},
    subscription: {
      values: true,
    },
  });

  const handleReset = useCallback(() => {
    form.reset({
      carrierId: currentBusinessEntityId,
    });
  }, [form, currentBusinessEntityId]);

  return (
    <FormProvider form={form}>
      <Box sx={{ minHeight: 0, overflow: 'auto' }}>
        <form onSubmit={handleSubmit}>
          <NotificationFiltersFields />
        </form>
      </Box>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'end',
          py: 3,
          pr: 2,
          boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.2)',
        }}
        spacing={1}
      >
        <Button onClick={handleReset} variant="text">
          <TransButton i18nKey="reset" />
        </Button>
        <Button
          icon="arrow-right"
          onClick={() => handleSubmit()}
          sx={{ ml: 2 }}
          data-testid="notification-filters-submit"
        >
          <TransButton i18nKey="notificationAddressees" />
        </Button>
      </Stack>
    </FormProvider>
  );
};
