import React, { FC } from 'react';
import { Button, Icon, Loadable, Modal, TabPanel, Tabs } from '@fleet/shared';
import { validatedNotificationsSelector } from 'features/notifications/notificationsSelectors';
import { useSelector } from 'store/utils';
import { Box, Typography } from '@mui/material';
import { ValidatedNotificationByLanguage } from 'dto/api/notifications-management';
import { ValidationTab } from 'routes/SendNotifications/content/validation/ValidationTab';
import { TransButton } from 'i18n/trans/button';
import { validationModalLoadingSelector } from 'features/loading/loadingSelectors';
import { TransTitle } from 'i18n/trans/title';

interface ValidationModalProps {
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export const ValidationModal: FC<ValidationModalProps> = ({
  isOpen,
  onSubmit,
  onClose,
}) => {
  const loading = useSelector(validationModalLoadingSelector);
  const validatedNotifications = useSelector(validatedNotificationsSelector);
  const errorInAnyTabs = validatedNotifications
    .map((tab) => hasAnyErrors(tab))
    .some((v) => v);

  return (
    <Modal
      data-testid="validation-modal"
      title={<TransTitle i18nKey="templateValidation" />}
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      actionButton={
        <Button
          variant="contained"
          startIcon={<Icon name="mail" size={20} />}
          onClick={() => {
            onSubmit();
          }}
          disabled={errorInAnyTabs}
          data-testid="validation-modal-submit"
        >
          <TransButton i18nKey="sendNotifications" />
        </Button>
      }
    >
      <Loadable loading={loading}>
        {validatedNotifications.length ? (
          <Tabs value={validatedNotifications[0].language?.id}>
            {validatedNotifications.map((tab, i) => {
              const languageId = tab.language?.id || '';
              const languageName = tab.language?.name || '';
              return (
                <TabPanel
                  value={languageId}
                  key={languageId}
                  label={
                    <Typography>
                      {languageName}
                      &nbsp;
                      {
                        <Box
                          component="span"
                          sx={{
                            color: hasAnyErrors(tab) ? 'red' : 'green',
                            top: '-5px',
                            position: 'relative',
                          }}
                        >
                          ●
                        </Box>
                      }
                    </Typography>
                  }
                >
                  <ValidationTab tabData={tab} index={i} />
                </TabPanel>
              );
            })}
          </Tabs>
        ) : (
          <></>
        )}
      </Loadable>
    </Modal>
  );
};

const hasAnyErrors = (tab: ValidatedNotificationByLanguage) =>
  Boolean(
    tab.emailContentError ||
      tab.smsContentError ||
      tab.pushNotificationContentError
  );
