import { createAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/utils';
import {
  AdmissionSearchGroup,
  AdmissionFilterByTrips,
} from 'dto/passengersAllocation';
import { api } from '@fleet/shared';
import { PostAdmissionsByTripsResponse } from 'dto/api/deviations-management';
import { parseFilterByTrips } from 'helpers/parse';

export const setPassengersAllocationFilterTab =
  createAction<AdmissionSearchGroup>(
    'passengersAllocation/setPassengersAllocationFilterTab'
  );

export const setPassengersAllocationFilterByTrips = createAction<
  Partial<AdmissionFilterByTrips>
>('passengersAllocation/setPassengersAllocationFilterByTrips');

export const clearPassengersAllocationByTrips = createAction(
  'passengersAllocation/clearPassengersAllocationByTrips'
);

export const getPassengersAllocationByTrips = createAsyncThunk<
  PostAdmissionsByTripsResponse,
  Partial<AdmissionFilterByTrips>
>(
  'passengersAllocation/getPassengersAllocationByTrips',
  async (values, { getState, dispatch }) => {
    if (values) {
      dispatch(setPassengersAllocationFilterByTrips(values));
    }

    const {
      passengersAllocation: { filterByTrips },
      common: { currentBusinessEntityId },
    } = getState();

    const filter = parseFilterByTrips(filterByTrips, currentBusinessEntityId);

    return (
      await api.post<PostAdmissionsByTripsResponse>(
        '/trips/admissions/search',
        filter,
        {
          shouldRelocatePaginationAndSortingParamsFromPayloadToQueryString:
            true,
        }
      )
    ).data;
  }
);
