import { Table } from '@fleet/shared';
import { makeStyles } from '@mui/styles';
import { ReAccommodateDetailedTripStop } from 'dto/api/deviations-management';
import { reAccommodateNewDetailedTripSelector } from 'features/reAccommodate/reAccommodateSelectors';
import { useDetailedTripColumns } from 'hooks/passengersAllocation/useDetailedTripColumns';
import { FC, useMemo } from 'react';
import { useTable } from 'react-table';
import { useSelector } from 'store/utils';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 120,
  },
}));

interface SelectTripMismatchModalStopsTableProps {}

export const SelectTripMismatchModalStopsTable: FC<SelectTripMismatchModalStopsTableProps> =
  () => {
    const classes = useStyles();
    const newDetailedTrip = useSelector(reAccommodateNewDetailedTripSelector);
    const data = useMemo(
      () => newDetailedTrip?.routeStops ?? [],
      [newDetailedTrip]
    );

    const { columns, hiddenColumns } = useDetailedTripColumns(true);

    const table = useTable<ReAccommodateDetailedTripStop>({
      data,
      columns,
      initialState: { hiddenColumns },
    });

    return <Table table={table} className={classes.root} />;
  };
