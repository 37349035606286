import {
  Button,
  Icon,
  SearchResult,
  SortingRules,
  Table,
  useIndeterminateRowSelectCheckbox,
  useRowActive,
  useTable,
} from '@fleet/shared';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { TableCaption } from '@fleet/shared/components/Table/TableCaption';
import { Divider, Stack, Typography } from '@mui/material';
import { passengersAllocationLoadingSelector } from 'features/loading/loadingSelectors';
import { setReAccommodatePassengersList } from 'features/reAccommodate/reAccommodateActions';
import { passengersAllocationTabSelector } from 'features/passengersAllocation/passengersAllocationSelectors';
import { passengersSearchCallbacks } from 'hooks/search/usePassengersSearchForm';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useMemo, FC, useCallback } from 'react';
import { useHistory } from 'react-router';
import { usePagination, useRowSelect, useSortBy } from 'react-table';
import { PassengersAllocationSearch } from 'routes/PassengersAllocation/Search/PassengersAllocationSearch';
import { useDispatch, useSelector } from 'store/utils';
import { Admission } from 'dto/api/deviations-management';
import { usePassengersAllocationColumns } from 'hooks/passengersAllocation/usePassengersAllocationColumns';

interface PassengersAllocationTableProps {}

export const PassengersAllocationTable: FC<PassengersAllocationTableProps> =
  () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const tab = useSelector(passengersAllocationTabSelector);
    const { passengersSelector, getPassengers, passengersFilterSelector } =
      passengersSearchCallbacks[tab];
    const passengers = useSelector(passengersSelector);
    const filter = useSelector(passengersFilterSelector);
    const loading = useSelector(passengersAllocationLoadingSelector);
    const data = useMemo(() => passengers?.items ?? [], [passengers]);

    const handlePageChange = useCallback(
      async (paginationParams: PaginationParams) =>
        dispatch(getPassengers({ ...filter, ...paginationParams })),
      [dispatch, filter, getPassengers]
    );

    const handleSortingChange = useCallback(
      async (sortBy: SortingRules) => {
        await dispatch(
          getPassengers({
            ...filter,
            sortBy,
          })
        ).unwrap();
      },
      [dispatch, filter, getPassengers]
    );

    const { columns, hiddenColumns } = usePassengersAllocationColumns();

    const table = useTable<Admission>(
      {
        data,
        columns,
        initialState: { hiddenColumns },
        total: passengers?.totalCount,
        offset: passengers?.offset,
        limit: filter.limit,
        sortBy: filter.sortBy,
        onPageChange: handlePageChange,
        onSortingChange: handleSortingChange,
      },
      useSortBy,
      usePagination,
      useRowActive,
      useRowSelect,
      useIndeterminateRowSelectCheckbox
    );

    const { selectedFlatRows } = table;

    const handleReAccommodateClick = useCallback(() => {
      dispatch(
        setReAccommodatePassengersList(
          selectedFlatRows.map(({ original }) => original)
        )
      );

      history.replace('/passengers-allocation/re-accommodate');
    }, [dispatch, history, selectedFlatRows]);

    return (
      <>
        <PassengersAllocationSearch />
        <Divider />
        <SearchResult results={!!data.length} loading={loading}>
          <Table
            data-testid="passengers-allocation-table"
            caption={
              <TableCaption>
                <Stack direction="row" alignItems="center" sx={{ gap: 2 }}>
                  <Typography variant="body2" color="text.secondary">
                    <TransSubtitle
                      i18nKey="selectedQty"
                      values={{
                        count: selectedFlatRows.length,
                      }}
                    />
                  </Typography>
                  {Boolean(selectedFlatRows.length) && (
                    <Button
                      variant="text"
                      startIcon={<Icon name="ticket-change" />}
                      onClick={handleReAccommodateClick}
                      sx={{ p: 0 }}
                      data-testid="re-accommodation-submit"
                    >
                      <TransButton i18nKey="reAccommodation" />
                    </Button>
                  )}
                </Stack>
              </TableCaption>
            }
            table={table}
          />
        </SearchResult>
      </>
    );
  };
