import { Grid } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import React, { FC } from 'react';
import {
  Button,
  FormProvider,
  Icon,
  useForm,
  useBusinessEntitiesByType,
  makeClassificationOptions,
  BusinessEntityType,
  SelectOwnerField,
} from '@fleet/shared';
import { GetAncillaryTypesSearchParams } from 'dto/api/product-management';
import { useCarrierInitValue } from 'hooks/useCarrierInitValue';
import { useSelector } from 'store/utils';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';

interface Props {
  onSubmit: (filter: Partial<GetAncillaryTypesSearchParams>) => Promise<void>;
}

export const AncillariesSearchForm: FC<Props> = ({ onSubmit }) => {
  const businessEntities = useSelector(businessEntitiesSelector);
  const { CARRIER } = useBusinessEntitiesByType(businessEntities);
  const carrierOptions = makeClassificationOptions(CARRIER);
  const currentBusinessEntityId = useCarrierInitValue(carrierOptions);

  const { form, handleSubmit, submitting } =
    useForm<GetAncillaryTypesSearchParams>({
      onSubmit,
      subscription: { dirty: true, submitting: true },
      initialValues: {
        ownerId: currentBusinessEntityId,
      },
    });

  return (
    <FormProvider form={form}>
      <form onSubmit={handleSubmit}>
        <Grid container columns={3} spacing={2} alignItems="flex-end">
          <Grid item xs={1}>
            <SelectOwnerField
              businessEntities={businessEntities}
              allowedBusinessEntityTypes={[BusinessEntityType.CARRIER]}
              disabled
            />
          </Grid>
          <Grid item xs="auto" sx={{ ml: 'auto' }} alignItems="flex-end">
            <Button
              variant="contained"
              type="submit"
              startIcon={<Icon name="search" size={20} />}
              disabled={submitting}
            >
              <TransButton i18nKey="search" />
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
