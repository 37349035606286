import React, { FC } from 'react';
import { Button, Icon, ExternalLink } from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import { Box, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'store/utils';
import {
  notificationSummarySelector,
  trackingIdSelector,
} from 'features/notifications/notificationsSelectors';
import {
  resetNotificationStore,
  setActiveStep,
} from 'features/notifications/notificationsActions';
import { Step } from 'consts';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { useTranslation } from 'react-i18next';

export const NotificationsSummary: FC = () => {
  const dispatch = useDispatch();
  const summaryData = useSelector(notificationSummarySelector);
  const trackingId = useSelector(trackingIdSelector);
  const { t } = useTranslation();
  const handleCreateMoreNotifications = () => {
    dispatch(resetNotificationStore());
    dispatch(setActiveStep(Step.Filters));
  };

  return (
    <>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        data-testid="notification-summary"
      >
        <Stack>
          <Box sx={{ textAlign: 'center', pb: 1 }}>
            <Icon name="sent" size={80} />
          </Box>

          <Stack sx={{ textAlign: 'center' }}>
            <Typography>
              <TransSubtitle i18nKey="notificationMassSending" />
            </Typography>
            {trackingId && (
              <Typography>
                <TransSubtitle i18nKey="infoAboutMassSending" />{' '}
                <ExternalLink
                  path={`/Admin/ProcessLogList/Logs/${trackingId}`}
                  underline="none"
                  content={t(
                    'subtitle.processPassengersReAccommodationLogs',
                    'Process logs'
                  )}
                />
                .
              </Typography>
            )}
            <Typography>
              <TransSubtitle
                i18nKey="notificationsSentToEmailsAndPhoneNumbers"
                values={{
                  emailsCount: summaryData.totalEmailRecipients,
                  smsCount: summaryData.totalSmsRecipients,
                }}
              />
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <Stack
        direction="row"
        sx={{ ml: 'auto', mr: 4, mt: 3, mb: 2, justifyContent: 'end' }}
        spacing={1}
      >
        <Button variant="text">
          <ExternalLink
            path={`/Admin/ProcessLogList/Logs/${trackingId}`}
            underline="none"
            content={t(
              'subtitle.processPassengersReAccommodationLogs',
              'Process logs'
            )}
          />
        </Button>
        <Button
          icon="add"
          onClick={() => handleCreateMoreNotifications()}
          sx={{ ml: 2 }}
        >
          <TransButton i18nKey="createMoreNotifications" />
        </Button>
      </Stack>
    </>
  );
};
