import { useCallback, useEffect, useRef, useState } from 'react';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { getToken } from '@fleet/shared';

interface Props<T> {
  processId: string | null | undefined;
  onNext: (value: T) => Promise<void> | void;
  onReset?: () => Promise<void> | void;
  onComplete?: () => Promise<void> | void;
}

export const useSignalRProgress = <T>({
  processId,
  onNext,
  onReset,
  onComplete,
}: Props<T>) => {
  const connection = useRef<HubConnection>(
    new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_TARGET}/process-progress`, {
        accessTokenFactory: () => getToken(),
      })
      .configureLogging(LogLevel.Information)
      .build()
  );
  const [isLoading, setIsLoading] = useState(Boolean(processId));

  const stopConnection = useCallback(async () => {
    setIsLoading(false);
    onReset?.();
    await connection.current.stop();
  }, [onReset]);

  const startConnection = useCallback(async () => {
    setIsLoading(true);

    try {
      await connection.current.start();
      connection.current.stream('StreamProcessLogs', processId).subscribe({
        next: onNext,
        complete: () => {
          stopConnection();
          onComplete?.();
        },
        error: stopConnection,
      });
    } catch (e) {
      stopConnection();
    }
  }, [onComplete, onNext, processId, stopConnection]);

  useEffect(() => {
    if (processId) startConnection();

    return () => {
      stopConnection();
    };
  }, [processId, startConnection, stopConnection]);

  return isLoading;
};
