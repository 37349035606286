import { getStopParams } from '@fleet/shared';
import { AdmissionFilterByTrips } from 'dto/passengersAllocation';
import _omit from 'lodash/omit';

export const parseFilterByTrips = (
  filterByTrips: Partial<AdmissionFilterByTrips>,
  currentBusinessEntityId: number
) => {
  return {
    carrierId: currentBusinessEntityId,
    ..._omit(filterByTrips, [
      'originStop',
      'destinationStop',
      'stopOne',
      'stopTwo',
      'trips',
    ]),
    originStopId: getStopParams(filterByTrips.originStop, 'guid')[0],
    destinationStopId: getStopParams(filterByTrips.destinationStop, 'guid')[0],
    stopOneId: getStopParams(filterByTrips.stopOne, 'guid')[0],
    stopTwoId: getStopParams(filterByTrips.stopTwo, 'guid')[0],
    ticketTag: filterByTrips.ticketTag?.value && filterByTrips.ticketTag,
    tripIds: filterByTrips.trips?.map(({ id }) => id),
  };
};

export const parseFullName = (
  firstName: string | null,
  lastName: string | null
) => {
  const arrayFullName = [firstName, lastName].filter(Boolean);

  return arrayFullName.length ? arrayFullName.join(' ') : ' - ';
};
