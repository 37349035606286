import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { Button, Table, TableColumns, useIndeterminateRowSelectCheckbox, } from '@fleet/shared';
import { useRowSelect, useTable } from 'react-table';
import { Box, CardContent, Stack, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { TransButton } from 'i18n/trans/button';
import { useDispatch, useSelector } from 'store/utils';
import { setActiveStep, setNotificationAddressees, } from 'features/notifications/notificationsActions';
import { Step } from 'consts';
import {
  notificationAddresseesSelector,
  notificationFilterListSelector,
} from 'features/notifications/notificationsSelectors';
import { Nullable } from '@fleet/shared/@types/openapi-typescript';
import { getAddresseesSummaryCounts } from 'helpers/notificationAddressees';
import _reduce from 'lodash/reduce';
import _pick from 'lodash/pick';

export interface AddresseeRow {
  legId: Nullable<number>;
  email?: Nullable<string>;
  language?: Nullable<string>;
  tripName?: Nullable<string>;
  originStopZone?: string;
  destinationStopZone?: string;
}

export interface AddresseePayload {
  passengerId: string;
  firstName?: Nullable<string>;
  lastName?: Nullable<string>;
  languageId: string;
  bookingId: string;
  legId: Nullable<number>;
  email?: Nullable<string>;
  phoneNumber?: Nullable<string>;
  phoneAreaCode?: Nullable<string>;
  customerProfileId?: Nullable<string>;
}

export interface AddresseeData extends AddresseeRow, AddresseePayload {}

export const NotificationAddressees: FC = () => {
  const dispatch = useDispatch();

  const columns = useMemo<TableColumns<AddresseeData>>(
    () => [
      {
        accessor: 'email',
        Header: <TransTableHead i18nKey="email" />,
        width: '120px',
      },
      {
        accessor: 'phoneNumber',
        Header: <TransTableHead i18nKey="phone" />,
        width: '120px',
      },
      {
        accessor: 'language',
        Header: <TransTableHead i18nKey="language" />,
        width: '120px',
      },
      {
        accessor: 'tripName',
        Header: <TransTableHead i18nKey="tripName" />,
        width: '120px',
      },
      {
        accessor: 'originStopZone',
        Header: <TransTableHead i18nKey="originStopZone" />,
        width: '120px',
      },
      {
        accessor: 'destinationStopZone',
        Header: <TransTableHead i18nKey="destinationStopZone" />,
        width: '120px',
      },
    ],
    []
  );

  const previouslySelectedAddressees = useSelector(
    notificationAddresseesSelector
  );
  const addressees = useSelector(notificationFilterListSelector);
  const data: Array<AddresseeData> = useMemo(
    () =>
      addressees.map(({ addressee, trip, bookingId, legId, passengerId }) => ({
        ..._pick(addressee, [
          'email',
          'firstName',
          'lastName',
          'phoneNumber',
          'phoneAreaCode',
          'customerProfileId',
        ]),
        tripName: trip.name,
        originStopZone: trip.originStopName,
        destinationStopZone: trip.destinationStopName,
        passengerId,
        language: addressee.culture.name,
        languageId: addressee.culture.id,
        bookingId,
        legId,
      })),
    [addressees]
  );

  const getRowId = useCallback((row: AddresseeData) => String(row.legId!), []);
  const selectedRowIds = useMemo(
    () =>
      _reduce(
        previouslySelectedAddressees,
        (acc: Record<string, boolean>, obj) => {
          acc[String(obj.legId!)] = true;
          return acc;
        },
        {}
      ),
    [previouslySelectedAddressees]
  );

  const table = useTable<AddresseeData>(
    {
      data,
      columns,
      total: data.length,
      getRowId,
      initialState: {
        selectedRowIds,
      },
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const handleSubmit = () => {
    const chosenPassengers = table.selectedFlatRows.map((row) => row.original);

    dispatch(setNotificationAddressees(chosenPassengers));
    dispatch(setActiveStep(Step.Content));
  };
  const selectedAddresseesCount = table.selectedFlatRows.length;

  const {
    potentialAddresseesCount,
    potentialLanguagesCount,
    emailsCount,
    phoneNumbersCount,
  } = getAddresseesSummaryCounts(addressees);

  return (
    <>
      <Table
        data-testid="notification-addressees-table"
        caption={
          <CardContent sx={{ p: 2 }} data-testid="addressees-table-caption">
            <Stack direction="row" alignItems="baseline" spacing={1}>
              <Typography variant="subtitle" fontWeight={700}>
                <TransSubtitle i18nKey="assignees" />
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <TransSubtitle
                  i18nKey="potentialAddresseesQty"
                  values={{ count: potentialAddresseesCount }}
                />
                {', '}
                <TransSubtitle
                  i18nKey="selectedLanguages"
                  values={{ count: potentialLanguagesCount }}
                />
                {', '}
                <TransSubtitle
                  i18nKey="emailsQty"
                  values={{ count: emailsCount }}
                />
                {', '}
                <TransSubtitle
                  i18nKey="phoneNumbersQty"
                  values={{ count: phoneNumbersCount }}
                />
              </Typography>
            </Stack>
          </CardContent>
        }
        table={table}
      />
      <Stack
        direction="row"
        sx={{ py: 2, px: 3, boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.2)' }}
      >
        <Typography fontWeight={400}>
          <TransSubtitle
            i18nKey="selectedAddressees"
            values={{ count: selectedAddresseesCount }}
          />
        </Typography>
        <Box sx={{ flex: 1 }}></Box>
        <Stack direction="row">
          <Button
            variant="text"
            onClick={() => {
              dispatch(setActiveStep(Step.Filters));
            }}
          >
            <TransButton i18nKey="back" />
          </Button>
          <Button
            onClick={() => {
              table.toggleAllRowsSelected(false);
            }}
            variant="text"
          >
            <TransButton i18nKey="clearSelection" />
          </Button>
          <Button
            icon="arrow-right"
            onClick={() => handleSubmit()}
            sx={{ ml: 2 }}
            data-testid="notification-addressees-submit"
            disabled={!table.selectedFlatRows.length}
          >
            <TransButton i18nKey="notificationContent" />
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
