import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { RadioGroupField } from '@fleet/shared';
import { EmailTabs } from './EmailTabs';
import { TransLabel } from 'i18n/trans/label';
import { Highlight } from 'components/Highlight';

interface EmailProps {
  hasRecipients: boolean;
}

const OPTIONS = [
  {
    name: 'unique',
    label: 'Only unique emails/phone numbers (w/o tickets)',
    value: 'unique',
  },
  {
    value: 'all',
    label: 'All emails/phone numbers (with tickets)',
    name: 'all',
  },
];

export const Email: FC<EmailProps> = ({ hasRecipients }) => {
  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <Typography variant="subtitle" fontWeight={700}>
        <TransSubtitle i18nKey="email" />
      </Typography>
      {hasRecipients ? (
        <>
          <RadioGroupField
            name="sendNotificationTo"
            options={OPTIONS}
            label={
              <Typography fontWeight={400}>
                <TransLabel i18nKey="sendNotificationTo" />:
              </Typography>
            }
            defaultValue={OPTIONS[0].value}
            inline
          />
          <Highlight>
            <EmailTabs />
          </Highlight>
        </>
      ) : (
        <Highlight>
          <Typography>
            <TransSubtitle i18nKey="noEmailReceivers" />
          </Typography>
        </Highlight>
      )}
    </Stack>
  );
};
