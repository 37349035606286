import {
  DateField,
  FormProvider,
  formSubmit,
  makeClassificationOptions,
  SearchForm,
  SelectField,
  StopsSelect,
  TextField,
  useBusinessEntitiesByType,
  useForm,
} from '@fleet/shared';
import { Grid } from '@mui/material';
import { ReAccommodateTripsFilter } from 'dto/reAccommodate';
import { setPassengersAllocationFilterByTrips } from 'features/passengersAllocation/passengersAllocationActions';
import { getReAccommodateNewTripsList } from 'features/reAccommodate/reAccommodateActions';
import { reAccommodateNewTripsFilterSelector } from 'features/reAccommodate/reAccommodateSelectors';
import { useCarrierInitValue } from 'hooks/useCarrierInitValue';
import { useTripOptionsSearch } from 'hooks/passengersAllocation/useTripOptionsSearch';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { pick } from 'lodash';
import { ClassificationGroup } from 'dto/classification';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { SearchControl } from 'components/Search/SearchControl';

interface ReAccommodateSelectTripSearchProps {}

export const ReAccommodateSelectTripSearch: FC<ReAccommodateSelectTripSearchProps> =
  () => {
    const dispatch = useDispatch();
    const filter = useSelector(reAccommodateNewTripsFilterSelector);

    const businessEntities = useSelector(businessEntitiesSelector);
    const { CARRIER } = useBusinessEntitiesByType(businessEntities);
    const carrierOptions = makeClassificationOptions(CARRIER);
    const carrierIdFormInitValue = useCarrierInitValue(carrierOptions);
    const tripStatusOptions = useClassificationOptions(
      ClassificationGroup.TRIP_STATUS
    );

    const defaultValues = useMemo(
      () => ({ carrierId: carrierIdFormInitValue }),
      [carrierIdFormInitValue]
    );

    const initialValues = useMemo<Partial<ReAccommodateTripsFilter>>(
      () => ({
        carrierId: carrierIdFormInitValue,
        ...filter,
      }),
      [carrierIdFormInitValue, filter]
    );

    const onSubmit = useCallback(
      async (filter: Partial<ReAccommodateTripsFilter>) =>
        formSubmit(async () => {
          (document.activeElement as HTMLInputElement)?.blur?.();
          await dispatch(
            getReAccommodateNewTripsList({ ...filter, offset: 0 })
          );
        }),
      [dispatch]
    );

    const { form, handleSubmit, values } = useForm({
      initialValues,
      onSubmit,
      subscription: { values: true },
    });

    const { carrierId } = values;

    const { lineNumberOptions, serviceOptions } =
      useTripOptionsSearch(carrierId);

    const handleReset = useCallback(() => {
      form.reset();
      dispatch(
        setPassengersAllocationFilterByTrips({
          ...defaultValues,
          ...pick(filter, ['limit', 'offset']),
        })
      );
    }, [form, dispatch, defaultValues, filter]);

    return (
      <SearchForm title={<TransSubtitle i18nKey="searchTrips" />}>
        <FormProvider form={form}>
          <form onSubmit={handleSubmit} data-testid="select-trip-search-form">
            <Grid container columns={5} spacing={2}>
              <Grid item xs={1}>
                <SelectField
                  name="carrierId"
                  required
                  label={<TransField i18nKey="carrier" />}
                  options={carrierOptions}
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="lineIds"
                  label={<TransField i18nKey="lineNumbers" />}
                  options={lineNumberOptions}
                  multiple
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="serviceCodes"
                  label={<TransField i18nKey="serviceCodes" />}
                  options={serviceOptions}
                  multiple
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="name"
                  label={<TransField i18nKey="tripName" />}
                />
              </Grid>
              <Grid item xs={1}>
                <DateField
                  name="departurePeriod"
                  label={<TransField i18nKey="departurePeriod" />}
                  selectsRange
                  isClearable
                />
              </Grid>
              <Grid item xs={1}>
                <StopsSelect
                  name="stopOne"
                  label={<TransField i18nKey="originStop" />}
                  allowSearch
                />
              </Grid>
              <Grid item xs={1}>
                <StopsSelect
                  name="stopTwo"
                  label={<TransField i18nKey="destinationStop" />}
                  allowSearch
                />
              </Grid>
              <Grid item xs={1}>
                <DateField
                  name="createdOnPeriod"
                  label={<TransField i18nKey="createdOnPeriod" />}
                  selectsRange
                  isClearable
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="tripStatus"
                  label={<TransField i18nKey="tripStatus" />}
                  options={tripStatusOptions}
                  showEmptyOption
                />
              </Grid>
              <SearchControl isDividerHidden handleReset={handleReset} />
            </Grid>
          </form>
        </FormProvider>
      </SearchForm>
    );
  };
