import { Event as SentryEvent, EventHint } from '@sentry/types';
import * as Sentry from '@sentry/react';
import { assertEnvVariable } from 'helpers/assertEnvVariable';

declare global {
  interface Window {
    __lastExceptionTime__?: number;
    __lastExceptionMsg__?: string;
  }
}

const beforeSend = (
  event: SentryEvent,
  hint: EventHint
): SentryEvent | PromiseLike<SentryEvent | null> | null => {
  const exception = hint.originalException as Error | undefined;

  if (exception && exception.message) {
    const now = Date.now();

    if (
      window.__lastExceptionTime__ &&
      window.__lastExceptionMsg__ === exception.message &&
      now - window.__lastExceptionTime__ < 1000
    ) {
      return null; // If the same error occurred within a second, don't send it
    }

    window.__lastExceptionMsg__ = exception.message;
    window.__lastExceptionTime__ = now;
  }

  return event;
};

const ignoredErrors = [
  'Network Error',
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications.',
  '$ is not defined',
  'SecurityError',
  'QuotaExceededError',
  'window.fetch is not a function',
];

export function initializeSentry() {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  const dsn = assertEnvVariable('REACT_APP_SENTRY_DSN');
  const tenant = assertEnvVariable('REACT_APP_TENANT');
  const environmentName = assertEnvVariable('REACT_APP_ENVIRONMENT');
  const environment = `${tenant}-${environmentName}`;

  Sentry.init({
    dsn,
    beforeSend,
    ignoreErrors: ignoredErrors,
    environment,
    release: assertEnvVariable('REACT_APP_VERSION'),
  });
}
