import React, { FC } from 'react';
import { Icon, TabPanel, Tabs } from '@fleet/shared';
import { Typography } from '@mui/material';
import { SmsTab } from 'routes/SendNotifications/content/sections/sms/SmsTab';
import { doesntUseChosenFallbackLanguage } from 'helpers/notificationContent';
import { Medium } from 'dto/notificationContent';
import { useFormContext } from '@fleet/shared';
import { useMediumTabData } from 'hooks/useMediumTabData';

export const SmsTabs: FC = () => {
  const form = useFormContext();
  const notificationsByLanguage =
    form.getState().values.notificationsByLanguage || [];

  const { tabData, hasRecipients, hasNecessaryContent } = useMediumTabData(
    Medium.sms,
    notificationsByLanguage
  );

  return (
    <>
      {tabData.length ? (
        <Tabs value={tabData[0]?.language.id}>
          {tabData
            .filter(doesntUseChosenFallbackLanguage)
            .filter(hasRecipients)
            .map((tab, i) => {
              return (
                <TabPanel
                  value={tab.language.id}
                  key={tab.language.id}
                  label={
                    <Typography>
                      {tab.language.name}
                      &nbsp;
                      {hasNecessaryContent(i) ? (
                        <Icon name="check-circle" color="success" />
                      ) : (
                        <Icon name="error-circle" color="error" />
                      )}
                    </Typography>
                  }
                >
                  <SmsTab index={i} />
                </TabPanel>
              );
            })}
        </Tabs>
      ) : (
        <></>
      )}
    </>
  );
};
