import React, { FC } from 'react';
import { Icon, TabPanel, Tabs } from '@fleet/shared';
import { EmailTab } from 'routes/SendNotifications/content/sections/email/EmailTab';
import { Typography } from '@mui/material';
import { useFormContext } from '@fleet/shared';
import { Medium } from 'dto/notificationContent';
import { doesntUseChosenFallbackLanguage } from 'helpers/notificationContent';
import { useMediumTabData } from 'hooks/useMediumTabData';

export const EmailTabs: FC = () => {
  const form = useFormContext();
  const notificationsByLanguage =
    form.getState().values.notificationsByLanguage || [];

  const { tabData, hasRecipients, hasNecessaryContent } = useMediumTabData(
    Medium.email,
    notificationsByLanguage
  );

  return (
    <>
      {tabData.length ? (
        <Tabs value={tabData[0]?.language?.id}>
          {tabData
            .filter(doesntUseChosenFallbackLanguage)
            .filter(hasRecipients)
            .map((tabData, i) => (
              <TabPanel
                value={tabData.language?.id}
                key={tabData.language?.id}
                label={
                  <Typography>
                    {tabData.language?.name}
                    &nbsp;
                    {hasNecessaryContent(i) ? (
                      <Icon name="check-circle" color="success" />
                    ) : (
                      <Icon name="error-circle" color="error" />
                    )}
                  </Typography>
                }
              >
                <EmailTab index={i} />
              </TabPanel>
            ))}
        </Tabs>
      ) : (
        <></>
      )}
    </>
  );
};
