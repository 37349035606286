import { createSelector, selector } from 'store/utils';

const selectTripPassengerOverviewFilter = selector(
  (state) => state.tripPassengerOverview.filterByTrips
);
export const tripPassengerOverviewFilterSelector = createSelector(
  selectTripPassengerOverviewFilter
);

const selectTripPassengerOverview = selector(
  (state) => state.tripPassengerOverview.listByTrips
);
export const tripPassengerOverviewSelector = createSelector(
  selectTripPassengerOverview
);
