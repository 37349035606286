import { createLoadingSelector } from 'store/utils';
import {
  getBusinessEntities,
  getClassifications,
} from 'features/classification/classificationActions';
import { getPassengersAllocationByTrips } from 'features/passengersAllocation/passengersAllocationActions';
import {
  getReAccommodateNewDetailedTrip,
  getReAccommodateNewTripsList,
  startReAccommodateCurrentProcess,
} from 'features/reAccommodate/reAccommodateActions';
import {
  getPotentialAddressees,
  getValidatedNotifications,
  sendNotifications,
} from 'features/notifications/notificationsActions';
import {
  getProblematicPassengers,
  solveProblematicPassengers,
} from 'features/problematicPassengers/problematicPassengersActions';
import { getTripPassengerOverview } from 'features/tripPassengerOverview/tripPassengerOverviewActions';

export const appLoadingSelector = createLoadingSelector(
  getClassifications,
  getBusinessEntities
);

export const passengersAllocationLoadingSelector = createLoadingSelector(
  getPassengersAllocationByTrips
);

export const reAccommodateLoadingSelector = createLoadingSelector(
  getReAccommodateNewTripsList,
  getReAccommodateNewDetailedTrip,
  startReAccommodateCurrentProcess
);

export const notificationsLoadingSelector = createLoadingSelector(
  getPotentialAddressees,
  getValidatedNotifications,
  sendNotifications
);

export const validationModalLoadingSelector =
  createLoadingSelector(sendNotifications);

export const problematicPassengersLoadingSelector = createLoadingSelector(
  getProblematicPassengers,
  solveProblematicPassengers
);

export const tripPassengerOverviewLoadingSelector = createLoadingSelector(
  getTripPassengerOverview
);
