import { createAction } from '@reduxjs/toolkit';
import {
  PostProblematicPassengersResponse,
  ProblematicPassenger,
} from 'dto/api/deviations-management';
import { ProblematicPassengersFilter } from 'dto/problematicPassengers';
import { createAsyncThunk } from 'store/utils';
import _omit from 'lodash/omit';
import { formatFilterPeriod } from 'helpers/date';
import { api, getStopParams } from '@fleet/shared';

export const setProblematicPassengersFilter = createAction<
  Partial<ProblematicPassengersFilter>
>('problematicPassengers/setProblematicPassengersFilter');

export const clearProblematicPassengers = createAction(
  'problematicPassengers/clearProblematicPassengers'
);

export const getProblematicPassengers = createAsyncThunk<
  PostProblematicPassengersResponse,
  Partial<ProblematicPassengersFilter> | undefined
>(
  'problematicPassengers/getProblematicPassengers',
  async (values, { dispatch, getState }) => {
    if (values) {
      dispatch(setProblematicPassengersFilter(values));
    }

    const {
      problematicPassengers: { filter: problematicPassengersFilter },
    } = getState();

    const filter = {
      ..._omit(problematicPassengersFilter, [
        'passengerSegmentDepartureDatePeriod',
        'salesDatePeriod',
        'problemCreatedPeriod',
        'problemChangedPeriod',
        'originStop',
        'destinationStop',
        'oldTrip',
        'newTrip',
      ]),
      ...formatFilterPeriod(
        problematicPassengersFilter.passengerSegmentDepartureDatePeriod,
        'passengerSegmentDepartureDate'
      ),
      ...formatFilterPeriod(
        problematicPassengersFilter.salesDatePeriod,
        'salesDate'
      ),
      ...formatFilterPeriod(
        problematicPassengersFilter.problemCreatedPeriod,
        'problemCreated'
      ),
      ...formatFilterPeriod(
        problematicPassengersFilter.problemChangedPeriod,
        'problemChanged'
      ),
      originStopId: getStopParams(
        problematicPassengersFilter.originStop,
        'guid'
      )[0],
      destinationStopId: getStopParams(
        problematicPassengersFilter.destinationStop,
        'guid'
      )[0],
      oldTripId: problematicPassengersFilter.oldTrip?.id,
      newTripId: problematicPassengersFilter.newTrip?.id,
      ticketTag:
        problematicPassengersFilter.ticketTag?.value &&
        problematicPassengersFilter.ticketTag,
    };

    return (
      await api.post<PostProblematicPassengersResponse>(
        '/problematic-passengers/search',
        filter,
        {
          shouldRelocatePaginationAndSortingParamsFromPayloadToQueryString:
            true,
        }
      )
    ).data;
  }
);

export const solveProblematicPassengers = createAsyncThunk<
  void,
  Array<ProblematicPassenger['id']>
>(
  'problematicPassengers/patchProblematicPassengers',
  async (problematicPassengerIds, { dispatch }) => {
    await api.patch('/problematic-passengers', {
      problematicPassengerIds,
      isSolved: true,
    });
    await dispatch(getProblematicPassengers());
  }
);
