import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransModal = createTrans({
  problematicPassengersMarkAsSolvedDescription: (
    <Trans
      i18nKey="modal.title.problematicPassengersMarkAsSolvedDescription"
      defaults="(0-1)[Are you sure you want to mark selected problematic passenger as solved?];(2-inf)[Are you sure you want to mark selected problematic passengers as solved?]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  problematicPassengersMarkAsSolvedTitle: (
    <Trans
      i18nKey="modal.title.problematicPassengersMarkAsSolvedTitle"
      defaults="Mark problematic passenger as solved"
    />
  ),
});
