import type { Classifier } from '@fleet/shared/dto/classifier';
import { BusinessEntityType } from '@fleet/shared';

export const ALLOWED_BUSINESS_ENTITY_ROLES = [
  BusinessEntityType.CARRIER,
  BusinessEntityType.RETAILER,
  BusinessEntityType.CO_TAXATION_GROUP,
];

export enum Classification {
  // !ClassificationGroups
  BUSINESS_ENTITY = 'BUSINESS_ENTITY',
  COUNTRY = 'COUNTRY',
  INVENTORY_CLASS = 'INVENTORY_CLASS',
}

export enum ClassificationGroup {
  BED_PROPERTY = 'BED_PROPERTY',
  COMPARTMENT_PROPERTY = 'COMPARTMENT_PROPERTY',
  CULTURE = 'CULTURE',
  CURRENCY = 'CURRENCY',
  NOTIFICATION_CHANNEL = 'NOTIFICATION_CHANNEL',
  NOTIFICATION_TEMPLATE_TYPE = 'NOTIFICATION_TEMPLATE_TYPE',
  PROBLEM_TYPE = 'PROBLEM_TYPE',
  SEAT_PROPERTY = 'SEAT_PROPERTY',
  TRIP_STATUS = 'TRIP_STATUS',
}

export type ClassificationGroupKey =
  typeof ClassificationGroup[keyof typeof ClassificationGroup];

export interface ClassificationGroupItem {
  id: ClassificationGroupKey;
  classifications: Array<Classifier>;
}

export enum InventoryType {
  COMPARTMENT = 'INVENTORY_TYPE.COMPARTMENT',
  CARGO = 'INVENTORY_TYPE.CARGO',
  SEAT = 'INVENTORY_TYPE.SEAT',
  BED = 'INVENTORY_TYPE.BED',
}

export const CLASSIFICATION_GROUP_IDS = Object.values(ClassificationGroup);
