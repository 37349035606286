import React, { FC, useCallback, useState } from 'react';
import { SearchForm } from '@fleet/shared';
import { TransSubtitle } from 'i18n/trans/subtitle';
import {
  Button,
  FormControl,
  FormProvider,
  formSubmit,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Grid, Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { TemplatesTable } from 'routes/SendNotifications/content/sections/templates/TemplatesTable';
import {
  getNotificationTemplatesList,
  setNotificationTemplateFilter,
} from 'features/notifications/notificationsActions';
import { useDispatch } from 'store/utils';
import { NotificationTemplateSearch } from 'dto/notificationContent';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { theme } from '@fleet/shared';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    ['& .MuiCardHeader-root']: {
      background: 'none',
      color: theme.palette.text.primary,
    },
  },
  button: {
    ['& .MuiButtonBase-root']: {
      color: theme.palette.text.primary,
    },
  },
}));

export const Templates: FC = () => {
  const [filter, setFilter] = useState<NotificationTemplateSearch>({});
  const dispatch = useDispatch();
  const classes = useStyles(theme);
  const languages = useClassificationOptions(ClassificationGroup.CULTURE);

  const onSubmit = useCallback(
    (values: NotificationTemplateSearch) =>
      formSubmit(async () => {
        const { offset, limit } = filter;
        (document.activeElement as HTMLInputElement)?.blur?.();
        setFilter({ ...values, offset, limit });
        dispatch(setNotificationTemplateFilter(filter));
        await dispatch(getNotificationTemplatesList(filter));
      }),
    [dispatch, filter]
  );

  const { form, handleSubmit } = useForm<NotificationTemplateSearch>({
    onSubmit,
  });

  const handleReset = () => {
    form.reset();
  };

  return (
    <SearchForm
      title={<TransSubtitle i18nKey="useTemplate" />}
      className={classNames(classes.cardHeader)}
      expandButtonColor={'primary'}
    >
      <FormProvider form={form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={4} spacing={2} sx={{ pb: 2 }}>
            <Grid item xs={1}>
              <TextField name="Name" label={<TransField i18nKey="name" />} />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="language"
                label={<TransField i18nKey="language" />}
                options={languages}
              />
            </Grid>
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button onClick={handleReset} variant="text">
                    <TransButton i18nKey="reset" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button
                    icon="search"
                    type="submit"
                    sx={{ ml: 2 }}
                    data-testid="notification-templates-search"
                  >
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <TemplatesTable filter={filter} setFilter={setFilter} />
    </SearchForm>
  );
};
