import { api } from '@fleet/shared';
import {
  FetchTripsSearch,
  GetLineNumbersResponse,
  GetServicesResponse,
  GetTripsResponse,
} from 'dto/api/line-management';
import qs from 'qs';

export const fetchLineNumbers = async (carrierId: number) =>
  (
    await api.get<GetLineNumbersResponse>(`/organizations/${carrierId}/lines`, {
      baseURL: process.env.REACT_APP_API_LM,
    })
  ).data.items;

export const fetchServices = async (carrierId: number) =>
  (
    await api.get<GetServicesResponse>(
      `/service-codes${qs.stringify(
        {
          carrierId,
        },
        {
          addQueryPrefix: true,
          skipNulls: true,
        }
      )}`,
      {
        baseURL: process.env.REACT_APP_API_LM,
      }
    )
  ).data.items;

export const fetchTrips = async (filter: FetchTripsSearch, carrierId: number) =>
  (
    await api.post<GetTripsResponse>(
      `/organizations/${carrierId}/trips/get`,
      {
        ...filter,
        ...(filter.serviceCodes ? { serviceCodes: [filter.serviceCodes] } : {}),
      },
      {
        baseURL: process.env.REACT_APP_API_LM,
        shouldRelocatePaginationAndSortingParamsFromPayloadToQueryString: true,
      }
    )
  ).data;
