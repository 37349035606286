import { NotificationAddressee } from 'dto/api/deviations-management';

export const getAddresseesSummaryCounts = (
  data: NotificationAddressee[]
): {
  potentialAddresseesCount: number;
  potentialLanguagesCount: number;
  emailsCount: number;
  phoneNumbersCount: number;
} => {
  const potentialLanguages: Set<string> = new Set([]);
  const uniqueEmails: Set<string> = new Set([]);
  const uniquePhoneNumbers: Set<string> = new Set([]);

  for (const entity of data) {
    const {
      addressee: { phoneNumber, email, culture },
    } = entity;

    if (culture) {
      potentialLanguages.add(culture.id);
    }

    if (email) {
      uniqueEmails.add(email);
    }

    if (phoneNumber) {
      uniquePhoneNumbers.add(phoneNumber);
    }
  }

  return {
    potentialAddresseesCount: data.length, // TODO: What if data includes same person multiple times, should we differentiate?
    potentialLanguagesCount: potentialLanguages.size,
    emailsCount: uniqueEmails.size,
    phoneNumbersCount: uniquePhoneNumbers.size,
  };
};
