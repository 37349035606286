import type { FC } from 'react';
import React, { useEffect } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { Button, Icon, TextField, useFormContext } from '@fleet/shared';
import { TransField } from 'i18n/trans/field';
import DOMPurify from 'dompurify';
import _debounce from 'lodash/debounce';
import { TransButton } from 'i18n/trans/button';
import { Nullable } from '@fleet/shared/@types/openapi-typescript';

interface EmailTemplateProps {
  index: number;
}

function decodeHtml(html: string) {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}

export const EmailTab: FC<EmailTemplateProps> = ({ index }) => {
  const form = useFormContext();
  const content: Nullable<string> =
    form.getState().values.notificationsByLanguage?.[index]?.emailContent;

  useEffect(() => {
    updateIframeContent(content);
    return () => {
      updateIframeContent.cancel();
    };
  }, [content, index]);

  return (
    <Grid
      container
      sx={{
        alignItems: 'flex-start',
      }}
      spacing={2}
      columns={2}
      data-testid="email-tab"
    >
      <Grid item xs={1}>
        <TextField
          name={`notificationsByLanguage[${index}].emailSenderName`}
          label={<TransField i18nKey="senderName" />}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          name={`notificationsByLanguage[${index}].emailSenderEmail`}
          label={<TransField i18nKey="senderEmail" />}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          name={`notificationsByLanguage[${index}].emailSubject`}
          label={<TransField i18nKey="emailSubject" />}
        />
      </Grid>
      <Grid item xs={2}>
        <Stack
          sx={{ alignItems: 'center' }}
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <TextField
            multiline
            rows={10}
            onChange={(e) => {
              updateIframeContent(e.target.value);
            }}
            label={
              <Stack
                direction="row"
                sx={{
                  ml: 'auto',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                spacing={1}
              >
                <TransField i18nKey="emailContent" />
                <Button
                  sx={{ padding: 0 }}
                  disabled
                  variant="text"
                  size="small"
                  startIcon={<Icon name="question" />}
                >
                  <Typography variant="body2">
                    <TransButton i18nKey="placeholderList" />
                  </Typography>
                </Button>
              </Stack>
            }
            name={`notificationsByLanguage[${index}].emailContent`}
          />
          <iframe
            id="emailTemplatePreview"
            style={{
              marginTop: '25px',
              width: '100%',
              height: '215px',
              border: '1px solid #E1E1E1',
              borderRadius: '4px',
            }}
          ></iframe>
        </Stack>
      </Grid>
    </Grid>
  );
};

const updateIframeContent = _debounce((input: Nullable<string>) => {
  const iframe = document.getElementById(
    'emailTemplatePreview'
  ) as HTMLIFrameElement;

  const sanitizedHtml = DOMPurify.sanitize(input || '');
  const decodedHtml = decodeHtml(sanitizedHtml);

  iframe?.contentWindow?.document.open();
  iframe?.contentWindow?.document.write(decodedHtml);
  iframe?.contentWindow?.document.close();
}, 500);
