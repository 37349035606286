import { Nullable } from '@fleet/shared/@types/openapi-typescript';
import {
  NotificationByLanguage,
  NotificationsTemplate,
} from 'dto/api/notifications-management';
import {
  EnrichedMediumDataEmail,
  EnrichedMediumDataPush,
  EnrichedMediumDataSms,
  MediumData,
  MediumDataEmail,
  MediumDataPush,
  MediumDataSms,
  NotificationByLanguageWithLanguage,
} from 'dto/mediums';
import {
  NotificationLanguage,
  NotificationSummary,
} from 'dto/notificationContent';

export const getMediumDataByLanguageByMedium = (
  currentTemplate: Nullable<NotificationsTemplate>
): MediumData => {
  const result: MediumData = {
    email: [],
    sms: [],
    push: [],
  };

  if (!currentTemplate || !currentTemplate.notificationsByLanguage) {
    return result;
  }
  const hasValidLanguage = (notif: NotificationByLanguage) =>
    notif.language && notif.language.id && notif.language.name;
  currentTemplate.notificationsByLanguage
    .filter(hasValidLanguage)
    .forEach((_notification: NotificationByLanguage) => {
      const notification = _notification as NotificationByLanguageWithLanguage;

      result.email.push(getEmailNotification(notification));
      result.sms.push(getSmsNotification(notification));
      result.push.push(getPushNotification(notification));
    });

  return result;
};

const getEmailNotification = (
  notification: NotificationByLanguageWithLanguage
) => {
  const {
    language,
    emailSenderName,
    emailSenderEmail,
    emailSubject,
    emailContent,
  } = notification;

  return {
    language,
    emailSenderEmail,
    emailContent,
    emailSubject,
    emailSenderName,
  };
};

const getSmsNotification = (
  notification: NotificationByLanguageWithLanguage
) => {
  const { language, smsContent, smsSenderName } = notification;

  return {
    language,
    smsContent,
    smsSenderName,
  };
};

const getPushNotification = (
  notification: NotificationByLanguageWithLanguage
) => {
  const { language, pushNotificationContent, pushNotificationTitle } =
    notification;

  return {
    language,
    pushNotificationTitle,
    pushNotificationContent,
  };
};

export const countTotalRecipients = (
  notificationLanguages: Array<NotificationLanguage> | undefined
): Omit<NotificationSummary, 'trackingId'> => {
  let emailCount = 0;
  let smsCount = 0;
  let pushCount = 0;

  for (const notif of notificationLanguages || []) {
    emailCount += notif.emailRecipients;
    smsCount += notif.smsRecipients;
    pushCount += notif.pushRecipients;
  }

  return {
    totalEmailRecipients: emailCount,
    totalSmsRecipients: smsCount,
    totalPushRecipients: pushCount,
  };
};

export const mergeRequiredLanguagesWithEmailTemplateData = (
  templateData: Array<MediumDataEmail>,
  notificationLanguages: Array<NotificationLanguage>
): Array<EnrichedMediumDataEmail> =>
  notificationLanguages.map((el) => {
    const { languageId, useFallbackLanguage } = el;
    const correspondingTemplate = templateData.find(
      (obj) => obj.language.id === languageId
    );

    return correspondingTemplate
      ? {
          ...correspondingTemplate,
          useFallbackLanguage,
        }
      : {
          language: {
            id: languageId,
            name: el.language,
          },
          emailSenderName: null,
          emailSenderEmail: null,
          emailSubject: null,
          emailContent: null,
          useFallbackLanguage,
        };
  });

export const mergeRequiredLanguagesWithSmsTemplateData = (
  templateData: Array<MediumDataSms>,
  notificationLanguages: Array<NotificationLanguage>
): Array<EnrichedMediumDataSms> =>
  notificationLanguages.map((el) => {
    const { languageId, useFallbackLanguage } = el;
    const correspondingTemplate = templateData.find(
      (obj) => obj.language.id === languageId
    );

    return correspondingTemplate
      ? {
          ...correspondingTemplate,
          useFallbackLanguage,
        }
      : {
          language: {
            id: languageId,
            name: el.language,
          },
          smsSenderName: null,
          smsContent: null,
          useFallbackLanguage,
        };
  });

export const mergeRequiredLanguagesWithPushTemplateData = (
  templateData: Array<MediumDataPush>,
  notificationLanguages: Array<NotificationLanguage>
): Array<EnrichedMediumDataPush> =>
  notificationLanguages.map((el) => {
    const { languageId, useFallbackLanguage } = el;
    const correspondingTemplate = templateData.find(
      (obj) => obj.language.id === languageId
    );

    return correspondingTemplate
      ? { ...correspondingTemplate, useFallbackLanguage }
      : {
          language: {
            id: languageId,
            name: el.language,
          },
          pushNotificationContent: null,
          pushNotificationTitle: null,
          useFallbackLanguage,
        };
  });
