import {
  Icon,
  SearchResult,
  SortingRules,
  Table,
  TableColumns,
  currentDateTimeFormat,
  formatDate,
  useIndeterminateRowSelectCheckbox,
  useRowActive,
  useTable,
} from '@fleet/shared';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { Divider, Stack } from '@mui/material';
import { TripNameCell } from 'components/TripNameCell';
import { ProblematicPassenger } from 'dto/api/deviations-management';
import { problematicPassengersLoadingSelector } from 'features/loading/loadingSelectors';
import { getProblematicPassengers } from 'features/problematicPassengers/problematicPassengersActions';
import {
  problematicPassengersFilterSelector,
  problematicPassengersSelector,
} from 'features/problematicPassengers/problematicPassengersSelectors';
import { TransTableHead } from 'i18n/trans/table';
import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { usePagination, useRowSelect, useSortBy } from 'react-table';
import { ProblematicPassengersSearchForm } from 'routes/ProblematicPassengers/ProblematicPassengersSearchForm';
import { ProblematicPassengersTableCaption } from 'routes/ProblematicPassengers/ProblematicPassengersTableCaption';
import { useDispatch } from 'store/utils';

interface ProblematicPassengersTableProps {}

export const ProblematicPassengersTable: FC<ProblematicPassengersTableProps> =
  () => {
    const dispatch = useDispatch();
    const problematicPassengers = useSelector(problematicPassengersSelector);
    const filter = useSelector(problematicPassengersFilterSelector);
    const loading = useSelector(problematicPassengersLoadingSelector);
    const data = useMemo(
      () => problematicPassengers?.items ?? [],
      [problematicPassengers]
    );

    const handlePageChange = useCallback(
      async (paginationParams: PaginationParams) =>
        await dispatch(
          getProblematicPassengers({
            ...filter,
            ...paginationParams,
          })
        ).unwrap(),
      [dispatch, filter]
    );

    const handleSortingChange = useCallback(
      async (sortBy: SortingRules) => {
        await dispatch(
          getProblematicPassengers({
            ...filter,
            sortBy,
          })
        ).unwrap();
      },
      [dispatch, filter]
    );

    const getRowId = useCallback(
      (row: ProblematicPassenger) => row.id.toString(),
      []
    );

    const columns = useMemo<TableColumns<ProblematicPassenger>>(
      () => [
        {
          accessor: 'bookingNumber',
          Header: <TransTableHead i18nKey="bookingNumber" />,
        },
        {
          accessor: 'ticketNumber',
          Header: <TransTableHead i18nKey="ticketNumber" />,
          disableSortBy: true,
        },
        {
          id: 'tripName',
          accessor: ({ trips }) => (
            <Stack direction="column" gap={1}>
              {trips.map(
                ({ name, departureDateTime }) =>
                  name && (
                    <TripNameCell
                      key={`${name}_${departureDateTime}`}
                      name={name}
                      date={departureDateTime}
                    />
                  )
              )}
            </Stack>
          ),
          Header: <TransTableHead i18nKey="tripName" />,
          disableSortBy: true,
        },
        {
          accessor: 'problemType',
          Header: <TransTableHead i18nKey="problemType" />,
        },
        {
          id: 'problemCreatedOn',
          accessor: ({ problemCreatedOn }) =>
            formatDate(problemCreatedOn, currentDateTimeFormat),
          Header: <TransTableHead i18nKey="problemCreatedOn" />,
        },
        {
          id: 'isProblemSolved',
          accessor: ({ isProblemSolved }) => (
            <Icon
              name={isProblemSolved ? 'check' : 'close'}
              color={isProblemSolved ? 'success' : 'error'}
            />
          ),
          Header: <TransTableHead i18nKey="isSolved" />,
          disableSortBy: true,
        },
        {
          id: 'isPartOfMultiLegJourney',
          accessor: ({ isPartOfMultiLegJourney }) => (
            <Icon
              name={isPartOfMultiLegJourney ? 'check' : 'close'}
              color={isPartOfMultiLegJourney ? 'success' : 'error'}
            />
          ),
          Header: <TransTableHead i18nKey="isPartOfMultiLeg" />,
        },
      ],
      []
    );

    const table = useTable<ProblematicPassenger>(
      {
        data,
        columns,
        total: problematicPassengers?.totalCount,
        offset: problematicPassengers?.offset,
        limit: filter.limit,
        sortBy: filter.sortBy,
        onPageChange: handlePageChange,
        onSortingChange: handleSortingChange,
        getRowId,
      },
      useSortBy,
      usePagination,
      useRowActive,
      useRowSelect,
      useIndeterminateRowSelectCheckbox
    );

    return (
      <>
        <ProblematicPassengersSearchForm />
        <Divider />
        <SearchResult results={!!data.length} loading={loading}>
          <Table
            table={table}
            caption={
              <ProblematicPassengersTableCaption
                selectedFlatRows={table.selectedFlatRows}
              />
            }
          />
        </SearchResult>
      </>
    );
  };
