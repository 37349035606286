import {
  Button,
  Loadable,
  Modal,
  SearchModalProps,
  Table,
  TableColumns,
  currentDateTimeFormat,
  formatDate,
  useFormContext,
  useRowActive,
  useRowSelectRadio,
  useTable,
} from '@fleet/shared';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { TransTableHead } from 'i18n/trans/table';
import { usePagination, useRowSelect } from 'react-table';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { Icon } from '@fleet/shared/mui';
import { Classifier } from '@fleet/shared/dto/classifier';
import { fetchTrips } from 'features/common/commonService';
import {
  FetchTripsSearch,
  GetTripsItem,
  GetTripsResponse,
} from 'dto/api/line-management';
import { makeStyles } from '@mui/styles';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { useSelector } from 'store/utils';

import { formatFilterDate } from 'helpers/date';
import { TripSearchForm } from 'components/TripSelectionField/TripSearchForm';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialogContent-root': {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      gap: theme.spacing(3),
    },
  },
}));

interface TripSelectionModalProps
  extends SearchModalProps<Classifier<number>> {}

export const TripSelectionModal: FC<TripSelectionModalProps> = ({
  isOpen,
  onSubmit,
  onClose,
}) => {
  const classes = useStyles();
  const { getState } = useFormContext<{ carrierId: number }>();
  const values = getState().values;
  const [trips, setTrips] = useState<GetTripsResponse>();
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationParams, setPaginationParams] = useState<PaginationParams>();
  const [filter, setFilter] = useState<FetchTripsSearch>();
  const data = useMemo(() => trips?.items ?? [], [trips?.items]);
  const fetchData = useCallback(
    async (filter: FetchTripsSearch) => {
      setIsLoading(true);
      const response = await fetchTrips(
        {
          ...filter,
          tripDateFrom: formatFilterDate(filter.tripDateFrom),
          tripDateTo: formatFilterDate(filter.tripDateTo),
        },
        values.carrierId ?? currentBusinessEntityId
      );
      setTrips(response);
      setIsLoading(false);
    },
    [currentBusinessEntityId, values.carrierId]
  );

  useEffect(() => {
    if (!isOpen) {
      setTrips(undefined);
      setPaginationParams(undefined);
    }
  }, [isOpen]);

  const columns = useMemo<TableColumns<GetTripsItem>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="tripName" />,
      },
      {
        id: 'departureDateTime',
        accessor: ({ departureDateTime }) =>
          formatDate(departureDateTime, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="departureDateTime" />,
      },
      {
        accessor: 'serviceCode',
        Header: <TransTableHead i18nKey="serviceCode" />,
      },
      {
        id: 'hasAdmissions',
        accessor: 'hasAdmissions',
        Header: <TransTableHead i18nKey="hasTickets" />,
        Cell: ({ row: { original } }) => (
          <Icon
            name={original.hasAdmissions ? 'check' : 'close'}
            color={original.hasAdmissions ? 'success' : 'error'}
          />
        ),
      },
      {
        id: 'isActive',
        accessor: 'isActive',
        Header: <TransTableHead i18nKey="isActive" />,
        Cell: ({ row: { original } }) => (
          <Icon
            name={original.isActive ? 'check' : 'close'}
            color={original.isActive ? 'success' : 'error'}
          />
        ),
      },
    ],
    []
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) => {
      setPaginationParams(paginationParams);
      fetchData({ ...filter, ...paginationParams });
    },
    [fetchData, filter]
  );

  const table = useTable<GetTripsItem>(
    {
      data,
      columns,
      total: trips?.totalCount,
      offset: trips?.offset,
      limit: paginationParams?.limit,
      onPageChange: handlePageChange,
    },
    useRowActive,
    usePagination,
    useRowSelect,
    useRowSelectRadio
  );

  const handleSearchSubmit = useCallback(
    async (filter: Partial<FetchTripsSearch>) => {
      setFilter(filter);
      await fetchData({ ...paginationParams, ...filter, offset: 0 });
    },
    [fetchData, paginationParams]
  );

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={<TransTitle i18nKey="selectTrip" />}
      className={classes.root}
      actionButton={
        <Button
          variant="contained"
          type="submit"
          onClick={() =>
            onSubmit({
              id: table.selectedFlatRows[0].original.id,
              name: table.selectedFlatRows[0].original.name ?? 'Trip',
            })
          }
          startIcon={<Icon name="check" size={20} />}
          disabled={!table.selectedFlatRows.length}
        >
          <TransButton i18nKey="confirm" />
        </Button>
      }
      maxWidth="md"
      fullWidth
    >
      <Loadable loading={isLoading}>
        <TripSearchForm
          carrierId={values.carrierId}
          onSubmit={handleSearchSubmit}
        />
        <Table table={table} />
      </Loadable>
    </Modal>
  );
};
