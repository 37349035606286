import { createAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/utils';
import {
  ReAccommodateTripsFilter,
  PassengerReAccommodateStep,
} from 'dto/reAccommodate';
import _omit from 'lodash/omit';
import { api } from '@fleet/shared';
import {
  GetReAccommodateDetailedTripResponse,
  Admission,
  PostReAccommodateStartResponse,
  PostReAccommodateValidateRequest,
  PostReAccommodateValidateResponse,
} from 'dto/api/deviations-management';
import {
  PostReAccommodateTripsResponse,
  ReAccommodateTrip,
} from 'dto/api/line-management';
import { passengersSearchCallbacks } from 'hooks/search/usePassengersSearchForm';
import { formatFilterPeriod } from 'helpers/date';

export const resetAccommodate = createAction('reAccommodate/resetAccommodate');

export const setReAccommodatePassengersList = createAction<Array<Admission>>(
  'reAccommodate/setReAccommodatePassengersList'
);

export const setReAccommodateActiveStep =
  createAction<PassengerReAccommodateStep>(
    'reAccommodate/setReAccommodateActiveStep'
  );

export const setReAccommodateNewTripsFilter = createAction<
  Partial<ReAccommodateTripsFilter>
>('reAccommodate/setReAccommodateNewTripsFilter');

export const getReAccommodateNewTripsList = createAsyncThunk<
  PostReAccommodateTripsResponse,
  Partial<ReAccommodateTripsFilter>
>(
  'reAccommodate/getReAccommodateNewTripsList',
  async (values, { getState, dispatch }) => {
    if (values) {
      dispatch(setReAccommodateNewTripsFilter(values));
    }

    const {
      reAccommodate: {
        newTripsFilter: { carrierId, ...newTripsFilter },
      },
    } = getState();

    const filter = {
      ..._omit(newTripsFilter, [
        'departurePeriod',
        'createdOnPeriod',
        'stopOne',
        'stopTwo',
      ]),
      ...formatFilterPeriod(newTripsFilter.departurePeriod, 'tripDate'),
      ...formatFilterPeriod(newTripsFilter.createdOnPeriod, 'createdOn'),
      stopOneId: newTripsFilter.stopOne?.id,
      stopTwoId: newTripsFilter.stopTwo?.id,
    };

    return (
      await api.post<PostReAccommodateTripsResponse>(
        `/organizations/${carrierId}/trips/get`,
        filter,
        {
          baseURL: process.env.REACT_APP_API_LM,
          shouldRelocatePaginationAndSortingParamsFromPayloadToQueryString:
            true,
        }
      )
    ).data;
  }
);

export const getReAccommodateNewDetailedTrip = createAsyncThunk<
  GetReAccommodateDetailedTripResponse,
  ReAccommodateTrip['id']
>(
  'reAccommodate/getReAccommodateNewDetailedTrip',
  async (tripId, { getState }) => {
    const {
      reAccommodate: {
        newTripsFilter: { carrierId },
        newDetailedTrips,
      },
    } = getState();

    if (newDetailedTrips[tripId]) {
      return newDetailedTrips[tripId];
    }

    return (
      await api.get<GetReAccommodateDetailedTripResponse>(
        `/organizations/${carrierId}/trips/${tripId}/route-details`
      )
    ).data;
  }
);

export const startReAccommodateCurrentProcess = createAsyncThunk<
  {
    newTrip: GetReAccommodateDetailedTripResponse;
    mismatchedAdmissions: PostReAccommodateValidateResponse;
    trackingId: PostReAccommodateStartResponse['trackingId'] | null;
  },
  PostReAccommodateValidateRequest
>(
  'reAccommodate/startReAccommodateCurrentProcess',
  async (values, { getState, dispatch }) => {
    const {
      reAccommodate: {
        newTripsFilter: { carrierId },
      },
      passengersAllocation: { tab },
    } = getState();

    const [newTrip, { data: mismatchedAdmissions }] = await Promise.all([
      dispatch(getReAccommodateNewDetailedTrip(values.newTripId)).unwrap(),
      api.post<PostReAccommodateValidateResponse>(
        `/organizations/${carrierId}/re-allocations/validate`,
        values
      ),
    ]);

    let trackingId: PostReAccommodateStartResponse['trackingId'] | null = null;

    if (!mismatchedAdmissions.mismatches?.routes.length) {
      const { clearPassengers } = passengersSearchCallbacks[tab];

      trackingId = (
        await api.post<PostReAccommodateStartResponse>(
          `/organizations/${carrierId}/re-allocations/start`,
          { ...values, sendSeatChangeNotifications: false }
        )
      ).data.trackingId;

      dispatch(clearPassengers());
    }

    return { newTrip, mismatchedAdmissions, trackingId };
  }
);
