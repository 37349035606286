import { useMemo } from 'react';
import {
  getMediumDataByLanguageByMedium,
  mergeRequiredLanguagesWithEmailTemplateData,
  mergeRequiredLanguagesWithSmsTemplateData,
} from 'helpers/mediums';
import { useSelector } from 'store/utils';
import {
  currentTemplateSelector,
  notificationLanguagesSelector,
} from 'features/notifications/notificationsSelectors';
import _zipWith from 'lodash/zipWith';
import { EnrichedNotificationByLanguage } from 'dto/notificationContent';

export const useMergeLanguagesWithTemplate =
  (): Array<EnrichedNotificationByLanguage> => {
    const currentTemplate = useSelector(currentTemplateSelector);
    const notificationLanguages = useSelector(notificationLanguagesSelector);

    const { email, sms } = useMemo(
      () => getMediumDataByLanguageByMedium(currentTemplate),
      [currentTemplate]
    );

    const emailData = useMemo(
      () =>
        mergeRequiredLanguagesWithEmailTemplateData(
          email,
          notificationLanguages || []
        ),
      [email, notificationLanguages]
    );
    const smsData = useMemo(
      () =>
        mergeRequiredLanguagesWithSmsTemplateData(
          sms,
          notificationLanguages || []
        ),
      [sms, notificationLanguages]
    );

    return useMemo(
      () =>
        _zipWith(emailData, smsData, (emailElement, smsElement) => ({
          ...emailElement,
          ...smsElement,
        })),
      [emailData, smsData]
    );
  };
