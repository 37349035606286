import { classificationReducer } from 'features/classification/classificationReducer';
import { combineReducers } from '@reduxjs/toolkit';
import { loadingReducer } from 'features/loading/loadingReducer';
import { commonReducer } from 'features/common/commonReducer';
import { reAccommodateReducer } from 'features/reAccommodate/reAccommodateReducer';
import { notificationsReducer } from 'features/notifications/notificationsReducer';
import { passengersAllocationReducer } from 'features/passengersAllocation/passengersAllocationReducer';
import { problematicPassengersReducer } from 'features/problematicPassengers/problematicPassengersReducer';
import { tripPassengerOverviewReducer } from 'features/tripPassengerOverview/tripPassengerOverviewReducer';

export default combineReducers({
  loading: loadingReducer,
  classification: classificationReducer,
  common: commonReducer,
  passengersAllocation: passengersAllocationReducer,
  reAccommodate: reAccommodateReducer,
  notifications: notificationsReducer,
  problematicPassengers: problematicPassengersReducer,
  tripPassengerOverview: tripPassengerOverviewReducer,
});
