import { Layout, Loadable } from '@fleet/shared';
import { tripPassengerOverviewLoadingSelector } from 'features/loading/loadingSelectors';
import { FC } from 'react';
import { TripPassengerOverviewTable } from 'routes/TripPassengerOverview/TripPassengerOverviewTable';
import { useSelector } from 'store/utils';

interface TripPassengerOverviewProps {}

export const TripPassengerOverview: FC<TripPassengerOverviewProps> = () => {
  const loading = useSelector(tripPassengerOverviewLoadingSelector);

  return (
    <Loadable loading={loading}>
      <Layout>
        <TripPassengerOverviewTable />
      </Layout>
    </Loadable>
  );
};
