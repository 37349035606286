import React, { FC, useEffect, useRef, useState } from 'react';
import type { EmailValidationData } from 'dto/mediums';
import { Grid, Stack, TextareaAutosize, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { getSuccessOrFailureText } from 'helpers/templateValidation';
import { TransTitle } from 'i18n/trans/title';
import { TransLabel } from 'i18n/trans/label';
interface EmailValidationProps {
  data: EmailValidationData;
  index: number;
}

const decodeHtml = (html: string) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};
const updateIframeContent = (html: string) => {
  const iframe = document.getElementById(
    'emailTemplateValidationPreview'
  ) as HTMLIFrameElement;

  const sanitizedHtml = DOMPurify.sanitize(html);
  const decodedHtml = decodeHtml(sanitizedHtml);

  iframe?.contentWindow?.document.open();
  iframe?.contentWindow?.document.write(decodedHtml);
  iframe?.contentWindow?.document.close();
};

export const EmailValidation: FC<EmailValidationProps> = ({ data, index }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [iframeStyle, setIframeStyle] = useState({
    border: '1px solid #E1E1E1',
    borderRadius: '4px',
    width: '100%',
    height: textareaRef.current?.scrollHeight || '134px',
  });

  useEffect(() => {
    if (data.content) {
      updateIframeContent(data.content);
    }
  }, [data]);

  const handleResize = () => {
    setIframeStyle((prevState) => ({
      ...prevState,
      height: textareaRef.current
        ? textareaRef.current.scrollHeight + 'px'
        : '134px',
      width: textareaRef.current
        ? textareaRef.current.scrollWidth + 'px'
        : '100%',
    }));
  };

  useEffect(() => {
    const ref = textareaRef.current;
    ref?.addEventListener('pointerout', handleResize);

    return () => {
      ref?.removeEventListener('pointerout', handleResize);
    };
  }, []);

  return (
    <>
      <Stack direction="row" sx={{ mb: 2 }}>
        <Typography fontWeight={900}>
          <TransTitle i18nKey="emailTemplate" />
          {':'}
        </Typography>
        {getSuccessOrFailureText(data.contentError)}
      </Stack>

      <Grid container spacing={1}>
        <Grid item xs={1}>
          <Typography fontWeight={700}>From:</Typography>
        </Grid>
        <Grid item xs={11}>
          {`${data.senderName || ''} ${
            data.senderEmail ? `<${data.senderEmail}>` : ''
          }`}
        </Grid>
        <Grid item xs={1}>
          <Typography fontWeight={700}>Subject:</Typography>
        </Grid>
        <Grid item xs={11}>
          {data.subject}
        </Grid>
      </Grid>

      <Stack>
        <Typography fontWeight={700} sx={{ mt: 3, mb: 1 }}>
          <TransLabel i18nKey="textPlainContent" />:
        </Typography>
        <TextareaAutosize
          name={`notificationsByLanguage[${index}].emailContent`}
          value={data.content || ''}
          disabled
          ref={textareaRef}
          minRows={8}
          style={{ width: '100%', maxWidth: '100%', padding: '6px' }}
        />
      </Stack>

      <Stack>
        <Typography fontWeight={700} sx={{ mt: 3, mb: 1 }}>
          <TransLabel i18nKey="textHtmlContent" />
        </Typography>
        <iframe id="emailTemplateValidationPreview" style={iframeStyle} />
      </Stack>
    </>
  );
};
