import { Icon, SelectOption, TableColumns, formatDate } from '@fleet/shared';
import { currentLocaleConfiguration } from '@fleet/shared/i18n';
import { SelectValue } from '@fleet/shared/mui/Select';
import { Typography } from '@mui/material';
import { TripNameCell } from 'components/TripNameCell';
import { differenceInDays } from 'date-fns';
import { Admission } from 'dto/api/deviations-management';
import { parseFullName } from 'helpers/parse';
import { TransTableHead } from 'i18n/trans/table';
import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { MismatchModalStopField } from 'routes/PassengersAllocation/ReAccommodate/SelectTrip/Mismatch/StopField/MismatchModalStopField';

export const NEW_ORIGIN_COLUMN_NAME = 'newOriginRouteId';
export const NEW_DESTINATION_COLUMN_NAME = 'newDestinationRouteId';

export const usePassengersAllocationColumns = (
  stopOptions?: Array<SelectOption<SelectValue>>
) => {
  const columns = useMemo<TableColumns<Admission>>(
    () => [
      {
        id: 'name',
        accessor: ({ passenger: { firstName, lastName } }) =>
          parseFullName(firstName, lastName),
        Header: <TransTableHead i18nKey="name" />,
        disableSortBy: true,
        width: 110,
      },
      {
        accessor: 'bookingNumber',
        Header: <TransTableHead i18nKey="bookingNumber" />,
        width: 90,
      },
      {
        accessor: 'admissionNumber',
        Header: <TransTableHead i18nKey="admissionNumber" />,
        disableSortBy: true,
        width: 110,
      },
      {
        id: 'passengerCategory',
        accessor: ({ passenger: { category } }) => category?.name ?? '-',
        Header: <TransTableHead i18nKey="passengerCategory" />,
        disableSortBy: true,
        width: 110,
      },
      {
        id: 'admissionStatus',
        accessor: ({ admissionStatus }) => admissionStatus.name,
        Header: <TransTableHead i18nKey="status" />,
        width: 70,
      },
      {
        id: 'tripName',
        accessor: ({ trip: { name, departureDateTime } }) => (
          <TripNameCell name={name} date={departureDateTime} />
        ),
        Header: <TransTableHead i18nKey="tripName" />,
        disableSortBy: true,
        width: 160,
      },
      {
        id: 'vehicleCarriage',
        accessor: ({ vehicle: { name, carriage } }) => `${name} / ${carriage}`,
        Header: <TransTableHead i18nKey="vehicleCarriage" />,
        disableSortBy: true,
        width: 120,
      },
      {
        id: 'journeyLeg.originStop.name',
        accessor: ({ journeyLeg: { originStop } }) => originStop.name,
        Header: <TransTableHead i18nKey="originStopZone" />,
        width: 120,
      },
      {
        id: 'journeyLeg.departureTime',
        accessor: ({ journeyLeg: { departureTime } }) =>
          departureTime
            ? formatDate(departureTime, currentLocaleConfiguration.timeFormat)
            : ' - ',
        Header: <TransTableHead i18nKey="shortDepartureTime" />,
        width: 60,
      },
      {
        id: NEW_ORIGIN_COLUMN_NAME,
        Cell: (props: CellProps<Admission>) => (
          <MismatchModalStopField
            {...props}
            isNewOrigin
            stopOptions={stopOptions ?? []}
          />
        ),
        Header: <TransTableHead i18nKey="newOrigin" />,
        disableSortBy: true,
        width: 146,
      },
      {
        id: 'journeyLeg.destinationStop.name',
        accessor: ({ journeyLeg: { destinationStop } }) => destinationStop.name,
        Header: <TransTableHead i18nKey="destinationStopZone" />,
        width: 120,
      },
      {
        id: 'journeyLeg.arrivalTime',
        accessor: ({ journeyLeg: { departureTime, arrivalTime } }) => {
          if (!departureTime || !arrivalTime) {
            return ' - ';
          }

          const difference = differenceInDays(
            new Date(departureTime),
            new Date(arrivalTime)
          );

          return (
            <>
              <Typography variant="body2">
                {formatDate(arrivalTime, currentLocaleConfiguration.timeFormat)}
              </Typography>
              {difference > 0 && <Typography>{`(+${difference})`}</Typography>}
            </>
          );
        },
        Header: <TransTableHead i18nKey="shortArrivalTime" />,
        width: 60,
      },
      {
        id: NEW_DESTINATION_COLUMN_NAME,
        Cell: (props: CellProps<Admission>) => (
          <MismatchModalStopField {...props} stopOptions={stopOptions ?? []} />
        ),
        Header: <TransTableHead i18nKey="newDestination" />,
        disableSortBy: true,
        width: 170,
      },
      {
        id: 'isPartOfMultiLegJourney',
        accessor: ({ isPartOfMultiLegJourney }) => (
          <Icon
            name={isPartOfMultiLegJourney ? 'check' : 'close'}
            color={isPartOfMultiLegJourney ? 'success' : 'error'}
          />
        ),
        Header: <TransTableHead i18nKey="isPartOfMultiLeg" />,
        width: 110,
      },
    ],
    [stopOptions]
  );

  const hiddenColumns = useMemo(
    () =>
      stopOptions
        ? [
            'passengerCategory',
            'admissionStatus',
            'vehicleCarriage',
            'departureTime',
            'arrivalTime',
            'isPartOfMultiLegJourney',
          ]
        : [NEW_ORIGIN_COLUMN_NAME, NEW_DESTINATION_COLUMN_NAME],
    [stopOptions]
  );

  return { columns, hiddenColumns };
};
