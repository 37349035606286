import { TableColumns, formatDate } from '@fleet/shared';
import { currentLocaleConfiguration } from '@fleet/shared/i18n';
import { InventoryType } from 'dto/classification';
import { ReAccommodateDetailedTripStop } from 'dto/api/deviations-management';
import { TransTableHead } from 'i18n/trans/table';
import { useMemo } from 'react';

export const useDetailedTripColumns = (isStopMismatchWarning?: boolean) => {
  const columns = useMemo<TableColumns<ReAccommodateDetailedTripStop>>(
    () => [
      {
        id: 'arrival',
        accessor: ({ plannedArrivalTime }) =>
          formatDate(plannedArrivalTime, currentLocaleConfiguration.timeFormat),
        Header: <TransTableHead i18nKey="arrival" />,
      },
      {
        id: 'departure',
        accessor: ({ plannedDepartureTime }) =>
          formatDate(
            plannedDepartureTime,
            currentLocaleConfiguration.timeFormat
          ),
        Header: <TransTableHead i18nKey="departure" />,
      },
      {
        id: 'name',
        accessor: ({ stop: { name } }) => name,
        Header: <TransTableHead i18nKey="stop" />,
        width: '40%',
      },
      {
        id: 'availabilitySeat',
        accessor: ({ availabilities }) =>
          getAvailablePlaces(availabilities, InventoryType.SEAT),
        Header: <TransTableHead i18nKey="availabilitySeats" />,
      },
      {
        id: 'availabilityBed',
        accessor: ({ availabilities }) =>
          getAvailablePlaces(availabilities, InventoryType.BED),
        Header: <TransTableHead i18nKey="availabilityBeds" />,
      },
      {
        id: 'availabilityCompartment',
        accessor: ({ availabilities }) =>
          getAvailablePlaces(availabilities, InventoryType.COMPARTMENT),
        Header: <TransTableHead i18nKey="availabilityCompartments" />,
      },
    ],
    []
  );

  const hiddenColumns = useMemo(
    () =>
      isStopMismatchWarning
        ? ['availabilitySeat', 'availabilityBed', 'availabilityCompartment']
        : [],
    [isStopMismatchWarning]
  );

  return { columns, hiddenColumns };
};

const getAvailablePlaces = (
  availabilities: ReAccommodateDetailedTripStop['availabilities'],
  columnInventoryType: InventoryType
) =>
  availabilities.find(
    ({ inventoryType }) => inventoryType?.id === columnInventoryType
  )?.availableForInventoryType ?? 0;
