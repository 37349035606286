import React, { FC } from 'react';
import { Box, FormControlLabel, Stack, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransLabel } from 'i18n/trans/label';
import { Switch } from '@fleet/shared';

interface Props {
  totalCount: number;
  view: 'search' | 'selected';
  setView: React.Dispatch<React.SetStateAction<'search' | 'selected'>>;
  subtitleKey: 'linesQty' | 'stopsQty' | 'tripsQty' | 'ancillariesQty';
  labelKey:
    | 'displaySelectedLines'
    | 'displaySelectedStops'
    | 'displaySelectedTrips'
    | 'displaySelectedAncillaries';
}

export const ModalTableCaption: FC<Props> = ({
  totalCount,
  view,
  setView,
  subtitleKey,
  labelKey,
}) => {
  return (
    <Stack
      direction="row"
      alignItems="baseline"
      justifyContent="space-between"
      spacing={2}
    >
      <Box>
        <Typography variant="subtitle" fontWeight={700}>
          <TransSubtitle i18nKey="searchResults" />
        </Typography>
        {totalCount ? (
          <Typography variant="body2" color="text.secondary">
            <TransSubtitle
              i18nKey={subtitleKey}
              values={{ count: totalCount }}
            />
          </Typography>
        ) : (
          <></>
        )}
      </Box>
      <FormControlLabel
        sx={{
          alignSelf: 'end',
        }}
        control={
          <Switch
            sx={{ mr: 1 }}
            checked={view === 'selected'}
            onChange={() =>
              setView((prevState) =>
                prevState === 'selected' ? 'search' : 'selected'
              )
            }
          />
        }
        label={<TransLabel i18nKey={labelKey} />}
      />
    </Stack>
  );
};
