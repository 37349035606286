import {
  Button,
  Loadable,
  Modal,
  SearchModalProps,
  Table,
  TableColumns,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useRowSelect, useTable } from 'react-table';
import { Icon } from '@fleet/shared/mui';
import { Stack } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { TransTableHead } from 'i18n/trans/table';
import { TransTitle } from 'i18n/trans/title';
import { fetchAncillaryTypes } from 'features/notifications/notificationsService';
import type { Classifier } from '@fleet/shared/dto/classifier';
import { AncillariesSearchForm } from 'routes/SendNotifications/filters/SearchForms/AncillariesSearchForm';
import { ModalTableCaption } from 'routes/SendNotifications/filters/Modals/ModalTableCaption';
import { useModalTextFieldUtils } from 'hooks/useModalTextFieldUtils';

interface TripsSelectionModalProps
  extends SearchModalProps<Array<Classifier>> {}

interface AncillaryRow extends Classifier {
  description: string;
  code: string;
  orderNumber: number | null;
}

export const AncillariesModal: FC<TripsSelectionModalProps> = ({
  isOpen,
  name,
  onSubmit,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<AncillaryRow[]>([]);
  const [view, setView] = useState<'selected' | 'search'>('search');
  const [confirmedSelection, setConfirmedSelection] = useState(
    new Map<string, AncillaryRow>()
  );
  const [draftSelection, setDraftSelection] = useState(
    new Map<string, AncillaryRow>()
  );

  const { selectedRowIds, getRowId, handleClose, stateReducer } =
    useModalTextFieldUtils<AncillaryRow>({
      draftSelection,
      setDraftSelection,
      confirmedSelection,
      name,
      onClose,
    });

  const columns = useMemo<TableColumns<AncillaryRow>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
      },
      {
        accessor: 'description',
        Header: <TransTableHead i18nKey="description" />,
      },
      {
        accessor: 'code',
        Header: <TransTableHead i18nKey="code" />,
      },
      {
        accessor: 'orderNumber',
        Header: <TransTableHead i18nKey="isActive" />,
      },
    ],
    []
  );

  const table = useTable<AncillaryRow>(
    {
      data,
      columns,
      getRowId,
      useControlledState: (state) => ({
        ...state,
        selectedRowIds,
      }),
      stateReducer,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const selectedAncillariesTable = useTable<AncillaryRow>(
    {
      data: useMemo(
        () => Array.from(confirmedSelection.values()),
        [confirmedSelection]
      ),
      columns,
      getRowId,
      useControlledState: (state) => ({
        ...state,
        selectedRowIds,
      }),
      stateReducer,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const handleSearchSubmit = useCallback(async (filter) => {
    setIsLoading(true);
    const values = await fetchAncillaryTypes(filter);
    const mappedValues: AncillaryRow[] = values.map((ancillary) => ({
      id: ancillary.id,
      name: ancillary.name,
      description: ancillary.description,
      code: ancillary.code,
      orderNumber: ancillary.orderNumber ?? null,
    }));
    setData(mappedValues);

    setIsLoading(false);
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      title={<TransTitle i18nKey="selectAncillaries" />}
      actionButton={
        <Button
          variant="contained"
          type="submit"
          onClick={() => {
            onSubmit(
              table.selectedFlatRows.map((row) => ({
                name: row.original.name,
                id: row.original.id,
              }))
            );
            setConfirmedSelection(draftSelection);
            onClose();
          }}
          startIcon={<Icon name="check" size={20} />}
        >
          <TransButton i18nKey="confirm" />
        </Button>
      }
      maxWidth="md"
      fullWidth
    >
      <Loadable loading={isLoading}>
        <Stack rowGap={4}>
          <AncillariesSearchForm onSubmit={handleSearchSubmit} />
          <ModalTableCaption
            totalCount={data.length}
            view={view}
            setView={setView}
            subtitleKey="ancillariesQty"
            labelKey="displaySelectedAncillaries"
          />
          {view === 'search' && <Table table={table} />}
          {view === 'selected' && <Table table={selectedAncillariesTable} />}
        </Stack>
      </Loadable>
    </Modal>
  );
};
