import {
  EnrichedNotificationByLanguage,
  Medium,
  TabData,
} from 'dto/notificationContent';
import { useSelector } from 'store/utils';
import { notificationLanguagesSelector } from 'features/notifications/notificationsSelectors';
import _pick from 'lodash/pick';
import {
  hasNecessaryContent,
  hasRecipients,
} from 'helpers/notificationContent';

export const useMediumTabData = (
  medium: Medium,
  notificationsByLanguage: Array<EnrichedNotificationByLanguage>
) => {
  const notificationLanguages =
    useSelector(notificationLanguagesSelector) || [];

  const tabData: Array<TabData> =
    notificationsByLanguage.map(
      (notification: EnrichedNotificationByLanguage) =>
        _pick(notification, ['language', 'useFallbackLanguage'])
    ) || [];

  return {
    tabData,
    hasRecipients: hasRecipients(medium, notificationLanguages),
    hasNecessaryContent: hasNecessaryContent(medium, notificationsByLanguage),
  };
};
