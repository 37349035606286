import { createReducer } from '@reduxjs/toolkit';
import { PostAdmissionsByTripsResponse } from 'dto/api/deviations-management';
import {
  AdmissionSearchGroup,
  AdmissionFilterByTrips,
} from 'dto/passengersAllocation';
import {
  clearPassengersAllocationByTrips,
  getPassengersAllocationByTrips,
  setPassengersAllocationFilterByTrips,
  setPassengersAllocationFilterTab,
} from 'features/passengersAllocation/passengersAllocationActions';

export interface CommonState {
  filterByTrips: Partial<AdmissionFilterByTrips>;
  listByTrips?: PostAdmissionsByTripsResponse;
  tab: AdmissionSearchGroup;
}

const initialState: CommonState = {
  filterByTrips: {},
  tab: AdmissionSearchGroup.TRIPS,
};

export const passengersAllocationReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(setPassengersAllocationFilterTab, (state, action) => {
        state.tab = action.payload;
      })
      .addCase(setPassengersAllocationFilterByTrips, (state, action) => {
        state.filterByTrips = action.payload;
      })
      .addCase(clearPassengersAllocationByTrips, (state) => {
        state.listByTrips = undefined;
      })
      .addCase(getPassengersAllocationByTrips.fulfilled, (state, action) => {
        state.listByTrips = action.payload;
      });
  }
);
