import { CardHeader, Icon, Layout, Loadable } from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { reAccommodateLoadingSelector } from 'features/loading/loadingSelectors';
import { resetAccommodate } from 'features/reAccommodate/reAccommodateActions';
import { reAccommodatePassengersListSelector } from 'features/reAccommodate/reAccommodateSelectors';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTitle } from 'i18n/trans/title';
import { useEffect, FC } from 'react';
import { useHistory } from 'react-router';
import { ReAccommodateSteps } from 'routes/PassengersAllocation/ReAccommodate/ReAccommodateSteps';
import { useDispatch, useSelector } from 'store/utils';

interface ReAccommodateProps {}

export const ReAccommodate: FC<ReAccommodateProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const passengersList = useSelector(reAccommodatePassengersListSelector);
  const loading = useSelector(reAccommodateLoadingSelector);

  useEffect(() => {
    if (!passengersList.length) {
      history.replace('/passengers-allocation');
    }

    return () => {
      dispatch(resetAccommodate());
    };
  }, [dispatch, history, passengersList]);

  return (
    <Loadable loading={loading}>
      <Layout
        data-testid="reAccommodate-wizard-layout"
        header={
          <CardHeader
            title={
              <Stack direction="row" alignItems="baseline" spacing={2}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="h2">
                    <TransTitle i18nKey="reAccommodatePassengers" />
                  </Typography>
                  <Icon name="arrow-right" />
                  <Typography variant="h2">
                    <TransTitle i18nKey="bookNewTrip" />
                  </Typography>
                </Stack>
                <Typography variant="subtitle">
                  <TransSubtitle
                    i18nKey="selectedQty"
                    values={{ count: passengersList.length }}
                  />
                </Typography>
              </Stack>
            }
          />
        }
      >
        <ReAccommodateSteps />
      </Layout>
    </Loadable>
  );
};
