import { StopsSelectValue } from '@fleet/shared';
import { Classifier } from '@fleet/shared/dto/classifier';
import {
  PostAdmissionsByTripsRequest,
  PostAdmissionsByTripsRequestTicketTag,
  PostAdmissionsByTripsSearchParams,
} from 'dto/api/deviations-management';

export enum AdmissionSearchGroup {
  TRIPS = 'TRIPS',
}

export type AdmissionFilterByTrips = Omit<
  PostAdmissionsByTripsRequest,
  | 'originStopId'
  | 'destinationStopId'
  | 'stopOneId'
  | 'stopTwoId'
  | 'ticketTag'
  | 'tripIds'
> &
  PostAdmissionsByTripsSearchParams & {
    originStop: StopsSelectValue;
    destinationStop: StopsSelectValue;
    stopOne: StopsSelectValue;
    stopTwo: StopsSelectValue;
    ticketTag: {
      value?: PostAdmissionsByTripsRequestTicketTag['value'];
      isIncluded: PostAdmissionsByTripsRequestTicketTag['isIncluded'];
    };
    trips: Array<Classifier<number>>;
  };

export enum OdFilteringMethodType {
  EXACT = 'exact',
  SUBSEGMENT = 'subsegment',
  OVERLAP = 'overlap',
}
