import type { FC } from 'react';
import React from 'react';
import { Grid } from '@mui/material';
import { TextField } from '@fleet/shared';
import { TransField } from 'i18n/trans/field';

interface SmsTemplateProps {
  index: number;
}

export const SmsTab: FC<SmsTemplateProps> = ({ index }) => {
  return (
    <Grid
      container
      sx={{ alignItems: 'flex-start' }}
      spacing={2}
      columns={2}
      data-testid="sms-tab"
    >
      <Grid item xs={1}>
        <TextField
          name={`notificationsByLanguage[${index}].smsSenderName`}
          label={
            <>
              <TransField i18nKey="smsSenderName" />
              {':'}
            </>
          }
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          multiline
          rows={5}
          name={`notificationsByLanguage[${index}].smsContent`}
          label={<TransField i18nKey="smsContent" />}
        />
      </Grid>
    </Grid>
  );
};
