import {
  Medium,
  NotificationLanguage,
  RecipientsCountByLanguageId,
  TabData,
} from 'dto/notificationContent';
import { NotificationByLanguage } from 'dto/api/notifications-management';
import { Nullable } from '@fleet/shared/@types/openapi-typescript';
import { AddresseeData } from 'routes/SendNotifications/NotificationAddressees';

export const hasNecessaryContent =
  (medium: Medium, data: Array<NotificationByLanguage>) => (i: number) => {
    const _data = data[i];

    switch (medium) {
      case Medium.email:
        return (
          _data.emailContent &&
          _data.emailSubject &&
          _data.emailSenderEmail &&
          _data.emailSenderName
        );
      case Medium.sms:
        return _data.smsContent && _data.smsSenderName;
      case Medium.push:
        return _data.pushNotificationTitle && _data.pushNotificationContent;
      default:
        return never(medium);
    }
  };

export const doesntUseChosenFallbackLanguage = ({
  useFallbackLanguage,
}: TabData) => !useFallbackLanguage;

export const never = (never: never) => never;

export const hasRecipients =
  (
    medium: Medium,
    notificationLanguages: Array<NotificationLanguage>
  ): ((tab: TabData) => boolean) =>
  (tab: TabData) => {
    let recipientKey: Nullable<
      'emailRecipients' | 'smsRecipients' | 'pushRecipients'
    > = null;

    switch (medium) {
      case Medium.email:
        recipientKey = 'emailRecipients';
        break;
      case Medium.sms:
        recipientKey = 'smsRecipients';
        break;
      case Medium.push:
        recipientKey = 'pushRecipients';
        break;
      default:
        return never(medium);
    }

    return Boolean(
      notificationLanguages?.find(
        (lang) => lang.languageId === tab.language.id
      )?.[recipientKey]
    );
  };

export const getRecipientsCountByLanguageId = (
  addressees: Array<AddresseeData>
): RecipientsCountByLanguageId => {
  const countMapByLanguage: RecipientsCountByLanguageId = {};

  addressees.forEach((recipient) => {
    const { languageId } = recipient;

    if (!countMapByLanguage[languageId]) {
      countMapByLanguage[languageId] = {
        emailRecipients: 0,
        smsRecipients: 0,
        pushRecipients: 0,
      };
    }

    if (recipient.email) {
      countMapByLanguage[languageId].emailRecipients++;
    }

    if (recipient.phoneNumber) {
      countMapByLanguage[languageId].smsRecipients++;
    }

    if (recipient.customerProfileId) {
      countMapByLanguage[languageId].pushRecipients++;
    }
  });
  return countMapByLanguage;
};

export const createNotificationLanguagesData = (
  recipients: RecipientsCountByLanguageId,
  languages: Map<string, string>
): Array<NotificationLanguage> =>
  Object.keys(recipients).map((languageId) => ({
    languageId,
    language: languages.get(languageId)!,
    useFallbackLanguage: false,
    ...recipients[languageId],
  }));
