import { FC, useCallback, useMemo } from 'react';
import { Table, TableColumns, useRowActive, useTable } from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { usePagination, useRowSelect } from 'react-table';
import { Stack, Typography } from '@mui/material';
import { Button } from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import { useDispatch, useSelector } from 'store/utils';
import { notificationTemplatesListSelector } from 'features/notifications/notificationsSelectors';
import { NotificationTemplatePreview } from 'dto/api/notifications-management';
import {
  getNotificationTemplateById,
  getNotificationTemplatesList,
} from 'features/notifications/notificationsActions';
import { renderCheckmarkIfTrue } from 'helpers/checkmark';
import { useRowSelectRadio } from '@fleet/shared';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { NotificationTemplateSearch } from 'dto/notificationContent';

interface TemplatesTableProps {
  filter: NotificationTemplateSearch;
  setFilter: (value: NotificationTemplateSearch) => void;
}

export const TemplatesTable: FC<TemplatesTableProps> = ({
  filter,
  setFilter,
}) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const templates = useSelector(notificationTemplatesListSelector);
  const data = useMemo(() => templates?.items ?? [], [templates]);
  const columns = useMemo<TableColumns<NotificationTemplatePreview>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
      },
      {
        id: 'organization.name',
        accessor: ({ organization }) => organization?.name || '-',
        Header: <TransTableHead i18nKey="owner" />,
      },
      {
        id: 'type.name',
        accessor: ({ type }) => type?.name || '-',
        Header: <TransTableHead i18nKey="type" />,
      },
      {
        id: 'languages',
        accessor: ({ languages }) =>
          languages?.map((lang) => lang.name).join(', ') || '-',
        Header: <TransTableHead i18nKey="languages" />,
      },
      {
        accessor: 'hasEmailContent',
        Header: <TransTableHead i18nKey="email" />,
        width: '60px',
        Cell: ({
          row: {
            original: { hasEmailContent },
          },
        }) => renderCheckmarkIfTrue(hasEmailContent),
      },
      {
        accessor: 'hasSmsContent',
        Header: 'SMS',
        width: '60px',
        Cell: ({
          row: {
            original: { hasSmsContent },
          },
        }) => renderCheckmarkIfTrue(hasSmsContent),
      },
      // { (uncomment once Push functionality supported)
      //   accessor: 'hasPushContent',
      //   Header: <TransTableHead i18nKey="pushNotification" />,
      //   Cell: ({
      //     row: {
      //       original: { hasPushContent },
      //     },
      //   }) => renderCheckmarkIfTrue(hasPushContent),
      // },
    ],
    []
  );

  const getRowId = useCallback(
    (row: NotificationTemplatePreview) => `${row.id}`,
    []
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) => {
      setFilter({
        ...filter,
        ...paginationParams,
      });
      dispatch(
        getNotificationTemplatesList({ ...filter, ...paginationParams })
      );
    },
    [dispatch, filter, setFilter]
  );

  const table = useTable(
    {
      data,
      columns,
      total: templates?.totalCount,
      offset: templates?.offset,
      limit: filter.limit,
      onPageChange: handlePageChange,
      getRowId,
    },
    usePagination,
    useRowActive,
    useRowSelectRadio,
    useRowSelect
  );
  const { selectedFlatRows } = table;
  const selectedTemplateId = useMemo(
    () => selectedFlatRows[0]?.original.id,
    [selectedFlatRows]
  );

  const handleTemplateSubmit = async () => {
    if (!selectedTemplateId) {
      return;
    }

    await dispatch(getNotificationTemplateById(selectedTemplateId))
      .unwrap()
      .then(() => {
        alert.success(<TransAlert i18nKey="fillFromTemplateSuccess" />);
      })
      .catch(() => {
        alert.error(<TransAlert i18nKey="fillFromTemplateFail" />);
      });
  };

  return (
    <Stack direction="column">
      <Table
        table={table}
        variant="scrollable"
        sx={{ borderWidth: 1 }}
        data-testid="templates-table"
      />
      <Button
        icon="edit"
        disabled={!table.selectedFlatRows?.length}
        onClick={() => handleTemplateSubmit()}
        sx={{ width: '161px', mt: 2 }}
        data-testid="notification-template-submit"
      >
        <Typography sx={{ minWidth: 'fit-content' }}>
          <TransButton i18nKey="fillFromTemplate" />
        </Typography>
      </Button>
    </Stack>
  );
};
