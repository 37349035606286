import { Button, FormControl } from '@fleet/shared';
import { Divider, Grid, Stack } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { FC } from 'react';

interface SearchControlProps {
  isDividerHidden?: boolean;
  handleReset: () => void;
}

export const SearchControl: FC<SearchControlProps> = ({
  isDividerHidden = false,
  handleReset,
}) => {
  return (
    <>
      {!isDividerHidden && (
        <Grid item xs={5}>
          <Divider />
        </Grid>
      )}
      <Grid item xs="auto" sx={{ ml: 'auto' }}>
        <Stack direction="row" spacing={2}>
          <FormControl label="&nbsp;">
            <Button
              sx={{ whiteSpace: 'nowrap' }}
              variant="text"
              onClick={handleReset}
            >
              <TransButton i18nKey="resetFilters" />
            </Button>
          </FormControl>
          <FormControl label="&nbsp;">
            <Button
              variant="contained"
              type="submit"
              icon="search"
              data-testid="passengers-search-by-trips-submit"
            >
              <TransButton i18nKey="search" />
            </Button>
          </FormControl>
        </Stack>
      </Grid>
    </>
  );
};
