import {
  Button,
  currentDateTimeFormat,
  formatDate,
  SearchForm,
  SearchResult,
  Table,
  TableColumns,
  useRowActive,
  useTable,
} from '@fleet/shared';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { TableCaption } from '@fleet/shared/components/Table/TableCaption';
import { Divider } from '@mui/material';
import { tripPassengerOverviewLoadingSelector } from 'features/loading/loadingSelectors';
import { TransButton } from 'i18n/trans/button';
import { FC, useCallback, useMemo } from 'react';
import { usePagination, useRowSelect } from 'react-table';
import { useDispatch, useSelector } from 'store/utils';
import { Admission } from 'dto/api/deviations-management';
import {
  tripPassengerOverviewFilterSelector,
  tripPassengerOverviewSelector,
} from 'features/tripPassengerOverview/tripPassengerOverviewSelectors';
import {
  getTripPassengerOverview,
  setTripPassengerOverviewFilter,
} from 'features/tripPassengerOverview/tripPassengerOverviewActions';
import { SearchByTripForm } from 'components/Search/SearchByTripForm';
import { TransTitle } from 'i18n/trans/title';
import { TransTableHead } from 'i18n/trans/table';
import { currentLocaleConfiguration } from '@fleet/shared/i18n';
import * as XLSX from 'xlsx';
import { parseFullName } from 'helpers/parse';

const TABLE_ID = 'TRIP_PASSENGER_OVERVIEW';

interface TripPassengerOverviewTableProps {}

export const TripPassengerOverviewTable: FC<TripPassengerOverviewTableProps> =
  () => {
    const dispatch = useDispatch();
    const passengers = useSelector(tripPassengerOverviewSelector);
    const filter = useSelector(tripPassengerOverviewFilterSelector);
    const loading = useSelector(tripPassengerOverviewLoadingSelector);
    const data = useMemo(() => passengers?.items ?? [], [passengers]);

    const columns = useMemo<TableColumns<Admission>>(
      () => [
        {
          accessor: 'bookingNumber',
          Header: <TransTableHead i18nKey="bookingNumber" />,
        },
        {
          id: 'admissionNumber',
          accessor: ({ admissionNumber }) => `${admissionNumber}`,
          Header: <TransTableHead i18nKey="ticketNumber" />,
        },
        {
          id: 'ticketStatus',
          accessor: ({ admissionStatus }) => admissionStatus.name ?? ' - ',
          Header: <TransTableHead i18nKey="ticketStatus" />,
        },
        {
          id: 'passengerName',
          accessor: ({ passenger: { firstName, lastName } }) =>
            parseFullName(firstName, lastName),
          Header: <TransTableHead i18nKey="passengerName" />,
        },
        {
          id: 'paxType',
          accessor: ({ passenger: { category } }) => category?.name ?? ' - ',
          Header: <TransTableHead i18nKey="paxType" />,
        },
        {
          id: 'gender',
          accessor: ({ passenger: { gender } }) => gender?.name ?? ' - ',
          Header: <TransTableHead i18nKey="gender" />,
        },
        {
          id: 'email',
          accessor: ({ passenger: { email } }) => email ?? ' - ',
          Header: <TransTableHead i18nKey="email" />,
        },
        {
          id: 'phone',
          accessor: ({ passenger: { phone } }) => phone || ' - ',
          Header: <TransTableHead i18nKey="phone" />,
        },
        {
          id: 'admissionProduct',
          accessor: ({ admissionProduct }) => admissionProduct ?? ' - ',
          Header: <TransTableHead i18nKey="admissionProduct" />,
        },
        {
          id: 'price',
          accessor: ({ price, currency }) =>
            price && currency.name ? `${price} ${currency.name}` : ' - ',
          Header: <TransTableHead i18nKey="price" />,
        },
        {
          id: 'service',
          accessor: ({ trip: { serviceCode } }) => serviceCode ?? ' - ',
          Header: <TransTableHead i18nKey="service" />,
        },
        {
          id: 'trip',
          accessor: ({ trip }) => trip.name ?? ' - ',
          Header: <TransTableHead i18nKey="trip" />,
        },
        {
          id: 'tripDateAndTime',
          accessor: ({ trip }) =>
            trip.departureDateTime
              ? formatDate(trip.departureDateTime, currentDateTimeFormat)
              : ' - ',
          Header: <TransTableHead i18nKey="tripDateAndTime" />,
        },
        {
          id: 'origin',
          accessor: ({ journeyLeg }) => journeyLeg.originStop.name ?? ' - ',
          Header: <TransTableHead i18nKey="origin" />,
        },
        {
          id: 'departureDate',
          accessor: ({ journeyLeg }) =>
            journeyLeg.departureTime
              ? formatDate(
                  journeyLeg.departureTime,
                  currentLocaleConfiguration.dateFormat
                )
              : ' - ',
          Header: <TransTableHead i18nKey="departureDate" />,
        },
        {
          id: 'departureTime',
          accessor: ({ journeyLeg }) =>
            journeyLeg.departureTime
              ? formatDate(
                  journeyLeg.departureTime,
                  currentLocaleConfiguration.timeFormat
                )
              : ' - ',
          Header: <TransTableHead i18nKey="departureTime" />,
        },
        {
          id: 'destination',
          accessor: ({ journeyLeg }) =>
            journeyLeg.destinationStop.name ?? ' - ',
          Header: <TransTableHead i18nKey="destination" />,
        },
        {
          id: 'arrivalDate',
          accessor: ({ journeyLeg }) =>
            journeyLeg.arrivalTime
              ? formatDate(
                  journeyLeg.arrivalTime,
                  currentLocaleConfiguration.dateFormat
                )
              : ' - ',
          Header: <TransTableHead i18nKey="arrivalDate" />,
        },
        {
          id: 'arrivalTime',
          accessor: ({ journeyLeg }) =>
            journeyLeg.arrivalTime
              ? formatDate(
                  journeyLeg.arrivalTime,
                  currentLocaleConfiguration.timeFormat
                )
              : ' - ',
          Header: <TransTableHead i18nKey="arrivalTime" />,
        },
        {
          id: 'inventoryClass',
          accessor: ({ vehicle: { places } }) =>
            places.length
              ? places.map(({ inventoryClass }) => inventoryClass).join(', ')
              : ' - ',
          Header: <TransTableHead i18nKey="inventoryClass" />,
        },
        {
          id: 'place',
          accessor: ({ vehicle: { places } }) =>
            places.length
              ? places.map(({ number }) => number || ' - ').join(', ')
              : ' - ',
          Header: <TransTableHead i18nKey="place" />,
        },
        {
          id: 'placeProperties',
          accessor: ({ vehicle: { places } }) =>
            places.length
              ? places
                  .map(({ properties }) =>
                    properties.length
                      ? properties.map(({ name }) => name ?? ' - ').join(', ')
                      : ' - '
                  )
                  .join(', ')
              : ' - ',
          Header: <TransTableHead i18nKey="placeProperties" />,
        },
        {
          id: 'carriage',
          accessor: ({ vehicle: { carriage } }) => carriage ?? ' - ',
          Header: <TransTableHead i18nKey="carriage" />,
        },
        {
          id: 'carriageType',
          accessor: ({ vehicle: { name } }) => name ?? ' - ',
          Header: <TransTableHead i18nKey="carriageType" />,
        },
        {
          id: 'compartment',
          accessor: ({ vehicle: { compartment } }) => compartment ?? ' - ',
          Header: <TransTableHead i18nKey="compartment" />,
        },
        {
          id: 'previousLegService',
          accessor: ({ previousJourneyLeg }) =>
            previousJourneyLeg?.trip.serviceCode ?? ' - ',
          Header: <TransTableHead i18nKey="previousLegService" />,
        },
        {
          id: 'previousLegTrip',
          accessor: ({ previousJourneyLeg }) =>
            previousJourneyLeg?.trip.name ?? ' - ',
          Header: <TransTableHead i18nKey="previousLegTrip" />,
        },
        {
          id: 'previousLegDestination',
          accessor: ({ previousJourneyLeg }) =>
            previousJourneyLeg?.destinationStop.name ?? ' - ',
          Header: <TransTableHead i18nKey="previousLegDestination" />,
        },
        {
          id: 'previousLegArrivalTime',
          accessor: ({ previousJourneyLeg }) =>
            previousJourneyLeg?.arrivalTime
              ? formatDate(
                  previousJourneyLeg.arrivalTime,
                  currentLocaleConfiguration.timeFormat
                )
              : ' - ',
          Header: <TransTableHead i18nKey="previousLegArrivalTime" />,
        },
        {
          id: 'nextLegService',
          accessor: ({ nextJourneyLeg }) =>
            nextJourneyLeg?.trip.serviceCode ?? ' - ',
          Header: <TransTableHead i18nKey="nextLegService" />,
        },
        {
          id: 'nextLegTrip',
          accessor: ({ nextJourneyLeg }) => nextJourneyLeg?.trip.name ?? ' - ',
          Header: <TransTableHead i18nKey="nextLegTrip" />,
        },
        {
          id: 'nextLegOrigin',
          accessor: ({ nextJourneyLeg }) =>
            nextJourneyLeg?.originStop.name ?? ' - ',
          Header: <TransTableHead i18nKey="nextLegOrigin" />,
        },
        {
          id: 'nextLegDepartureTime',
          accessor: ({ nextJourneyLeg }) =>
            nextJourneyLeg?.departureTime
              ? formatDate(
                  nextJourneyLeg.departureTime,
                  currentLocaleConfiguration.timeFormat
                )
              : ' - ',
          Header: <TransTableHead i18nKey="nextLegDepartureTime" />,
        },
        {
          id: 'journeyFinalDestination',
          accessor: ({ journeyDestinationStop }) =>
            journeyDestinationStop.name ?? ' - ',
          Header: <TransTableHead i18nKey="journeyFinalDestination" />,
        },
        {
          id: 'ticketTags',
          accessor: ({ ticketTags }) =>
            ticketTags.length
              ? ticketTags.map((ticketTag) => ticketTag || ' - ').join(', ')
              : ' - ',
          Header: <TransTableHead i18nKey="ticketTags" />,
        },
      ],
      []
    );

    const handlePageChange = useCallback(
      async (paginationParams: PaginationParams) =>
        dispatch(getTripPassengerOverview({ ...filter, ...paginationParams })),
      [dispatch, filter]
    );

    const table = useTable<Admission>(
      {
        data,
        columns,
        total: passengers?.totalCount,
        offset: passengers?.offset,
        limit: filter.limit,
        onPageChange: handlePageChange,
      },
      usePagination,
      useRowActive,
      useRowSelect
    );

    const handleExportClick = useCallback(() => {
      const table_elt = document.getElementById(TABLE_ID);
      const workbook = XLSX.utils.table_to_book(table_elt, { raw: true });
      XLSX.writeFile(
        workbook,
        `${TABLE_ID}_${formatDate(new Date(), currentDateTimeFormat)}.xlsx`
      );
    }, []);

    return (
      <>
        <SearchForm title={<TransTitle i18nKey="tripPassengerOverview" />}>
          <SearchByTripForm
            passengersFilterSelector={tripPassengerOverviewFilterSelector}
            setPassengersFilter={setTripPassengerOverviewFilter}
            getPassengers={getTripPassengerOverview}
          />
        </SearchForm>
        <Divider />
        <SearchResult results={!!data.length} loading={loading}>
          <Table
            tableId={TABLE_ID}
            caption={
              <TableCaption>
                <Button
                  variant="text"
                  onClick={handleExportClick}
                  data-testid="trip-passenger-export-excel"
                  sx={{ p: 0 }}
                >
                  <TransButton i18nKey="exportToExcel" />
                </Button>
              </TableCaption>
            }
            table={table}
          />
        </SearchResult>
      </>
    );
  };
