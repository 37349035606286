import { createSelector, selector } from 'store/utils';

const selectPassengersAllocationFilterByTrip = selector(
  (state) => state.passengersAllocation.filterByTrips
);
export const passengersAllocationFilterByTripSelector = createSelector(
  selectPassengersAllocationFilterByTrip
);

const selectPassengersAllocationByTrip = selector(
  (state) => state.passengersAllocation.listByTrips
);
export const passengersAllocationSelectorByTripSelector = createSelector(
  selectPassengersAllocationByTrip
);

const selectPassengersAllocationTab = selector(
  (state) => state.passengersAllocation.tab
);
export const passengersAllocationTabSelector = createSelector(
  selectPassengersAllocationTab
);
