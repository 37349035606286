import { createReducer } from '@reduxjs/toolkit';
import {
  getNotificationTemplatesList,
  setActiveStep,
  setNotificationAddressees,
  setNotificationFilters,
  getNotificationTemplateById,
  setNotificationTemplateFilter,
  updateNotificationLanguages,
  setNotificationLanguagesData,
  getValidatedNotifications,
  setMappedNotificationContentFormValues,
  resetNotificationStore,
  setNotificationSummaryData,
  getPotentialAddressees,
  getAddresseesByReallocationId,
  setTrackingId,
  getAddresseesByProblematicPassengers,
} from './notificationsActions';
import { Step } from 'consts';
import {
  RecipientsCountByLanguageId,
  NotificationContent,
  NotificationSummary,
  AddresseesFilter,
} from 'dto/notificationContent';
import { NotificationAddressee } from 'dto/api/deviations-management';
import { AddresseeData } from 'routes/SendNotifications/NotificationAddressees';

export interface CommonState {
  notificationFilters: {
    filter: Partial<AddresseesFilter>;
    list: Array<NotificationAddressee>;
  };
  notificationAddressees: {
    list: Array<AddresseeData>;
  };
  notificationContent: NotificationContent;
  notificationSummary: NotificationSummary;
  activeStep: Step;
  addresseesSummary?: RecipientsCountByLanguageId;
}

const initialState: CommonState = {
  notificationFilters: {
    filter: {},
    list: [],
  },
  notificationAddressees: {
    list: [],
  },
  notificationContent: {
    templates: {},
    notificationChannels: {
      list: [],
    },
    notificationLanguages: {},
    validation: {
      list: [],
    },
  },
  notificationSummary: {
    totalEmailRecipients: 0,
    totalSmsRecipients: 0,
    totalPushRecipients: 0,
    trackingId: null,
  },
  activeStep: Step.Filters,
};

export const notificationsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setActiveStep, (state, action) => {
      state.activeStep = action.payload;
    })
    .addCase(setTrackingId, (state, action) => {
      state.notificationSummary.trackingId = action.payload;
    })
    .addCase(updateNotificationLanguages, (state, action) => {
      const { checked, idx } = action.payload;
      const languages = state.notificationContent.notificationLanguages.list;
      if (!languages) {
        return;
      }

      languages[idx] = {
        ...languages[idx],
        useFallbackLanguage: checked,
      };

      state.notificationContent.notificationLanguages.list = languages;
    })
    .addCase(setNotificationFilters, (state, action) => {
      state.notificationFilters.filter = action.payload;
    })
    .addCase(setNotificationAddressees, (state, action) => {
      state.notificationAddressees.list = action.payload;
    })
    .addCase(getNotificationTemplatesList.fulfilled, (state, action) => {
      state.notificationContent.templates.list = action.payload;
    })
    .addCase(setNotificationTemplateFilter, (state, action) => {
      state.notificationContent.templates.filter = action.payload;
    })
    .addCase(getNotificationTemplateById.fulfilled, (state, action) => {
      state.notificationContent.templates.current = action.payload;
    })
    .addCase(setNotificationLanguagesData, (state, action) => {
      state.notificationContent.notificationLanguages.list = action.payload;
    })
    .addCase(getValidatedNotifications.fulfilled, (state, action) => {
      state.notificationContent.validation.list =
        action.payload.notificationsByLanguage || [];
    })
    .addCase(setMappedNotificationContentFormValues, (state, action) => {
      state.notificationContent.validation.formValues = action.payload;
    })
    .addCase(resetNotificationStore, (state) => {
      state.notificationFilters = initialState.notificationFilters;
      state.notificationAddressees = initialState.notificationAddressees;
      state.notificationContent = initialState.notificationContent;
      state.notificationSummary = initialState.notificationSummary;
      state.addresseesSummary = initialState.addresseesSummary;
    })
    .addCase(setNotificationSummaryData, (state, action) => {
      state.notificationSummary = {
        ...state.notificationSummary,
        ...action.payload,
      };
    })
    .addCase(getPotentialAddressees.fulfilled, (state, action) => {
      state.notificationFilters.list = action.payload;
    })
    .addCase(getAddresseesByReallocationId.fulfilled, (state, action) => {
      state.notificationAddressees.list = action.payload;
    })
    .addCase(
      getAddresseesByProblematicPassengers.fulfilled,
      (state, action) => {
        state.notificationAddressees.list = action.payload;
      }
    );
});
