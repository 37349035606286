import {
  FormProvider,
  Loadable,
  Table,
  formSubmit,
  useForm,
} from '@fleet/shared';
import { makeStyles } from '@mui/styles';
import {
  Admission,
  ReAccommodateDetailedTripStop,
} from 'dto/api/deviations-management';
import { reAccommodateLoadingSelector } from 'features/loading/loadingSelectors';
import {
  setReAccommodateActiveStep,
  startReAccommodateCurrentProcess,
} from 'features/reAccommodate/reAccommodateActions';
import {
  reAccommodateMismatchesDictionarySelector,
  reAccommodateNewDetailedTripSelector,
  reAccommodatePassengersListSelector,
} from 'features/reAccommodate/reAccommodateSelectors';
import {
  NEW_DESTINATION_COLUMN_NAME,
  NEW_ORIGIN_COLUMN_NAME,
  usePassengersAllocationColumns,
} from 'hooks/passengersAllocation/usePassengersAllocationColumns';
import { FC, useCallback, useMemo, useState, useRef } from 'react';
import { useTable } from 'react-table';
import { useDispatch, useSelector } from 'store/utils';

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    minHeight: 82,
  },
}));

interface SelectTripMismatchModalPassengersTableProps {
  onClose: () => void;
}

export const SelectTripMismatchModalPassengersTable: FC<SelectTripMismatchModalPassengersTableProps> =
  ({ onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const passengersList = useSelector(reAccommodatePassengersListSelector);
    const mismatchesDictionary = useSelector(
      reAccommodateMismatchesDictionarySelector
    );
    const initialMismatchesDictionary = useRef(mismatchesDictionary);
    const newDetailedTrip = useSelector(reAccommodateNewDetailedTripSelector);
    const loading = useSelector(reAccommodateLoadingSelector);

    const [updatedPassengersList, setUpdatedPassengersList] =
      useState(passengersList);
    const data = useMemo(
      () => Object.values(mismatchesDictionary),
      [mismatchesDictionary]
    );

    const stopOptions = useMemo(
      () =>
        (newDetailedTrip?.routeStops ?? []).map(({ routeStopId, stop }) => ({
          value: routeStopId,
          label: stop?.name ?? ' - ',
        })),
      [newDetailedTrip]
    );

    const onSubmit = useCallback(
      async (
        values: Record<
          Admission['admissionNumber'],
          ReAccommodateDetailedTripStop['routeStopId']
        >
      ) =>
        formSubmit(async () => {
          (document.activeElement as HTMLInputElement)?.blur?.();

          if (!newDetailedTrip) {
            return;
          }

          const newPassengersList = updatedPassengersList.map((passenger) => ({
            ...passenger,
            journeyLeg: {
              ...passenger.journeyLeg,
              ...(mismatchesDictionary[passenger.admissionNumber] && {
                originRouteStopId:
                  values[
                    `${passenger.admissionNumber}_${NEW_ORIGIN_COLUMN_NAME}`
                  ],
                destinationRouteStopId:
                  values[
                    `${passenger.admissionNumber}_${NEW_DESTINATION_COLUMN_NAME}`
                  ],
              }),
            },
          }));

          setUpdatedPassengersList(newPassengersList);

          const payload = {
            newTripId: newDetailedTrip.tripId,
            admissions: newPassengersList.map(
              ({
                admissionNumber,
                journeyLeg: { originRouteStopId, destinationRouteStopId },
                trip: { id: oldTripId },
              }) => ({
                admissionNumber,
                oldTripId,
                newOriginRouteId:
                  initialMismatchesDictionary.current[admissionNumber] &&
                  originRouteStopId,
                newDestinationRouteId:
                  initialMismatchesDictionary.current[admissionNumber] &&
                  destinationRouteStopId,
              })
            ),
          };

          const { mismatchedAdmissions } = await dispatch(
            startReAccommodateCurrentProcess(payload)
          ).unwrap();

          if (!mismatchedAdmissions.mismatches?.routes.length) {
            onClose();
            dispatch(setReAccommodateActiveStep('confirmChanges'));
          }
        }),
      [
        dispatch,
        mismatchesDictionary,
        newDetailedTrip,
        onClose,
        updatedPassengersList,
      ]
    );

    const { form, handleSubmit } = useForm({
      onSubmit,
    });

    const { columns, hiddenColumns } =
      usePassengersAllocationColumns(stopOptions);

    const table = useTable<Admission>({
      data,
      columns,
      initialState: { hiddenColumns },
    });

    return (
      <Loadable loading={loading}>
        <FormProvider form={form}>
          <form
            onSubmit={handleSubmit}
            id="newStopsForm"
            className={classes.form}
          >
            <Table table={table} />
          </form>
        </FormProvider>
      </Loadable>
    );
  };
