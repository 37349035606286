import { Icon, Theme } from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TransTitle } from 'i18n/trans/title';
import { FC, useCallback } from 'react';

const useStyles = makeStyles<
  Theme,
  Pick<ReAccommodateConfirmChangesCardProps, 'isSuccessful'>
>((theme) => {
  const getColor = (isSuccessful?: boolean) =>
    isSuccessful ? theme.palette.success.main : theme.palette.error.main;

  return {
    root: ({ isSuccessful }) => ({
      padding: theme.spacing(3),
      width: 400,
      border: `2px solid ${getColor(isSuccessful)}`,
      borderRadius: 8,
    }),
    icon: ({ isSuccessful }) => {
      const color = getColor(isSuccessful);

      return {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 80,
        height: 80,
        backgroundColor: `${color}1a`,
        borderRadius: '100%',
        '& .Icon-root': {
          color,
        },
      };
    },
  };
});

interface ReAccommodateConfirmChangesCardProps {
  count?: number;
  isSuccessful?: boolean;
}

export const ReAccommodateConfirmChangesCard: FC<ReAccommodateConfirmChangesCardProps> =
  ({ count, isSuccessful = false }) => {
    const classes = useStyles({ isSuccessful });

    const getIconName = useCallback(() => {
      if (!count) {
        return 'error-circle';
      }

      if (isSuccessful) {
        return 'ticket-change';
      }

      return 'ticket-cancel';
    }, [count, isSuccessful]);

    const getI18nKey = useCallback(() => {
      if (!count) {
        return 'passengersReAccommodatedError';
      }

      if (isSuccessful) {
        return 'passengersReAccommodatedQty';
      }

      return 'passengersUnresolvedQty';
    }, [count, isSuccessful]);

    return (
      <Stack className={classes.root} direction="row" spacing={3}>
        <div className={classes.icon}>
          <Icon name={getIconName()} size={40} />
        </div>
        <Typography variant="h2">
          <TransTitle
            i18nKey={getI18nKey()}
            values={{
              count,
            }}
          />
        </Typography>
      </Stack>
    );
  };
