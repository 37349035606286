import { api } from '@fleet/shared';
import qs from 'qs';
import type {
  GetLinesItem,
  GetLinesResponse,
  GetLinesSearchParams,
} from 'dto/api/line-management';

import type {
  GetAncillaryTypesItem,
  GetAncillaryTypesResponse,
  GetAncillaryTypesSearchParams,
} from 'dto/api/product-management';

export const fetchLines = async (
  filter: Partial<GetLinesSearchParams>
): Promise<GetLinesItem[]> =>
  (
    await api.get<GetLinesResponse>(
      `/lines${qs.stringify(filter, {
        addQueryPrefix: true,
        skipNulls: true,
      })}`,
      {
        baseURL: process.env.REACT_APP_API_LM,
      }
    )
  ).data.items;

export const fetchAncillaryTypes = async (
  filter: GetAncillaryTypesSearchParams
): Promise<Array<GetAncillaryTypesItem>> =>
  (
    await api.get<GetAncillaryTypesResponse>(
      `/ancillary-types${qs.stringify(filter, {
        skipNulls: true,
        addQueryPrefix: true,
      })}`,
      { baseURL: process.env.REACT_APP_API_PM }
    )
  ).data.items;
