import { Icon } from '@fleet/shared';
import React from 'react';
import { Nullable } from '@fleet/shared/@types/openapi-typescript';

export const renderCheckmarkIfTrue = (condition: Nullable<boolean>) => {
  if (condition) {
    return <Icon name="check" color="success" />;
  }
  return <></>;
};
