import React, { FC } from 'react';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { Stack, Typography } from '@mui/material';
import { SmsTabs } from './SmsTabs';
import { Highlight } from 'components/Highlight';
interface SmsProps {
  hasRecipients: boolean;
}

export const Sms: FC<SmsProps> = ({ hasRecipients }) => {
  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <Typography variant="subtitle" fontWeight="700">
        <TransSubtitle i18nKey="sms" />
      </Typography>
      <Highlight>
        {hasRecipients ? (
          <SmsTabs />
        ) : (
          <Typography>
            <TransSubtitle i18nKey="noSmsReceivers" />
          </Typography>
        )}
      </Highlight>
    </Stack>
  );
};
