import { Table } from '@fleet/shared';
import { ReAccommodateDetailedTripStop } from 'dto/api/deviations-management';
import { getReAccommodateNewDetailedTrip } from 'features/reAccommodate/reAccommodateActions';
import { reAccommodateNewDetailedTripsSelector } from 'features/reAccommodate/reAccommodateSelectors';
import { useDetailedTripColumns } from 'hooks/passengersAllocation/useDetailedTripColumns';
import { TransField } from 'i18n/trans/field';
import { FC, useEffect, useMemo } from 'react';
import { useTable } from 'react-table';
import { useDispatch, useSelector } from 'store/utils';

interface ReAccommodateSelectTripSubTableProps {
  tripId: number;
}

export const ReAccommodateSelectTripSubTable: FC<ReAccommodateSelectTripSubTableProps> =
  ({ tripId }) => {
    const dispatch = useDispatch();
    const detailedTrips = useSelector(reAccommodateNewDetailedTripsSelector);
    const data = useMemo(
      () => detailedTrips?.[tripId]?.routeStops ?? [],
      [detailedTrips, tripId]
    );

    const { columns, hiddenColumns } = useDetailedTripColumns();

    useEffect(() => {
      dispatch(getReAccommodateNewDetailedTrip(tripId));
    }, [dispatch, tripId]);

    const table = useTable<ReAccommodateDetailedTripStop>({
      data,
      columns,
      initialState: { hiddenColumns },
    });

    return (
      <Table table={table} emptyLabel={<TransField i18nKey="noStops" />} />
    );
  };
