import { createTrans } from 'i18n/trans';
import { Trans } from 'react-i18next';

export const TransField = createTrans({
  active: <Trans i18nKey="field.active" defaults="Active" />,
  bookingNumber: (
    <Trans i18nKey="field.bookingNumber" defaults="Booking number" />
  ),
  carriageName: (
    <Trans i18nKey="field.carriageName" defaults="Carriage name/code" />
  ),
  carrier: <Trans i18nKey="field.carrier" defaults="Carrier" />,
  city: <Trans i18nKey="field.city" defaults="City" />,
  compositionName: (
    <Trans i18nKey="field.compositionName" defaults="Composition name/code" />
  ),
  country: <Trans i18nKey="field.country" defaults="Country" />,
  county: <Trans i18nKey="field.county" defaults="County" />,
  createdOnPeriod: (
    <Trans
      i18nKey="field.createdOnPeriod"
      defaults="Created on date (from-to)"
    />
  ),
  departureDateTime: (
    <Trans i18nKey="field.departureDateTime" defaults="Departure datetime" />
  ),
  departureDays: (
    <Trans i18nKey="field.departureDays" defaults="Departure days" />
  ),
  departureFrom: (
    <Trans i18nKey="field.departureFrom" defaults="Departure from" />
  ),
  departurePeriod: (
    <Trans
      i18nKey="field.departurePeriod"
      defaults="Departure date (from-to)"
    />
  ),
  departurePeriodFrom: (
    <Trans i18nKey="field.departurePeriodFrom" defaults="Departure from" />
  ),
  departurePeriodTo: (
    <Trans i18nKey="field.departurePeriodTo" defaults="Departure to" />
  ),
  departureStop: (
    <Trans i18nKey="field.departureStop" defaults="Departure stop" />
  ),
  departureTo: <Trans i18nKey="field.departureTo" defaults="Departure to" />,
  destinationStop: (
    <Trans i18nKey="field.destinationStop" defaults="Destination stop" />
  ),
  displaySelectedStops: (
    <Trans
      i18nKey="field.displaySelectedStops"
      defaults="Display selected stops"
    />
  ),
  emailContent: <Trans i18nKey="field.emailContent" defaults="Email content" />,
  emailSubject: <Trans i18nKey="field.emailSubject" defaults="Email subject" />,
  hasBeenReallocated: (
    <Trans
      i18nKey="field.hasBeenReallocated"
      defaults="Has passenger been reallocated"
    />
  ),
  isPartOfMultiLegJourney: (
    <Trans
      i18nKey="field.isPartOfMultiLegJourney"
      defaults="Is part of multi-leg journey"
    />
  ),
  isSolved: <Trans i18nKey="field.isSolved" defaults="Is solved" />,
  isTicketTagIncluded: (
    <Trans
      i18nKey="field.isTicketTagIncluded"
      defaults="Is ticket tag included"
    />
  ),
  language: <Trans i18nKey="field.language" defaults="Language" />,
  lineName: <Trans i18nKey="field.lineName" defaults="Line name" />,
  lineNumber: <Trans i18nKey="field.lineNumber" defaults="Line number" />,
  lineNumbers: <Trans i18nKey="field.lineNumbers" defaults="Line numbers" />,
  modifiedPeriod: (
    <Trans i18nKey="field.modifiedPeriod" defaults="Modified (from-to)" />
  ),
  name: <Trans i18nKey="field.name" defaults="Name" />,
  noStops: <Trans i18nKey="field.noStops" defaults="No stops" />,
  odFilteringMethod: (
    <Trans
      i18nKey="field.odFilteringMethod.label"
      defaults="OD filtering method"
    />
  ),
  'odFilteringMethod.EXACT': (
    <Trans i18nKey="field.odFilteringMethod.option.EXACT" defaults="Exact" />
  ),
  'odFilteringMethod.SUBSEGMENT': (
    <Trans
      i18nKey="field.odFilteringMethod.option.SUBSEGMENT"
      defaults="Subsegment"
    />
  ),
  'odFilteringMethod.OVERLAP': (
    <Trans
      i18nKey="field.odFilteringMethod.option.OVERLAP"
      defaults="Overlap"
    />
  ),
  originStop: <Trans i18nKey="field.originStop" defaults="Origin stop" />,
  passengerMovedFromTrip: (
    <Trans
      i18nKey="field.passengerMovedFromTrip"
      defaults="Passenger moved from (trip)"
    />
  ),
  passengerMovedToTrip: (
    <Trans
      i18nKey="field.passengerMovedToTrip"
      defaults="Passenger moved to (trip)"
    />
  ),
  problemCreatedOnPeriod: (
    <Trans
      i18nKey="field.problemCreatedOnPeriod"
      defaults="Problem created on (from-to)"
    />
  ),
  problemTypes: <Trans i18nKey="field.problemTypes" defaults="Problem types" />,
  pushNotificationContent: (
    <Trans
      i18nKey="field.pushNotificationContent"
      defaults="Push notification content"
    />
  ),
  pushNotificationTitle: (
    <Trans
      i18nKey="field.pushNotificationTitle"
      defaults="Push notification title"
    />
  ),
  salesPeriod: (
    <Trans i18nKey="field.salesPeriod" defaults="Sales date (from-to)" />
  ),
  sendSmsAs: <Trans i18nKey="field.sendSmsAs" defaults="Send SMS as" />,
  senderEmail: <Trans i18nKey="field.senderEmail" defaults="Sender email" />,
  senderName: <Trans i18nKey="field.senderName" defaults="Sender name" />,
  serviceCode: <Trans i18nKey="field.serviceCode" defaults="Service code" />,
  serviceCodes: <Trans i18nKey="field.serviceCodes" defaults="Service codes" />,
  smsContent: <Trans i18nKey="field.smsContent" defaults="SMS content" />,
  smsSenderName: <Trans i18nKey="field.smsSenderName" defaults="Sender name" />,
  stopMismatchWarning: (
    <Trans
      i18nKey="field.stopMismatchWarning"
      defaults="(0-1)[Origin and/or destination stops can not be matched with current trip for {{count}} passenger. Assign different origin and destination stops and/or flag the passenger as problematic.];(2-inf)[Origin and/or destination stops can not be matched with current trip for {{count}} passengers. Assign different origin and destination stops and/or flag the passengers as problematic.];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  stopOne: <Trans i18nKey="field.stopOne" defaults="Stop one" />,
  stopTwo: <Trans i18nKey="field.stopTwo" defaults="Stop two" />,
  streetHouseNumber: (
    <Trans i18nKey="field.streetHouseNumber" defaults="Street, house number" />
  ),
  ticketNumber: <Trans i18nKey="field.ticketNumber" defaults="Ticket number" />,
  ticketTag: <Trans i18nKey="field.ticketTag" defaults="Ticket tag" />,
  tripName: <Trans i18nKey="field.tripName" defaults="Trip name" />,
  tripStatus: <Trans i18nKey="field.tripStatus" defaults="Trip status" />,
});
