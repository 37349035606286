import { Button, ExternalLink, Icon } from '@fleet/shared';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReAccommodateProgress } from 'dto/reAccommodate';
import { passengersAllocationTabSelector } from 'features/passengersAllocation/passengersAllocationSelectors';
import {
  reAccommodatePassengersListSelector,
  reAccommodateTrackingIdSelector,
} from 'features/reAccommodate/reAccommodateSelectors';
import { passengersSearchCallbacks } from 'hooks/search/usePassengersSearchForm';
import { useSignalRProgress } from 'hooks/useSignalRProgress';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTitle } from 'i18n/trans/title';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ReAccommodateConfirmChangesCard } from 'routes/PassengersAllocation/ReAccommodate/ConfirmChanges/ReAccommodateConfirmChangesCard';
import { ReAccommodateStepProps } from 'routes/PassengersAllocation/ReAccommodate/ReAccommodateSteps';
import { useSelector } from 'store/utils';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  process: {
    margin: '0 auto',
    width: 410,
  },
  actions: {
    padding: theme.spacing(2),
    '& .MuiButton-text': { padding: 0 },
  },
}));

export const ReAccommodateConfirmChanges: FC<ReAccommodateStepProps> = ({
  onCancelReAccommodate,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const reAccommodatePassengersList = useSelector(
    reAccommodatePassengersListSelector
  );
  const processId = useSelector(reAccommodateTrackingIdSelector);
  const tab = useSelector(passengersAllocationTabSelector);
  const { passengersFilterSelector } = passengersSearchCallbacks[tab];
  const { carrierId } = useSelector(passengersFilterSelector);

  const [progress, setProgress] = useState<ReAccommodateProgress>();
  const isLoading = useSignalRProgress<ReAccommodateProgress>({
    processId,
    onNext: setProgress,
  });

  const getProgressReportDetails = () => {
    if (!progress?.progressReport) {
      return `0/${reAccommodatePassengersList.length}`;
    }

    const { successCount, errorCount, totalCount } = progress.progressReport;

    return `${successCount + errorCount}/${totalCount}`;
  };

  const renderProgressReport = useCallback(() => {
    if (
      !progress?.progressReport?.isEnded ||
      (progress.progressReport.successCount === 0 &&
        progress.progressReport.errorCount === 0)
    ) {
      return <ReAccommodateConfirmChangesCard />;
    }

    const { successCount, errorCount } = progress.progressReport;

    return (
      <>
        {successCount > 0 && (
          <ReAccommodateConfirmChangesCard isSuccessful count={successCount} />
        )}
        {errorCount > 0 && (
          <ReAccommodateConfirmChangesCard count={errorCount} />
        )}
      </>
    );
  }, [progress?.progressReport]);

  return (
    <>
      <div className={classes.content}>
        {isLoading ? (
          <Stack alignItems="end" className={classes.process}>
            <Stack spacing={5} direction="row" alignItems="center">
              <CircularProgress size={100} />
              <Stack>
                <Typography variant="h2" width={267}>
                  {getProgressReportDetails()}
                </Typography>
                <Typography variant="h2" width={267}>
                  <TransTitle i18nKey="passengersReAccommodated" />
                </Typography>
              </Stack>
            </Stack>
            <Typography width={267}>
              <TransSubtitle i18nKey="processPassengersReAccommodation" />{' '}
              <ExternalLink
                path={`/Admin/ProcessLogList/Logs/${processId}`}
                underline="none"
                content={t(
                  'subtitle.processPassengersReAccommodationLogs',
                  'Process logs'
                )}
              />
            </Typography>
          </Stack>
        ) : (
          <Stack
            direction="row"
            spacing={5}
            justifyContent="center"
            alignItems="start"
          >
            {renderProgressReport()}
          </Stack>
        )}
      </div>
      <Stack
        direction="row"
        justifyContent="flex-end"
        className={classes.actions}
        spacing={2}
      >
        <>
          <Button variant="text" onClick={onCancelReAccommodate}>
            <TransButton
              i18nKey={isLoading ? 'close' : 'viewUnresolvedPassengers'}
            />
          </Button>
          <ExternalLink
            path={`/Admin/ProcessLogList/Logs/${processId}`}
            underline="none"
            content={t('button.viewLogs', 'View logs')}
            display="inline-flex"
            alignItems="center"
          />
          <Button
            startIcon={<Icon name="arrow-right" />}
            onClick={() =>
              history.push({
                pathname: `/send-notifications`,
                state: { carrierId, processId },
              })
            }
            disabled={isLoading}
          >
            <TransButton i18nKey="sendNotifications" />
          </Button>
        </>
      </Stack>
    </>
  );
};
