import React, { FC } from 'react';
import { Box } from '@mui/material';

export const Highlight: FC = ({ children }) => {
  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: '#F9F9F9',
        border: '1px solid #E9ECF3',
      }}
      borderRadius={2}
      borderColor="#E9ECF3"
    >
      {children}
    </Box>
  );
};
