import { createTrans } from 'i18n/trans';
import { Trans } from 'react-i18next';

export const TransTableHead = createTrans({
  admissionNumber: (
    <Trans i18nKey="table.head.admissionNumber" defaults="Admission number" />
  ),
  admissionProduct: (
    <Trans i18nKey="table.head.admissionProduct" defaults="Admission product" />
  ),
  addon: <Trans i18nKey="table.head.addon" defaults="Add-on" />,
  arrival: <Trans i18nKey="table.head.arrival" defaults="Arrival" />,
  arrivalDate: (
    <Trans i18nKey="table.head.arrivalDate" defaults="Arrival date" />
  ),
  arrivalTime: (
    <Trans i18nKey="table.head.arrivalTime" defaults="Arrival time" />
  ),
  assignDestination: (
    <Trans
      i18nKey="table.head.assignDestination"
      defaults="Assign destination"
    />
  ),
  assignOrigin: (
    <Trans i18nKey="table.head.assignOrigin" defaults="Assign origin" />
  ),
  availabilityBeds: (
    <Trans
      i18nKey="table.head.availabilityBeds"
      defaults="Availability (beds)"
    />
  ),
  availabilityCompartments: (
    <Trans
      i18nKey="table.head.availabilityCompartments"
      defaults="Availability (compartments)"
    />
  ),
  availabilitySeats: (
    <Trans
      i18nKey="table.head.availabilitySeats"
      defaults="Availability (seats)"
    />
  ),
  bookingNumber: (
    <Trans i18nKey="table.head.bookingNumber" defaults="Booking number" />
  ),
  carrier: <Trans i18nKey="table.head.carrier" defaults="Carrier" />,
  carriage: <Trans i18nKey="table.head.carriage" defaults="Carriage" />,
  carriageType: (
    <Trans i18nKey="table.head.carriageType" defaults="Carriage type" />
  ),
  city: <Trans i18nKey="table.head.city" defaults="City" />,
  coach: <Trans i18nKey="table.head.coach" defaults="Coach" />,
  code: <Trans i18nKey="table.head.code" defaults="Code" />,
  comfortLevel: (
    <Trans i18nKey="table.head.comfortLevel" defaults="Comfort level" />
  ),
  compartment: (
    <Trans i18nKey="table.head.compartment" defaults="Compartment" />
  ),
  country: <Trans i18nKey="table.head.country" defaults="Country" />,
  county: <Trans i18nKey="table.head.county" defaults="County" />,
  departure: <Trans i18nKey="table.head.departure" defaults="Departure" />,
  departureDateTime: (
    <Trans
      i18nKey="table.head.departureDateTime"
      defaults="Departure datetime"
    />
  ),
  departureStop: (
    <Trans
      i18nKey="table.head.departureStop"
      defaults="Departure stop / zone"
    />
  ),
  departureDate: (
    <Trans i18nKey="table.head.departureDate" defaults="Departure date" />
  ),
  departureTime: (
    <Trans i18nKey="table.head.departureTime" defaults="Departure time" />
  ),
  description: (
    <Trans i18nKey="table.head.description" defaults="Description" />
  ),
  destination: <Trans i18nKey="field.destination" defaults="Destination" />,
  destinationStop: (
    <Trans i18nKey="field.destinationStop" defaults="Destination stop" />
  ),
  destinationStopZone: (
    <Trans
      i18nKey="field.destinationStopZone"
      defaults="Destination stop / zone"
    />
  ),
  email: <Trans i18nKey="table.head.email" defaults="Email" />,
  emailRecipients: (
    <Trans i18nKey="table.head.emailRecipients" defaults="E-mail recipients:" />
  ),
  firstName: <Trans i18nKey="table.head.firstName" defaults="First name" />,
  firstTicketNumber: (
    <Trans
      i18nKey="table.head.firstTicketNumber"
      defaults="Ticket number (1/2)"
    />
  ),
  gender: <Trans i18nKey="table.head.gender" defaults="Gender" />,
  hasTickets: <Trans i18nKey="table.head.hasTickets" defaults="Has tickets" />,
  inventoryClass: (
    <Trans i18nKey="table.head.inventoryClass" defaults="Inventory class" />
  ),
  isActive: <Trans i18nKey="table.head.isActive" defaults="Active" />,
  isChangeable: (
    <Trans i18nKey="table.head.isChangeable" defaults="Changeable" />
  ),
  isPartOfMultiLeg: (
    <Trans
      i18nKey="table.head.isPartOfMultiLeg"
      defaults="Is part of multi-leg"
    />
  ),
  isRefundable: (
    <Trans i18nKey="table.head.isRefundable" defaults="Refundable" />
  ),
  isSolved: <Trans i18nKey="table.head.isSolved" defaults="Is solved" />,
  journeyFinalDestination: (
    <Trans
      i18nKey="table.head.journeyFinalDestination"
      defaults="Journey final destination"
    />
  ),
  language: <Trans i18nKey="table.head.language" defaults="Language" />,
  languages: <Trans i18nKey="table.head.languages" defaults="Languages" />,
  line: <Trans i18nKey="table.head.line" defaults="Line" />,
  lineName: <Trans i18nKey="table.head.lineName" defaults="Line name" />,
  lineNumber: <Trans i18nKey="table.head.lineNumber" defaults="Line number" />,
  medium: <Trans i18nKey="table.head.medium" defaults="Medium" />,
  name: <Trans i18nKey="table.head.name" defaults="Name" />,
  newDestination: (
    <Trans i18nKey="table.head.newDestination" defaults="New destination" />
  ),
  newOrigin: <Trans i18nKey="table.head.newOrigin" defaults="New origin" />,
  nextLegOrigin: (
    <Trans i18nKey="table.head.nextLegOrigin" defaults="Next leg origin" />
  ),
  nextLegDepartureTime: (
    <Trans
      i18nKey="table.head.nextLegDepartureTime"
      defaults="Next leg departure time"
    />
  ),
  nextLegService: (
    <Trans i18nKey="table.head.nextLegService" defaults="Next leg service" />
  ),
  nextLegTrip: (
    <Trans i18nKey="table.head.nextLegTrip" defaults="Next leg trip" />
  ),
  origin: <Trans i18nKey="table.head.origin" defaults="Origin" />,
  originStop: <Trans i18nKey="table.head.originStop" defaults="Origin stop" />,
  originStopZone: (
    <Trans i18nKey="table.head.originStopZone" defaults="Origin stop / zone" />
  ),
  owner: <Trans i18nKey="table.head.owner" defaults="Owner" />,
  passengerCategory: (
    <Trans
      i18nKey="table.head.passengerCategory"
      defaults="Passenger category"
    />
  ),
  passengerName: (
    <Trans i18nKey="table.head.passengerName" defaults="Passenger name" />
  ),
  paxType: <Trans i18nKey="table.head.paxType" defaults="Pax type" />,
  phone: <Trans i18nKey="table.head.phone" defaults="Phone" />,
  place: <Trans i18nKey="table.head.place" defaults="Place" />,
  placeProperties: (
    <Trans i18nKey="table.head.placeProperties" defaults="Place properties" />
  ),
  previousLegArrivalTime: (
    <Trans
      i18nKey="table.head.previousLegArrivalTime"
      defaults="Previous leg arrival time"
    />
  ),
  previousLegDestination: (
    <Trans
      i18nKey="table.head.previousLegDestination"
      defaults="Previous leg destination"
    />
  ),
  previousLegService: (
    <Trans
      i18nKey="table.head.previousLegService"
      defaults="Previous leg service"
    />
  ),
  previousLegTrip: (
    <Trans i18nKey="table.head.previousLegTrip" defaults="Previous leg trip" />
  ),
  price: <Trans i18nKey="table.head.price" defaults="Price" />,
  priority: <Trans i18nKey="table.head.priority" defaults="Priority" />,
  problemCreatedOn: (
    <Trans
      i18nKey="table.head.problemCreatedOn"
      defaults="Problem created on"
    />
  ),
  problemType: (
    <Trans i18nKey="table.head.problemType" defaults="Problem type" />
  ),
  pushNotification: (
    <Trans i18nKey="table.head.pushNotification" defaults="Push notification" />
  ),
  pushNotificationRecipients: (
    <Trans
      i18nKey="table.head.pushNotificationRecipients"
      defaults="Push notification recipients:"
    />
  ),
  pushNotificationsEnabled: (
    <Trans
      i18nKey="table.head.pushNotificationsEnabled"
      defaults="Push notifications enabled"
    />
  ),
  quantity: <Trans i18nKey="table.head.quantity" defaults="Quantity" />,
  seat: <Trans i18nKey="table.head.seat" defaults="Seat" />,
  secondTicketNumber: (
    <Trans
      i18nKey="table.head.secondTicketNumber"
      defaults="Ticket number (2/2)"
    />
  ),
  sendEvenWhenHigherPriorityExists: (
    <Trans
      i18nKey="table.head.sendEvenWhenHigherPriorityExists"
      defaults="Use even if higher priority channel is used"
    />
  ),
  service: <Trans i18nKey="table.head.service" defaults="Service" />,
  serviceCode: (
    <Trans i18nKey="table.head.serviceCode" defaults="Service code" />
  ),
  shortArrivalTime: (
    <Trans i18nKey="table.head.shortArrivalTime" defaults="Arr." />
  ),
  shortDepartureTime: (
    <Trans i18nKey="table.head.shortDepartureTime" defaults="Dep." />
  ),
  smsRecipients: (
    <Trans i18nKey="table.head.smsRecipients" defaults="SMS recipients:" />
  ),
  status: <Trans i18nKey="table.head.status" defaults="Status" />,
  stop: <Trans i18nKey="table.head.stop" defaults="Stop" />,
  stopsFromTo: (
    <Trans i18nKey="table.head.stopsFromTo" defaults="Stops (from-to)" />
  ),
  streetHouseNumber: (
    <Trans
      i18nKey="table.head.streetHouseNumber"
      defaults="Street, house number"
    />
  ),
  surname: <Trans i18nKey="table.head.surname" defaults="Surname" />,
  ticketNumber: (
    <Trans i18nKey="table.head.ticketNumber" defaults="Ticket number" />
  ),
  ticketStatus: (
    <Trans i18nKey="table.head.ticketStatus" defaults="Ticket status" />
  ),
  ticketTags: <Trans i18nKey="table.head.ticketTags" defaults="Ticket tags" />,
  trip: <Trans i18nKey="table.head.trip" defaults="Trip" />,
  tripDateAndTime: (
    <Trans i18nKey="table.head.tripDateAndTime" defaults="Trip date and time" />
  ),
  tripName: <Trans i18nKey="table.head.tripName" defaults="Trip name" />,
  type: <Trans i18nKey="table.head.type" defaults="Type" />,
  useFallbackLanguage: (
    <Trans
      i18nKey="table.head.useFallbackLanguage"
      defaults="Use fallback language"
    />
  ),
  vehicleCarriage: (
    <Trans i18nKey="table.head.vehicleCarriage" defaults="Vehicle / Carriage" />
  ),
  vehicleProfile: (
    <Trans i18nKey="table.head.vehicleProfile" defaults="Vehicle profile" />
  ),
});
