import { useCallback, useMemo, Dispatch, SetStateAction } from 'react';
import { ModalProps, useFormContext } from '@fleet/shared';
import { copy } from 'helpers/copy';
import { Classifier } from '@fleet/shared/dto/classifier';
import _difference from 'lodash/difference';
import { UseTableOptions } from 'react-table';

export const useModalTextFieldUtils = <T extends { id: string | number }>({
  draftSelection,
  setDraftSelection,
  confirmedSelection,
  name,
  onClose,
}: {
  draftSelection: Map<string, T>;
  setDraftSelection: Dispatch<SetStateAction<Map<string, T>>>;
  confirmedSelection: Map<string, T>;
  name: string;
  onClose: () => void;
}) => {
  const form = useFormContext();
  const selectedRowIds = useMemo(
    () =>
      Array.from(draftSelection.keys()).reduce(
        (acc: Record<string, boolean>, id) => {
          acc[id] = true;
          return acc;
        },
        {}
      ),
    [draftSelection]
  );

  const getRowId = useCallback((row: T) => `${row.id}`, []);

  const handleClose = useCallback<Required<ModalProps>['onClose']>(
    (_, reason) => {
      const draftCopy: Map<string, T> = copy(draftSelection);
      if (reason === 'escapeKeyDown') {
        const confirmedIds =
          form
            .getState()
            .values[name]?.map(
              (formValue: Classifier<number>) => formValue.id + ''
            ) || [];

        const selectedIds = Array.from(draftSelection.keys());
        const rowsToDeselect = _difference(selectedIds, confirmedIds);
        const rowsToReselect = _difference(confirmedIds, selectedIds);

        rowsToDeselect.forEach((id) => {
          draftCopy.delete(id);
        });

        rowsToReselect.forEach((id) => {
          const confirmedRow = confirmedSelection.get(id);
          if (confirmedRow) {
            draftCopy.set(id, confirmedRow);
          }
        });
      }
      setDraftSelection(draftCopy);
      onClose();
    },
    [name, form, onClose, draftSelection, setDraftSelection, confirmedSelection]
  );

  const stateReducer = useCallback<
    Required<UseTableOptions<T>>['stateReducer']
  >(
    (newState, action, previousState, instance) => {
      if (!instance) {
        return newState;
      }

      const draftCopy: Map<string, T> = copy(draftSelection);
      switch (action.type) {
        case 'toggleRowSelected':
          const selectedRow = instance.flatRows.find(
            ({ id }) => id === action.id
          );
          if (selectedRow) {
            const rowSelected = action.value;

            if (rowSelected) {
              draftCopy.set(action.id, selectedRow.original);
            } else {
              draftCopy.delete(action.id);
            }
          }
          setDraftSelection(draftCopy);
          break;
        case 'toggleAllRowsSelected':
          const allRowsSelected = action.value;

          instance.flatRows.forEach(({ original }) => {
            allRowsSelected
              ? draftCopy.set(String(original.id), original)
              : draftCopy.delete(String(original.id));
          });

          setDraftSelection(draftCopy);
          break;
        default:
          break;
      }
      return newState;
    },
    [draftSelection, setDraftSelection]
  );

  return {
    selectedRowIds,
    getRowId,
    handleClose,
    stateReducer,
  };
};
