import { createAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/utils';
import { Step } from 'consts';
import {
  AddresseesFilter,
  MappedNotificationContentFormValues,
  NotificationLanguage,
  NotificationSummary,
  NotificationTemplateSearch,
} from 'dto/notificationContent';
import {
  NotificationAddressee,
  PostAddresseesSearchParams,
  PostAddresseesSearchResponse,
  PostAddresseesSearchByReallocationIdResponse,
  PostReAccommodateStartResponse,
  PostAddresseesSearchByProblematicPassengersResponse,
} from 'dto/api/deviations-management';
import { api } from '@fleet/shared';
import { AddresseeData } from 'routes/SendNotifications/NotificationAddressees';
import qs from 'qs';
import {
  NotificationsTemplate,
  GetNotificationTemplatesResponse,
  NotificationTemplatePreview,
  PostValidateNotificationTemplateBody,
  PostValidateNotificationTemplateResponse,
  PostSendCustomNotificationBody,
  PostSendCustomNotificationResponse,
} from 'dto/api/notifications-management';

export const setActiveStep = createAction<Step>('notifications/setActiveStep');
export const setTrackingId = createAction<
  PostReAccommodateStartResponse['trackingId']
>('notifications/setTrackingId');

export const updateNotificationLanguages = createAction<{
  idx: number;
  checked: boolean;
}>('notifications/updateLanguages');

export const setNotificationFilters = createAction<AddresseesFilter>(
  'notifications/setFilters'
);

export const setNotificationAddressees = createAction<Array<AddresseeData>>(
  'notifications/setAddressees'
);

export const getNotificationTemplatesList = createAsyncThunk<
  GetNotificationTemplatesResponse,
  NotificationTemplateSearch
>('notifications/getTemplatesList', async (filter, { getState }) => {
  const currentBusinessEntityId = getState().common.currentBusinessEntityId;

  return (
    await api.get<GetNotificationTemplatesResponse>(
      `/notification-templates${qs.stringify(
        {
          ...filter,
          organizationId: currentBusinessEntityId,
          typeId: 'NOTIFICATION_TEMPLATE_TYPE.CUSTOM',
        },
        {
          addQueryPrefix: true,
          skipNulls: true,
        }
      )}`,
      {
        baseURL: process.env.REACT_APP_API_NM,
      }
    )
  ).data;
});

export const setNotificationTemplateFilter =
  createAction<NotificationTemplateSearch>('notifications/setTemplateFilter');
export const getNotificationTemplateById = createAsyncThunk<
  NotificationTemplatePreview,
  string
>('notifications/getTemplateById', async (templateId) => {
  return (
    await api.get<NotificationsTemplate>(
      `/notification-templates/${templateId}`,
      {
        baseURL: process.env.REACT_APP_API_NM,
      }
    )
  ).data;
});

export const setNotificationLanguagesData = createAction<
  Array<NotificationLanguage>
>('notifications/setLanguages');

export const getValidatedNotifications = createAsyncThunk<
  PostValidateNotificationTemplateResponse,
  PostValidateNotificationTemplateBody
>('notifications/getValidatedNotifications', async (payload) => {
  return (
    await api.post<PostValidateNotificationTemplateResponse>(
      '/notification-templates/validate',
      payload,
      {
        baseURL: process.env.REACT_APP_API_NM,
      }
    )
  ).data;
});

export const setMappedNotificationContentFormValues =
  createAction<MappedNotificationContentFormValues>(
    'notifications/setContentFormValues'
  );

export const resetNotificationStore = createAction('notifications/resetStore');

export const setNotificationSummaryData = createAction<
  Omit<NotificationSummary, 'trackingId'>
>('notifications/setSummaryData');

export const getPotentialAddressees = createAsyncThunk<
  Array<NotificationAddressee>,
  PostAddresseesSearchParams
>('notifications/getPotentialAddressees', async (payload) => {
  return (
    await api.post<PostAddresseesSearchResponse>(
      '/notifications/addressees/search',
      { ...payload, limit: 9999 }
    )
  ).data.items;
});

export const sendNotifications = createAsyncThunk<
  PostSendCustomNotificationResponse,
  PostSendCustomNotificationBody
>('notifications/sendNotifications', async (payload) => {
  return (
    await api.post<PostSendCustomNotificationResponse>(
      '/notifications/send',
      payload,
      {
        baseURL: process.env.REACT_APP_API_NM,
      }
    )
  ).data;
});

const transformAddresseesResponse = (
  addressees:
    | PostAddresseesSearchByReallocationIdResponse
    | PostAddresseesSearchByProblematicPassengersResponse
) =>
  addressees.items.map(
    ({ addressee, trip, bookingId, legId, passengerId }) => ({
      passengerId,
      email: addressee.email,
      language: addressee.culture.name,
      tripName: trip.name,
      originStopZone: trip.originStopName,
      destinationStopZone: trip.destinationStopName,
      firstName: addressee.firstName,
      lastName: addressee.lastName,
      languageId: addressee.culture.id,
      bookingId,
      legId,
      phoneNumber: addressee.phoneNumber,
      phoneAreaCode: addressee.phoneAreaCode,
      customerProfileId: addressee.customerProfileId,
    })
  );

export const getAddresseesByReallocationId = createAsyncThunk<
  Array<AddresseeData>,
  { carrierId: string; processId: string }
>(
  'notifications/getAddresseesByReallocationId',
  async ({ carrierId, processId }) => {
    const addressees = (
      await api.post<PostAddresseesSearchByReallocationIdResponse>(
        `/notifications/addressees/search/by-reallocation/${processId}`,
        {
          carrierId,
          limit: 9999,
          offset: 0,
        }
      )
    ).data;

    return transformAddresseesResponse(addressees);
  }
);

export const getAddresseesByProblematicPassengers = createAsyncThunk<
  Array<AddresseeData>,
  { carrierId: string; problematicPassengerIds: Array<number> }
>(
  'notifications/getAddresseesByProblematicPassengers',
  async ({ carrierId, problematicPassengerIds }) => {
    const addressees = (
      await api.post<PostAddresseesSearchByProblematicPassengersResponse>(
        '/notifications/addressees/search/by-problematic-passengers',
        {
          carrierId,
          problematicPassengerIds,
          limit: 9999,
          offset: 0,
        }
      )
    ).data;

    return transformAddresseesResponse(addressees);
  }
);
