import {
  makeUseClassificationOptions,
  makeUseClassificationMap,
} from '@fleet/shared/hooks/useClassification';
import { RootState } from 'store';

export const useClassificationOptions =
  makeUseClassificationOptions<RootState>();

export const useClassificationMap = makeUseClassificationMap<RootState>();
