import { FC, useEffect, useMemo } from 'react';
import {
  Button,
  FormProvider,
  Icon,
  Layout,
  useForm,
  useModal,
} from '@fleet/shared';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { Templates } from 'routes/SendNotifications/content/sections/templates/Templates';
import { NotificationChannels } from './sections/NotificationChannels';
import { NotificationLanguages } from './sections/NotificationLanguages';
import { useDispatch, useSelector } from 'store/utils';
import {
  getValidatedNotifications,
  resetNotificationStore,
  sendNotifications,
  setActiveStep,
  setMappedNotificationContentFormValues,
  setNotificationLanguagesData,
  setTrackingId,
} from 'features/notifications/notificationsActions';
import { TransButton } from 'i18n/trans/button';
import {
  currentTemplateSelector,
  notificationAddresseesSelector,
  notificationContentFormValuesSelector,
  notificationLanguagesSelector,
} from 'features/notifications/notificationsSelectors';
import { NotificationContentFormValues } from 'dto/notificationContent';
import { useClassificationMap } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { Mediums } from 'routes/SendNotifications/content/sections/Mediums';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { ValidationModal } from 'routes/SendNotifications/content/validation/ValidationModal';
import { Step } from 'consts';
import {
  PostSendCustomNotificationBody,
  PostValidateNotificationTemplateBody,
} from 'dto/api/notifications-management';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import {
  createNotificationLanguagesData,
  getRecipientsCountByLanguageId,
} from 'helpers/notificationContent';
import { useMergeLanguagesWithTemplate } from 'hooks/useMergeLanguagesWithTemplate';
import { noop } from 'lodash';
import { useAlert } from 'react-alert';

export const NotificationContent: FC = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const addressees = useSelector(notificationAddresseesSelector);
  const addresseeLanguages = useSelector(notificationLanguagesSelector);
  const currentTemplate = useSelector(currentTemplateSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const formValues = useSelector(notificationContentFormValuesSelector);
  const languagesMap = useClassificationMap(ClassificationGroup.CULTURE);
  const { open: isOpen, onOpen, onClose } = useModal();

  const onSubmit = async (values: NotificationContentFormValues) => {
    const payload: PostValidateNotificationTemplateBody = {
      typeId: currentTemplate?.type?.id || 'NOTIFICATION_TEMPLATE_TYPE.CUSTOM',
      notificationsByLanguage: values.notificationsByLanguage.map(
        (notification) => ({
          ...notification,
          languageId: notification.language.id,
        })
      ),
    };

    dispatch(
      setMappedNotificationContentFormValues({
        notificationsByLanguage: payload.notificationsByLanguage,
        fallbackLanguageId: values.fallbackLanguageId,
        sendNotificationTo: values.sendNotificationTo,
      })
    );

    await dispatch(getValidatedNotifications(payload))
      .unwrap()
      .then(() => onOpen())
      .catch(noop);
  };

  const notificationsByLanguage = useMergeLanguagesWithTemplate();
  const initialValues = useMemo(
    () => ({
      notificationsByLanguage,
    }),
    [notificationsByLanguage]
  );

  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues,
    subscription: {
      values: true,
    },
  });

  const onValidationModalSubmit = async () => {
    if (!formValues) {
      return;
    }

    const payload: PostSendCustomNotificationBody = {
      organizationId: currentBusinessEntityId,
      notificationTemplateId: currentTemplate?.id,
      contents: formValues.notificationsByLanguage?.map((notification) => ({
        ...notification,
        isDefault: Boolean(
          notification.languageId === formValues.fallbackLanguageId
        ),
      })),
      customers: addressees,
      includeTickets: formValues.sendNotificationTo === 'all',
    };

    const { trackingId, warnings } = await dispatch(
      sendNotifications(payload)
    ).unwrap();

    if (trackingId) {
      onClose();
      dispatch(setTrackingId(trackingId));
      dispatch(setActiveStep(Step.Summary));
    }

    if (warnings?.warnings) {
      alert.show(warnings.warnings[0].message, { type: 'info', timeout: 5000 });
    }
  };

  useEffect(() => {
    dispatch(
      setNotificationLanguagesData(
        createNotificationLanguagesData(
          getRecipientsCountByLanguageId(addressees),
          languagesMap
        )
      )
    );
  }, [dispatch, addressees, languagesMap]);

  return (
    <>
      <Layout
        height="fit"
        sx={{ p: '24px 0px 0px 24px !important', minHeight: 0 }}
        data-testid="notification-content-layout"
        elevation={0}
      >
        <>
          <Templates />
          <Divider />

          <FormProvider form={form}>
            <form onSubmit={handleSubmit}>
              <NotificationChannels />
              <Divider />
              <NotificationLanguages />
              <Divider />
              <Mediums />
            </form>
          </FormProvider>
        </>
      </Layout>
      <Stack
        direction="row"
        sx={{
          py: 2,
          px: 3,
          boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.2)',
          zIndex: 1,
        }}
      >
        <Stack direction="row" sx={{ my: 'auto' }}>
          <Typography fontWeight={400}>
            <TransSubtitle
              i18nKey="selectedAddressees"
              values={{ count: addressees.length }}
            />
            {', '}
            <TransSubtitle
              i18nKey="selectedLanguages"
              values={{ count: addresseeLanguages?.length ?? 0 }}
            />
          </Typography>
          <Icon name="info-circle" sx={{ ml: 1, my: 'auto' }} />
        </Stack>
        <Box sx={{ flex: 1 }}></Box>
        <Stack direction="row">
          <Button
            variant="text"
            onClick={() => {
              dispatch(setActiveStep(Step.Addressees));
            }}
          >
            <TransButton i18nKey="back" />
          </Button>
          <Button
            onClick={() => {
              dispatch(resetNotificationStore());
              dispatch(setActiveStep(Step.Filters));
            }}
            variant="text"
          >
            <TransButton i18nKey="cancel" />
          </Button>
          <Button
            onClick={() => {
              form.reset();
            }}
            variant="text"
          >
            <TransButton i18nKey="reset" />
          </Button>
          <Button
            icon="search"
            onClick={() => {
              handleSubmit();
            }}
            sx={{ ml: 2 }}
            data-testid="notification-content-submit"
          >
            <TransButton i18nKey="validate" />
          </Button>
        </Stack>
      </Stack>
      <ValidationModal
        isOpen={isOpen}
        onSubmit={onValidationModalSubmit}
        onClose={onClose}
      />
    </>
  );
};
