import { Layout, Loadable } from '@fleet/shared';
import { problematicPassengersLoadingSelector } from 'features/loading/loadingSelectors';
import { FC } from 'react';
import { ProblematicPassengersTable } from 'routes/ProblematicPassengers/ProblematicPassengersTable';
import { useSelector } from 'store/utils';

interface ProblematicPassengersProps {}

export const ProblematicPassengers: FC<ProblematicPassengersProps> = () => {
  const loading = useSelector(problematicPassengersLoadingSelector);

  return (
    <Loadable loading={loading}>
      <Layout>
        <ProblematicPassengersTable />
      </Layout>
    </Loadable>
  );
};
