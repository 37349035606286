import type { FC } from 'react';
import { Route, Switch } from 'react-router';
import { PassengersAllocationList } from 'routes/PassengersAllocation/PassengersAllocationList';
import { ReAccommodate } from 'routes/PassengersAllocation/ReAccommodate/ReAccommodate';

interface PassengersAllocationProps {}

export const PassengersAllocation: FC<PassengersAllocationProps> = () => {
  return (
    <Switch>
      <Route
        path="/passengers-allocation"
        component={PassengersAllocationList}
        exact
      />
      <Route
        path="/passengers-allocation/re-accommodate"
        component={ReAccommodate}
        exact
      />
    </Switch>
  );
};
