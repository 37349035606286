import { formSubmit, useForm } from '@fleet/shared';
import {
  clearPassengersAllocationByTrips,
  getPassengersAllocationByTrips,
  setPassengersAllocationFilterByTrips,
} from 'features/passengersAllocation/passengersAllocationActions';
import {
  passengersAllocationFilterByTripSelector,
  passengersAllocationSelectorByTripSelector,
} from 'features/passengersAllocation/passengersAllocationSelectors';
import { pick } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import {
  ActionCreatorWithPayload,
  AsyncThunk,
  Selector,
} from '@reduxjs/toolkit';
import { RootState } from 'store';
import { AdmissionSearchGroup } from 'dto/passengersAllocation';

export const passengersSearchCallbacks = {
  [AdmissionSearchGroup.TRIPS]: {
    passengersFilterSelector: passengersAllocationFilterByTripSelector,
    passengersSelector: passengersAllocationSelectorByTripSelector,
    setPassengersFilter: setPassengersAllocationFilterByTrips,
    clearPassengers: clearPassengersAllocationByTrips,
    getPassengers: getPassengersAllocationByTrips,
  },
};

interface UsePassengersSearchFormProps<
  Filter extends object,
  Response extends object
> {
  defaultValues: Partial<Filter>;
  passengersFilterSelector: Selector<RootState, Partial<Filter>>;
  setPassengersFilter: ActionCreatorWithPayload<Partial<Filter>>;
  getPassengers: AsyncThunk<Response, Partial<Filter>, {}>;
}

export const usePassengersSearchForm = <
  Filter extends object,
  Response extends object
>({
  defaultValues,
  passengersFilterSelector,
  setPassengersFilter,
  getPassengers,
}: UsePassengersSearchFormProps<Filter, Response>) => {
  const dispatch = useDispatch();
  const filter = useSelector(passengersFilterSelector);

  const initialValues = useMemo<Partial<Filter>>(
    () => ({ ...defaultValues, ...filter }),
    [defaultValues, filter]
  );

  const onSubmit = useCallback(
    async (filter: Partial<Filter>) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(getPassengers({ ...filter, offset: 0 }));
      }),
    [dispatch, getPassengers]
  );

  const { form, handleSubmit, values } = useForm({
    initialValues,
    onSubmit,
    subscription: { values: true },
  });

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(
      setPassengersFilter({
        ...defaultValues,
        ...pick(filter, ['limit', 'offset']),
      })
    );
  }, [form, dispatch, setPassengersFilter, defaultValues, filter]);

  return { form, values, handleSubmit, handleReset };
};
