import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import {
  Checkbox,
  Table,
  TableColumns,
  TableDragDropProps,
  useDraggable,
  useFormContext,
} from '@fleet/shared';
import { TransTableHead } from 'i18n/trans/table';
import { useTable } from 'react-table';
import { currentTemplateSelector } from 'features/notifications/notificationsSelectors';
import { useSelector } from 'store/utils';
import { NotificationChannel } from 'dto/api/notifications-management';
import { ClassificationGroup } from 'dto/classification';
import { classificationsSelector } from 'features/classification/classificationSelectors';

interface NotificationChannelsProps {}
export const NotificationChannels: FC<NotificationChannelsProps> = () => {
  const currentTemplate = useSelector(currentTemplateSelector);
  const [data, setData] = useState<NotificationChannel[]>([]);
  const form = useFormContext();
  const classifications = useSelector(classificationsSelector);
  const channelsOptions =
    classifications[ClassificationGroup.NOTIFICATION_CHANNEL];

  useEffect(() => {
    if (currentTemplate && currentTemplate.notificationChannels) {
      // remove filter once Push is supported
      setData(
        currentTemplate.notificationChannels.filter(
          (notificationChannel) =>
            notificationChannel.channel?.id !==
            'NOTIFICATION_CHANNEL.PUSH_NOTIFICATION'
        )
      );
    } else {
      setData(
        channelsOptions
          .map((option) => ({
            channel: option,
            sendEvenWhenHigherPriorityExists: false,
            isActive: option.id === 'NOTIFICATION_CHANNEL.EMAIL',
            orderNumber: getOrderNumber(option.id),
          }))
          .filter(
            (notificationChannel) =>
              notificationChannel.channel.id !==
              'NOTIFICATION_CHANNEL.PUSH_NOTIFICATION'
          )
      );
    }
  }, [currentTemplate, channelsOptions]);

  const handleCheckboxChange = useCallback(
    (rowIndex: number, fieldName: string, newValue: boolean) => {
      const newData = data.map((item, index) => {
        if (index === rowIndex) {
          return { ...item, [fieldName]: newValue };
        }
        return item;
      });
      setData(newData);

      form.change('notificationChannels', newData);
    },
    [data, form]
  );

  const columns = useMemo<TableColumns<NotificationChannel>>(
    () => [
      {
        id: 'isActive',
        accessor: 'isActive',
        Header: <TransTableHead i18nKey="isActive" />,
        Cell: ({ row: { original, index }, column: { id } }) => (
          <Checkbox
            size="small"
            checked={original.isActive}
            onChange={(e) => handleCheckboxChange(index, id, e.target.checked)}
          />
        ),
        width: 'auto',
      },
      {
        accessor: 'orderNumber',
        Header: <TransTableHead i18nKey="priority" />,
        width: 'auto',
      },
      {
        id: 'channel.id',
        accessor: ({ channel }) => channel?.name,
        Header: <TransTableHead i18nKey="medium" />,
        width: 'auto',
      },
      {
        id: 'sendEvenWhenHigherPriorityExists',
        accessor: 'sendEvenWhenHigherPriorityExists',
        Header: <TransTableHead i18nKey="sendEvenWhenHigherPriorityExists" />,
        Cell: ({ row: { index, original }, column: { id } }) =>
          index !== 0 ? (
            <Checkbox
              size="small"
              checked={original.sendEvenWhenHigherPriorityExists}
              onChange={(e) =>
                handleCheckboxChange(index, id, e.target.checked)
              }
            />
          ) : (
            <></>
          ),
        width: 'auto',
      },
    ],
    [handleCheckboxChange]
  );

  const handleDragEnd = useCallback<
    TableDragDropProps<NotificationChannel>['onDragEnd']
  >(
    ({ data }) => {
      const updatedData: NotificationChannel[] = data.map((item, index) => {
        return { ...item, orderNumber: index + 1 };
      });

      setData(updatedData);

      form.change('notificationChannels', updatedData);
    },
    [form]
  );

  const table = useTable({ columns, data }, useDraggable);
  return (
    <Stack sx={{ mb: 2, px: 2 }}>
      <Typography variant="subtitle" fontWeight="700" sx={{ my: 2 }}>
        <TransSubtitle i18nKey="notificationChannels" />
      </Typography>
      <Table table={table} draggable onDragEnd={handleDragEnd} />
    </Stack>
  );
};

const getOrderNumber = (channelId: string) => {
  switch (channelId) {
    case 'NOTIFICATION_CHANNEL.EMAIL':
      return 1;
    case 'NOTIFICATION_CHANNEL.SMS':
      return 2;
    case 'NOTIFICATION_CHANNEL.PUSH':
      return 3;
    default:
      return -1;
  }
};
