import {
  NotificationChannel,
  NotificationsTemplate,
  ValidatedNotificationByLanguage,
  PostValidateNotificationTemplateBody,
  NotificationByLanguage,
  GetNotificationTemplatesResponse,
} from 'dto/api/notifications-management';
import { ArrayElement } from '@fleet/shared/@types/openapi-typescript';
import { Classifier } from '@fleet/shared/dto/classifier';
import {
  PostAddresseesSearchParams,
  PostReAccommodateStartResponse,
} from 'dto/api/deviations-management';
import { StopsSelectValue } from '@fleet/shared';
import { PaginationParams } from '@fleet/shared/dto/pagination';

export interface NotificationTemplateSearch extends PaginationParams {
  name?: string;
  language?: string;
}
export interface NotificationLanguage {
  language: string;
  languageId: string;
  emailRecipients: number;
  smsRecipients: number;
  pushRecipients: number;
  useFallbackLanguage: boolean;
}
export interface NotificationContentLanguages {
  list?: NotificationLanguage[];
  fallbackLanguageId?: string;
}

export interface NotificationContent {
  templates: {
    filter?: {
      name?: string;
      languages?: string[];
    };
    list?: GetNotificationTemplatesResponse;
    current?: NotificationsTemplate | null;
  };
  notificationChannels: {
    list: Array<NotificationChannel>;
  };
  notificationLanguages: NotificationContentLanguages;
  validation: {
    list: Array<ValidatedNotificationByLanguage>;
    formValues?: MappedNotificationContentFormValues;
  };
}

export type RecipientsCountByLanguageId = Record<
  string,
  { emailRecipients: number; smsRecipients: number; pushRecipients: number }
>;

export interface NotificationContentFormValues {
  notificationsByLanguage: Array<
    Omit<
      ArrayElement<
        Omit<
          PostValidateNotificationTemplateBody,
          'typeId'
        >['notificationsByLanguage']
      >,
      'languageId'
    > & { language: Classifier }
  >;
  sendNotificationTo?: 'unique' | 'all';
  fallbackLanguageId?: string;
}

export interface MappedNotificationContentFormValues {
  notificationsByLanguage: Omit<
    PostValidateNotificationTemplateBody,
    'typeId'
  >['notificationsByLanguage'];
  fallbackLanguageId?: string;
  sendNotificationTo?: 'all' | 'unique';
}

export interface NotificationSummary {
  totalEmailRecipients: number;
  totalSmsRecipients: number;
  totalPushRecipients: number;
  trackingId: PostReAccommodateStartResponse['trackingId'] | null;
}

export interface TabData {
  language: Classifier;
  useFallbackLanguage: boolean;
}

export enum Medium {
  email = 'email',
  sms = 'sms',
  push = 'push',
}

export interface EnrichedNotificationByLanguage extends NotificationByLanguage {
  language: Classifier;
  useFallbackLanguage: boolean;
}

export type AddresseesFilter = Omit<
  PostAddresseesSearchParams,
  'fromStopIds' | 'toStopIds'
> & {
  fromStops: StopsSelectValue;
  toStops: StopsSelectValue;
};
