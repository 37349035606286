import React, { FC, useCallback, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import {
  Checkbox,
  SelectField,
  Table,
  TableColumns,
  useFormContext,
} from '@fleet/shared';
import { useTable } from 'react-table';
import { TransTableHead } from 'i18n/trans/table';
import { TransLabel } from 'i18n/trans/label';
import { useDispatch, useSelector } from 'store/utils';
import {
  currentTemplateSelector,
  notificationLanguagesSelector,
} from 'features/notifications/notificationsSelectors';
import { NotificationLanguage } from 'dto/notificationContent';
import { updateNotificationLanguages } from 'features/notifications/notificationsActions';
interface NotificationLanguagesProps {}

export const NotificationLanguages: FC<NotificationLanguagesProps> = () => {
  const dispatch = useDispatch();
  const currentTemplate = useSelector(currentTemplateSelector);
  const [fallbackLanguage, setFallbackLanguage] = useState(
    currentTemplate?.fallbackLanguage?.id || ''
  );

  const notificationLanguages = useSelector(notificationLanguagesSelector);
  const fallbackLanguageOptions = useMemo(
    () =>
      notificationLanguages?.map((data) => ({
        value: data.languageId,
        label: data.language,
      })) || [],
    [notificationLanguages]
  );

  const form = useFormContext();

  const handleCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
      dispatch(updateNotificationLanguages({ idx, checked: e.target.checked }));

      form.change(
        `notificationLanguages[${idx}].useFallbackLanguage`,
        e.target.checked
      );
    },
    [dispatch, form]
  );

  const data = useSelector(notificationLanguagesSelector) || [];
  const columns = useMemo<TableColumns<NotificationLanguage>>(
    () => [
      {
        accessor: 'language',
        Header: <TransTableHead i18nKey="language" />,
      },
      {
        accessor: 'emailRecipients',
        Header: <TransTableHead i18nKey="emailRecipients" />,
      },
      {
        accessor: 'smsRecipients',
        Header: <TransTableHead i18nKey="smsRecipients" />,
      },
      {
        accessor: 'pushRecipients',
        Header: <TransTableHead i18nKey="pushNotificationRecipients" />,
      },
      {
        accessor: 'useFallbackLanguage',
        Header: <TransTableHead i18nKey="useFallbackLanguage" />,
        Cell: ({ row }) => {
          const { languageId, useFallbackLanguage } = row.original;
          if (languageId === fallbackLanguage || !fallbackLanguage) {
            return <Checkbox disabled={true} />;
          }
          return (
            <Checkbox
              checked={useFallbackLanguage}
              onChange={(e) => handleCheckboxChange(e, row.index)}
            />
          );
        },
      },
    ],
    [fallbackLanguage, handleCheckboxChange]
  );
  const table = useTable({ data, columns });

  return (
    <Box sx={{ p: 2 }}>
      <Table
        caption={
          <>
            <Typography variant="subtitle" fontWeight="700">
              <TransSubtitle i18nKey="notificationLanguages" />
            </Typography>
            <Box sx={{ width: '353px', my: 2 }}>
              <SelectField
                name="fallbackLanguageId"
                label={
                  <Typography variant="body2" fontWeight={200}>
                    <TransLabel i18nKey="fallbackLanguage" />
                    &#x2A;
                  </Typography>
                }
                options={fallbackLanguageOptions}
                value={fallbackLanguage}
                onChange={(value) => {
                  form.change(`fallbackLanguageId`, value);
                  setFallbackLanguage(value);
                }}
                showEmptyOption
              />
            </Box>
          </>
        }
        table={table}
      />
    </Box>
  );
};
