import { createReducer } from '@reduxjs/toolkit';
import {
  GetReAccommodateDetailedTripResponse,
  Admission,
  PostReAccommodateStartResponse,
} from 'dto/api/deviations-management';
import {
  PostReAccommodateTripsResponse,
  ReAccommodateTrip,
} from 'dto/api/line-management';
import {
  ReAccommodateTripsFilter,
  PassengerReAccommodateStep,
} from 'dto/reAccommodate';
import {
  getReAccommodateNewDetailedTrip,
  getReAccommodateNewTripsList,
  setReAccommodateActiveStep,
  setReAccommodatePassengersList,
  setReAccommodateNewTripsFilter,
  resetAccommodate,
  startReAccommodateCurrentProcess,
} from 'features/reAccommodate/reAccommodateActions';
import { keyBy } from 'lodash';

export interface CommonState {
  passengersList: Array<Admission>;
  activeStep: PassengerReAccommodateStep;
  newTripsFilter: Partial<ReAccommodateTripsFilter>;
  newTripsList?: PostReAccommodateTripsResponse | null;
  newDetailedTrips: Record<
    ReAccommodateTrip['id'],
    GetReAccommodateDetailedTripResponse
  >;
  newTrip?: GetReAccommodateDetailedTripResponse | null;
  mismatchesDictionary: Record<Admission['admissionNumber'], Admission>;
  trackingId?: PostReAccommodateStartResponse['trackingId'] | null;
}

const initialState: CommonState = {
  activeStep: 'selectTrip',
  passengersList: [],
  newDetailedTrips: {},
  newTripsFilter: {},
  mismatchesDictionary: {},
};

export const reAccommodateReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(resetAccommodate, (state) => {
      state.passengersList = [];
      state.activeStep = 'selectTrip';
      state.newTripsFilter = {};
      state.newTripsList = null;
      state.newDetailedTrips = {};
      state.newTrip = null;
      state.mismatchesDictionary = {};
      state.trackingId = null;
    })
    .addCase(setReAccommodatePassengersList, (state, action) => {
      state.passengersList = action.payload;
    })
    .addCase(setReAccommodateActiveStep, (state, action) => {
      state.activeStep = action.payload;
    })
    .addCase(setReAccommodateNewTripsFilter, (state, action) => {
      state.newTripsFilter = action.payload;
    })
    .addCase(getReAccommodateNewTripsList.fulfilled, (state, action) => {
      state.newTripsList = action.payload;
    })
    .addCase(getReAccommodateNewDetailedTrip.fulfilled, (state, action) => {
      const { tripId } = action.payload;
      state.newDetailedTrips[tripId] = action.payload;
    })
    .addCase(startReAccommodateCurrentProcess.fulfilled, (state, action) => {
      const {
        newTrip,
        mismatchedAdmissions: { mismatches },
        trackingId,
      } = action.payload;
      const passengersObject = keyBy(state.passengersList, 'admissionNumber');
      const mismatchesDictionary = (mismatches?.routes ?? []).reduce(
        (acc, route) => {
          if (passengersObject[route]) {
            acc[route] = passengersObject[route];
          }

          return acc;
        },
        {} as Record<Admission['admissionNumber'], Admission>
      );

      state.newTrip = newTrip;
      state.mismatchesDictionary = mismatchesDictionary;
      state.trackingId = trackingId;
    });
});
