import type { FC } from 'react';
import { Layout, Loadable } from '@fleet/shared';
import { PassengersAllocationTable } from 'routes/PassengersAllocation/PassengersAllocationTable';
import { useSelector } from 'store/utils';
import { passengersAllocationLoadingSelector } from 'features/loading/loadingSelectors';

interface PassengersAllocationListProps {}

export const PassengersAllocationList: FC<PassengersAllocationListProps> =
  () => {
    const loading = useSelector(passengersAllocationLoadingSelector);

    return (
      <Loadable loading={loading}>
        <Layout>
          <PassengersAllocationTable />
        </Layout>
      </Loadable>
    );
  };
