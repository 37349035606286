import React, { FC } from 'react';
import { Grid, Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import {
  Button,
  FormProvider,
  PeriodField,
  SelectField,
  TextField,
  TimeUnit,
  Icon,
  useForm,
  useBusinessEntitiesByType,
  makeClassificationOptions,
} from '@fleet/shared';
import { FetchTripsSearch } from 'dto/api/line-management';
import { useServiceOptions } from 'hooks/useServiceOptions';
import { useCarrierInitValue } from 'hooks/useCarrierInitValue';
import { useSelector } from 'store/utils';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';

interface Props {
  carrierId?: number;
  onSubmit: (filter: Partial<FetchTripsSearch>) => Promise<void>;
}

export const TripSearchForm: FC<Props> = ({ carrierId, onSubmit }) => {
  const businessEntities = useSelector(businessEntitiesSelector);
  const { CARRIER } = useBusinessEntitiesByType(businessEntities);
  const carrierOptions = makeClassificationOptions(CARRIER);
  const currentBusinessEntityId = useCarrierInitValue(carrierOptions);
  const serviceOptions = useServiceOptions(
    carrierId ?? currentBusinessEntityId
  );

  const { form, handleSubmit, submitting } = useForm<FetchTripsSearch>({
    onSubmit,
    subscription: { dirty: true, submitting: true },
  });

  const handleReset = () => {
    form.reset();
  };

  return (
    <FormProvider form={form}>
      <form onSubmit={handleSubmit}>
        <Grid container columns={3} spacing={2} alignItems="flex-end">
          <Grid item xs={1}>
            <SelectField
              name="serviceCodes"
              label={<TransField i18nKey="serviceCode" />}
              options={serviceOptions}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField name="name" label={<TransField i18nKey="name" />} />
          </Grid>
          <Grid item xs={1} />
          <PeriodField
            from={{
              name: 'tripDateFrom',
              label: <TransField i18nKey="departureFrom" />,
              required: true,
            }}
            to={{
              name: 'tripDateTo',
              label: <TransField i18nKey="departureTo" />,
              required: true,
            }}
            maxRange={{ [TimeUnit.DAYS]: 7 }}
          />
          <Grid item xs="auto" sx={{ ml: 'auto' }} alignItems="flex-end">
            <Stack direction="row" spacing={2}>
              <Button
                sx={{ whiteSpace: 'nowrap' }}
                variant="text"
                onClick={handleReset}
              >
                <TransButton i18nKey="resetFilters" />
              </Button>

              <Button
                variant="contained"
                type="submit"
                startIcon={<Icon name="search" size={20} />}
                disabled={submitting}
              >
                <TransButton i18nKey="search" />
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
