import { createTrans } from 'i18n/trans';
import { Trans } from 'react-i18next';

export const TransAlert = createTrans({
  departureTimesOrTripsFilled: (
    <Trans
      i18nKey="alert.departureTimesOrTripsFilled"
      defaults="Either departure times or trips need to be filled"
    />
  ),
  fillAtLeastOne: (
    <Trans
      i18nKey="alert.fillAtLeastOne"
      defaults="At least one filter must be filled"
    />
  ),
  fillFromTemplateFail: (
    <Trans
      i18nKey="alert.fillFromTemplateFail"
      defaults="Failed to use values from template."
    />
  ),
  fillFromTemplateSuccess: (
    <Trans
      i18nKey="alert.fillFromTemplateSuccess"
      defaults="Values have been filled from chosen template."
    />
  ),
  noPassengersFound: (
    <Trans
      i18nKey="alert.noPassengersFound"
      defaults="Could not find any passengers with the given input."
    />
  ),
  problematicPassengersSolved: (
    <Trans
      i18nKey="alert.problematicPassengersSolved"
      defaults="(0-1)[Problematic passenger is solved];(2-inf)[Problematic passengers are solved]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
});
