import { Admission } from 'dto/api/deviations-management';
import {
  NEW_DESTINATION_COLUMN_NAME,
  NEW_ORIGIN_COLUMN_NAME,
} from 'hooks/passengersAllocation/usePassengersAllocationColumns';

interface OptionDisabledProps {
  isNewOrigin: boolean;
  index: number;
  outerIndex: number;
  length: number;
}

export const isOptionDisabled = ({
  isNewOrigin,
  index,
  outerIndex,
  length,
}: OptionDisabledProps) => {
  if (isNewOrigin) {
    return index >= length - 1 || (outerIndex !== -1 && index >= outerIndex);
  }

  return index <= 0 || (outerIndex !== -1 && index <= outerIndex);
};

export const getOuterNewStopFieldName = (
  admissionNumber: Admission['admissionNumber'],
  isNewOrigin: boolean
) =>
  `${admissionNumber}_${
    isNewOrigin ? NEW_DESTINATION_COLUMN_NAME : NEW_ORIGIN_COLUMN_NAME
  }`;
