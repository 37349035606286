import {
  Button,
  Loadable,
  Modal,
  SearchModalProps,
  Table,
  TableColumns,
  currentDateTimeFormat,
  formatDate,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import { FC, useCallback, useMemo, useState } from 'react';
import { useRowSelect, useTable } from 'react-table';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { TransTableHead } from 'i18n/trans/table';
import { TransTitle } from 'i18n/trans/title';
import { useSelector } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import type { Classifier } from '@fleet/shared/dto/classifier';
import { makeStyles } from '@mui/styles';
import { FetchTripsSearch, GetTripsItem } from 'dto/api/line-management';
import { TripSearchForm } from 'components/TripSelectionField/TripSearchForm';
import { ModalTableCaption } from 'routes/SendNotifications/filters/Modals/ModalTableCaption';
import { Stack } from '@mui/material';
import { useModalTextFieldUtils } from 'hooks/useModalTextFieldUtils';
import { fetchTrips } from 'features/common/commonService';

interface TripsSelectionModalProps
  extends SearchModalProps<Array<Classifier<number>>> {}

export interface TripsRow {
  id: number;
  name: string | null;
  departureDateTime: string;
  serviceCode: string | null;
  hasAdmissions: boolean;
  isActive: boolean;
}

const useStyles = makeStyles(() => ({
  paper: {
    boxShadow: 'none !important',
    display: 'flex',
    flexDirection: 'column',
    height: '-webkit-fill-available',
  },
  content: {
    display: 'flex',
  },
}));

export const TripsModal: FC<TripsSelectionModalProps> = ({
  isOpen,
  name,
  onSubmit,
  onClose,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TripsRow[]>([]);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const [confirmedSelection, setConfirmedSelection] = useState(
    new Map<string, TripsRow>()
  );
  const [draftSelection, setDraftSelection] = useState(
    new Map<string, TripsRow>()
  );
  const [view, setView] = useState<'selected' | 'search'>('search');
  const { selectedRowIds, getRowId, handleClose, stateReducer } =
    useModalTextFieldUtils<TripsRow>({
      draftSelection,
      setDraftSelection,
      confirmedSelection,
      name,
      onClose,
    });

  const columns = useMemo<TableColumns<TripsRow>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="tripName" />,
      },
      {
        id: 'departureDateTime',
        accessor: ({ departureDateTime }) =>
          formatDate(departureDateTime, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="departureDateTime" />,
      },
      {
        accessor: 'serviceCode',
        Header: <TransTableHead i18nKey="serviceCode" />,
      },
      {
        id: 'hasAdmissions',
        accessor: 'hasAdmissions',
        Header: <TransTableHead i18nKey="hasTickets" />,
        Cell: ({ row: { original } }) => (
          <Icon
            name={original.hasAdmissions ? 'check' : 'close'}
            color={original.hasAdmissions ? 'success' : 'error'}
          />
        ),
      },
      {
        id: 'isActive',
        accessor: 'isActive',
        Header: <TransTableHead i18nKey="isActive" />,
        Cell: ({ row: { original } }) => (
          <Icon
            name={original.isActive ? 'check' : 'close'}
            color={original.isActive ? 'success' : 'error'}
          />
        ),
      },
    ],
    []
  );

  const table = useTable<TripsRow>(
    {
      data,
      columns,
      getRowId,
      useControlledState: (state) => ({
        ...state,
        selectedRowIds,
      }),
      stateReducer,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const selectedTripsTable = useTable<TripsRow>(
    {
      data: useMemo(
        () => Array.from(confirmedSelection.values()),
        [confirmedSelection]
      ),
      columns,
      getRowId,
      useControlledState: (state) => ({
        ...state,
        selectedRowIds,
      }),
      stateReducer,
    },
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const handleSearchSubmit = useCallback(
    async (filter: FetchTripsSearch) => {
      setIsLoading(true);
      const [fromDateFormat] = filter.tripDateFrom?.split('T') || [];
      const [toDateFormat] = filter.tripDateTo?.split('T') || [];

      const _filter: FetchTripsSearch = {
        ...filter,
        ...(fromDateFormat ? { tripDateFrom: fromDateFormat } : {}),
        ...(toDateFormat ? { tripDateTo: toDateFormat } : {}),
        limit: 9999,
      };

      const values = await fetchTrips(_filter, currentBusinessEntityId);
      const mappedValues: TripsRow[] = values.items.map(
        (trip: GetTripsItem) => ({
          id: trip.id,
          name: trip.name ?? null,
          departureDateTime: trip.departureDateTime,
          serviceCode: trip.serviceCode ?? null,
          hasAdmissions: trip.hasAdmissions,
          isActive: trip.isActive,
        })
      );
      setData(mappedValues);

      setIsLoading(false);
    },
    [currentBusinessEntityId]
  );

  return (
    <Modal
      classes={classes}
      open={isOpen}
      onClose={handleClose}
      title={<TransTitle i18nKey="selectTrips" />}
      actionButton={
        <Button
          variant="contained"
          type="submit"
          onClick={() => {
            onSubmit(
              table.selectedFlatRows.map((row) => ({
                name: row.original.name ?? '',
                id: row.original.id,
              }))
            );
            setConfirmedSelection(draftSelection);
            onClose();
          }}
          startIcon={<Icon name="check" size={20} />}
        >
          <TransButton i18nKey="confirm" />
        </Button>
      }
      maxWidth="md"
      fullWidth
    >
      <Loadable loading={isLoading}>
        <Stack rowGap={4}>
          <TripSearchForm onSubmit={handleSearchSubmit} />
          <ModalTableCaption
            totalCount={data.length}
            view={view}
            setView={setView}
            subtitleKey="tripsQty"
            labelKey="displaySelectedTrips"
          />
          {view === 'search' && <Table table={table} />}
          {view === 'selected' && <Table table={selectedTripsTable} />}
        </Stack>
      </Loadable>
    </Modal>
  );
};
