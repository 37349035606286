import { Period, formatDate, isoDateTimeFormat } from '@fleet/shared';
import { DatePickerValue } from '@fleet/shared/mui/DatePicker';
import { TransError } from 'i18n/trans/error';

const [dateFormat] = isoDateTimeFormat.split("'T'");

export const formatFilterDate = (date: string | null | undefined) =>
  date && formatDate(date, dateFormat);

export const formatFilterPeriod = (
  period: Period | undefined,
  key: string
) => ({
  [`${key}From`]: formatFilterDate(period?.from),
  [`${key}To`]: formatFilterDate(period?.to),
});

export const validateMaxRange = <Range extends boolean>(
  value: DatePickerValue<Range, Date>
) => {
  if (value?.from && !value?.to) {
    return <TransError i18nKey="maxRangeError" />;
  }
};
