import React, { FC } from 'react';
import type { SmsValidationData } from 'dto/mediums';
import { Grid, Stack, Typography } from '@mui/material';
import { getSuccessOrFailureText } from 'helpers/templateValidation';
import { TransTitle } from 'i18n/trans/title';
import { TextField } from '@mui/material';
import { TransField } from 'i18n/trans/field';

interface SmsValidationProps {
  data: SmsValidationData;
  index: number;
}
export const SmsValidation: FC<SmsValidationProps> = ({ data, index }) => {
  return (
    <>
      <Stack direction="row" sx={{ mb: 2 }}>
        <Typography fontWeight={900}>
          <TransTitle i18nKey="smsTemplate" />
          {':'}
        </Typography>
        {getSuccessOrFailureText(data.contentError)}
      </Stack>

      <Grid container spacing={2} columns={2}>
        <Grid item xs={1}>
          <Typography>
            <TransField i18nKey="smsSenderName" />
            {':'}
          </Typography>
          <TextField
            name={`notificationsByLanguage[${index}].smsSenderName`}
            defaultValue={data.senderName}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <Typography>
            <TransField i18nKey="smsContent" />
            {':'}
          </Typography>
          <TextField
            name={`notificationsByLanguage[${index}].smsContent`}
            defaultValue={data.content}
            disabled
            multiline
            rows={5}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};
