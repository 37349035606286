import {
  FormProvider,
  makeClassificationOptions,
  PeriodField,
  RadioGroupField,
  SelectField,
  StopsSelect,
  TextField,
  TimeUnit,
  ToggleButtonGroupField,
  useBusinessEntitiesByType,
  useWeekdaysOptions,
  weekDaysUpperCase,
} from '@fleet/shared';
import { Grid } from '@mui/material';
import { ClassificationGroup } from 'dto/classification';
import {
  AdmissionFilterByTrips,
  OdFilteringMethodType,
} from 'dto/passengersAllocation';
import { useCarrierInitValue } from 'hooks/useCarrierInitValue';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { usePassengersSearchForm } from 'hooks/search/usePassengersSearchForm';
import { TransField } from 'i18n/trans/field';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'store/utils';
import { useTripOptionsSearch } from 'hooks/passengersAllocation/useTripOptionsSearch';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { TripsSelectionField } from 'components/TripsSelectionField/TripsSearchField';
import { TransLabel } from 'i18n/trans/label';
import { useOnFilteringMethodOptions } from 'hooks/useOnFilteringMethodOptions';
import { SearchControl } from 'components/Search/SearchControl';
import {
  ActionCreatorWithPayload,
  AsyncThunk,
  Selector,
} from '@reduxjs/toolkit';
import { RootState } from 'store';
import { PostAdmissionsByTripsResponse } from 'dto/api/deviations-management';

interface SearchByTripFormProps {
  passengersFilterSelector: Selector<
    RootState,
    Partial<AdmissionFilterByTrips>
  >;
  setPassengersFilter: ActionCreatorWithPayload<
    Partial<AdmissionFilterByTrips>
  >;
  getPassengers: AsyncThunk<
    PostAdmissionsByTripsResponse,
    Partial<AdmissionFilterByTrips>,
    {}
  >;
}

export const SearchByTripForm: FC<SearchByTripFormProps> = (props) => {
  const businessEntities = useSelector(businessEntitiesSelector);
  const { CARRIER } = useBusinessEntitiesByType(businessEntities);
  const carrierOptions = makeClassificationOptions(CARRIER);
  const carrierIdFormInitValue = useCarrierInitValue(carrierOptions);
  const tripStatusOptions = useClassificationOptions(
    ClassificationGroup.TRIP_STATUS
  );
  const weekdaysOptions = useWeekdaysOptions(false);
  const odFilteringMethodOptions = useOnFilteringMethodOptions();

  const defaultValues = useMemo<Partial<AdmissionFilterByTrips>>(
    () => ({
      carrierId: carrierIdFormInitValue,
      departureDays: [...weekDaysUpperCase],
      ticketTag: { isIncluded: false },
    }),
    [carrierIdFormInitValue]
  );

  const { form, values, handleSubmit, handleReset } = usePassengersSearchForm({
    ...props,
    defaultValues,
  });

  const handleCarrierChange = useCallback(
    () =>
      form.batch(() => {
        form.change('lineIds');
        form.change('serviceCodes');
      }),
    [form]
  );

  const { carrierId, originStop, destinationStop, trips } = values;
  const isOdFilteringMethodVisible = useMemo(
    () => Boolean(originStop?.size && destinationStop?.size && trips?.length),
    [destinationStop?.size, originStop?.size, trips?.length]
  );

  useEffect(() => {
    form.change(
      'odFilteringMethod',
      isOdFilteringMethodVisible ? OdFilteringMethodType.EXACT : undefined
    );
  }, [form, isOdFilteringMethodVisible]);

  const { lineNumberOptions, serviceOptions } = useTripOptionsSearch(carrierId);

  return (
    <FormProvider form={form}>
      <form
        onSubmit={handleSubmit}
        data-testid="passengers-search-by-trips-form"
      >
        <Grid container columns={5} spacing={2}>
          <Grid item xs={1}>
            <SelectField
              name="carrierId"
              label={<TransField i18nKey="carrier" />}
              onChange={handleCarrierChange}
              options={carrierOptions}
              required
            />
          </Grid>
          <Grid item xs={1}>
            <SelectField
              name="lineIds"
              label={<TransField i18nKey="lineNumbers" />}
              options={lineNumberOptions}
              multiple
            />
          </Grid>
          <Grid item xs={1}>
            <SelectField
              name="serviceCodes"
              label={<TransField i18nKey="serviceCodes" />}
              options={serviceOptions}
              multiple
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              name="tripName"
              label={<TransField i18nKey="tripName" />}
            />
          </Grid>
          <Grid item xs={1}>
            <SelectField
              name="tripStatus"
              label={<TransField i18nKey="tripStatus" />}
              options={tripStatusOptions}
              showEmptyOption
            />
          </Grid>
          <Grid item xs={1}>
            <TripsSelectionField
              name="trips"
              label={<TransLabel i18nKey="trips" />}
            />
          </Grid>
          <Grid item xs={1}>
            <StopsSelect
              name="originStop"
              label={<TransField i18nKey="originStop" />}
              allowSearch
            />
          </Grid>
          <Grid item xs={1}>
            <StopsSelect
              name="destinationStop"
              label={<TransField i18nKey="destinationStop" />}
              allowSearch
            />
          </Grid>
          <Grid item xs={1}>
            <SelectField
              name="odFilteringMethod"
              label={<TransField i18nKey="odFilteringMethod" />}
              options={odFilteringMethodOptions}
              disabled={!isOdFilteringMethodVisible}
            />
          </Grid>
          <Grid item xs={1}>
            <RadioGroupField
              name="isTripActive"
              label={<TransField i18nKey="active" />}
              options="BOOL"
              inline
            />
          </Grid>
          <Grid item xs={1}>
            <StopsSelect
              name="stopOne"
              label={<TransField i18nKey="stopOne" />}
              allowSearch
            />
          </Grid>
          <Grid item xs={1}>
            <StopsSelect
              name="stopTwo"
              label={<TransField i18nKey="stopTwo" />}
              allowSearch
            />
          </Grid>
          <PeriodField
            from={{
              name: 'departureFrom',
              label: <TransField i18nKey="departureFrom" />,
              required: true,
            }}
            to={{
              name: 'departureTo',
              label: <TransField i18nKey="departureTo" />,
              required: true,
            }}
            maxRange={{ [TimeUnit.DAYS]: 60 }}
          />
          <Grid item xs={1}>
            <RadioGroupField
              name="isPartOfMultiLegJourney"
              label={<TransField i18nKey="isPartOfMultiLegJourney" />}
              options="BOOL"
              inline
            />
          </Grid>
          <Grid item xs={3}>
            <ToggleButtonGroupField
              name="departureDays"
              label={<TransField i18nKey="departureDays" />}
              options={weekdaysOptions}
              fullWidth
              multiple
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              name="ticketTag.value"
              label={<TransField i18nKey="ticketTag" />}
            />
          </Grid>
          <Grid item xs={1}>
            <RadioGroupField
              name="ticketTag.isIncluded"
              label={<TransField i18nKey="isTicketTagIncluded" />}
              options="BOOL_ONLY"
              inline
            />
          </Grid>
          <SearchControl handleReset={handleReset} />
        </Grid>
      </form>
    </FormProvider>
  );
};
