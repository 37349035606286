import { Button, ConfirmButton, Icon, TableCaption } from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ProblematicPassenger } from 'dto/api/deviations-management';
import { solveProblematicPassengers } from 'features/problematicPassengers/problematicPassengersActions';
import { problematicPassengersFilterSelector } from 'features/problematicPassengers/problematicPassengersSelectors';
import { TransAlert } from 'i18n/trans/alert';
import { TransButton } from 'i18n/trans/button';
import { TransModal } from 'i18n/trans/modal';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, useCallback, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router';
import { Row } from 'react-table';
import { useDispatch, useSelector } from 'store/utils';

const useStyles = makeStyles(() => ({
  button: {
    paddingRight: 0,
  },
}));

interface ProblematicPassengersTableCaptionProps {
  selectedFlatRows: Array<Row<ProblematicPassenger>>;
}

export const ProblematicPassengersTableCaption: FC<ProblematicPassengersTableCaptionProps> =
  ({ selectedFlatRows }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const alert = useAlert();
    const { carrierId } = useSelector(problematicPassengersFilterSelector);

    const isDisabledButton = useMemo(
      () => !selectedFlatRows.length,
      [selectedFlatRows.length]
    );
    const problematicPassengerIds = useMemo(
      () => selectedFlatRows.map(({ original }) => original.id),
      [selectedFlatRows]
    );

    const handleSolveProblematicPassengers = useCallback(async () => {
      await dispatch(
        solveProblematicPassengers(problematicPassengerIds)
      ).unwrap();
      alert.success(
        <TransAlert
          i18nKey="problematicPassengersSolved"
          values={{ count: selectedFlatRows.length }}
        />
      );
    }, [alert, dispatch, problematicPassengerIds, selectedFlatRows.length]);

    return (
      <TableCaption>
        <Stack direction="row" alignItems="center">
          <Stack direction="row" alignItems="center">
            <Typography variant="body2" color="text.secondary">
              <TransSubtitle
                i18nKey="selectedQty"
                values={{
                  count: selectedFlatRows.length,
                }}
              />
            </Typography>
            <Button
              variant="text"
              startIcon={<Icon name="mail" />}
              disabled={isDisabledButton}
              className={classes.button}
              onClick={() =>
                history.push({
                  pathname: `/send-notifications`,
                  state: { carrierId, problematicPassengerIds },
                })
              }
            >
              <TransButton i18nKey="sendNotifications" />
            </Button>
            <Button
              variant="text"
              startIcon={<Icon name="currency" />}
              disabled={isDisabledButton}
              className={classes.button}
            >
              <TransButton i18nKey="massCompensate" />
            </Button>
            <ConfirmButton
              variant="text"
              startIcon={<Icon name="check" />}
              title={
                <TransModal i18nKey="problematicPassengersMarkAsSolvedTitle" />
              }
              description={
                <TransModal
                  i18nKey="problematicPassengersMarkAsSolvedDescription"
                  values={{ count: selectedFlatRows.length }}
                />
              }
              disabled={isDisabledButton}
              className={classes.button}
              onConfirm={handleSolveProblematicPassengers}
            >
              <TransButton i18nKey="markAsSolved" />
            </ConfirmButton>
          </Stack>
        </Stack>
      </TableCaption>
    );
  };
