import {
  Button,
  Loadable,
  makeClassificationOptions,
  Modal,
  SearchModalProps,
  Table,
  TableColumns,
  useBusinessEntitiesByType,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useFilters, useRowSelect, useTable } from 'react-table';
import { Icon } from '@fleet/shared/mui';
import { Stack } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { TransTableHead } from 'i18n/trans/table';
import { TransTitle } from 'i18n/trans/title';
import { fetchLines } from 'features/notifications/notificationsService';
import { useSelector } from 'react-redux';
import type { Classifier } from '@fleet/shared/dto/classifier';
import { GetLinesSearchParams } from 'dto/api/line-management';
import { LinesSearchForm } from 'routes/SendNotifications/filters/SearchForms/LinesSearchForm';
import { ModalTableCaption } from 'routes/SendNotifications/filters/Modals/ModalTableCaption';
import { useModalTextFieldUtils } from 'hooks/useModalTextFieldUtils';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';

interface LinesSelectionModalProps
  extends SearchModalProps<Array<Classifier<number>>> {}

export interface LineRow extends Classifier<number> {
  number: string;
  carrier: string;
}

export const LinesModal: FC<LinesSelectionModalProps> = ({
  isOpen,
  name,
  onSubmit,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<LineRow[]>([]);
  const [view, setView] = useState<'search' | 'selected'>('search');
  const [confirmedSelection, setConfirmedSelection] = useState(
    new Map<string, LineRow>()
  );
  const [draftSelection, setDraftSelection] = useState(
    new Map<string, LineRow>()
  );
  const { selectedRowIds, getRowId, handleClose, stateReducer } =
    useModalTextFieldUtils<LineRow>({
      draftSelection,
      setDraftSelection,
      confirmedSelection,
      name,
      onClose,
    });

  const businessEntities = useSelector(businessEntitiesSelector);
  const { CARRIER } = useBusinessEntitiesByType(businessEntities);
  const carrierOptions = makeClassificationOptions(CARRIER);

  const columns = useMemo<TableColumns<LineRow>>(
    () => [
      {
        accessor: 'number',
        Header: <TransTableHead i18nKey="lineNumber" />,
      },
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="lineName" />,
      },
      {
        accessor: 'carrier',
        Header: <TransTableHead i18nKey="carrier" />,
      },
    ],
    []
  );

  const table = useTable<LineRow>(
    {
      data,
      columns,
      getRowId,
      useControlledState: (state) => ({
        ...state,
        selectedRowIds,
      }),
      stateReducer,
    },
    useFilters,
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const selectedLinesTable = useTable<LineRow>(
    {
      data: useMemo(
        () => Array.from(confirmedSelection.values()),
        [confirmedSelection]
      ),
      columns,
      getRowId,
      useControlledState: (state) => ({
        ...state,
        selectedRowIds,
      }),
      stateReducer,
    },
    useFilters,
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const handleSearchSubmit = useCallback(
    async (filter: Partial<GetLinesSearchParams>) => {
      setIsLoading(true);
      const carrierMap = new Map(
        carrierOptions.map(({ value, label }) => [value, label])
      );

      const values = await fetchLines(filter);
      const mappedValues: LineRow[] = values.map((line) => ({
        id: line.id,
        number: line.number,
        name: line.name,
        carrier: carrierMap.get(line.carrierId!) ?? '-',
      }));
      setData(mappedValues);

      setIsLoading(false);
    },
    [carrierOptions]
  );

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      title={<TransTitle i18nKey="selectLines" />}
      actionButton={
        <Button
          variant="contained"
          type="submit"
          onClick={() => {
            onSubmit(
              table.selectedFlatRows.map((row) => ({
                name: row.original.name,
                id: row.original.id,
              }))
            );
            setConfirmedSelection(draftSelection);
            onClose();
          }}
          startIcon={<Icon name="check" size={20} />}
        >
          <TransButton i18nKey="confirm" />
        </Button>
      }
      maxWidth="md"
      fullWidth
    >
      <Loadable loading={isLoading}>
        <Stack rowGap={4}>
          <LinesSearchForm onSubmit={handleSearchSubmit} />
          <ModalTableCaption
            totalCount={data.length}
            view={view}
            setView={setView}
            subtitleKey="linesQty"
            labelKey="displaySelectedLines"
          />
          {view === 'search' && <Table table={table} />}
          {view === 'selected' && <Table table={selectedLinesTable} />}
        </Stack>
      </Loadable>
    </Modal>
  );
};
