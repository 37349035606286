import { createSelector, selector } from 'store/utils';

export const selectProblematicPassengersFilter = selector(
  (state) => state.problematicPassengers.filter
);
export const problematicPassengersFilterSelector = createSelector(
  selectProblematicPassengersFilter
);

const selectProblematicPassengers = selector(
  (state) => state.problematicPassengers.list
);
export const problematicPassengersSelector = createSelector(
  selectProblematicPassengers
);
