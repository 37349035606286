import { SearchForm } from '@fleet/shared';
import { Tab, Tabs as MuiTabs } from '@mui/material';
import { SearchByTripForm } from 'components/Search/SearchByTripForm';
import { AdmissionSearchGroup } from 'dto/passengersAllocation';
import { setPassengersAllocationFilterTab } from 'features/passengersAllocation/passengersAllocationActions';
import { passengersAllocationTabSelector } from 'features/passengersAllocation/passengersAllocationSelectors';
import { TransTitle } from 'i18n/trans/title';
import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { passengersSearchCallbacks } from 'hooks/search/usePassengersSearchForm';

interface PassengersAllocationSearchProps {}

export const PassengersAllocationSearch: FC<PassengersAllocationSearchProps> =
  () => {
    const dispatch = useDispatch();
    const tab = useSelector(passengersAllocationTabSelector);

    const tabs = useMemo(
      () => ({
        [AdmissionSearchGroup.TRIPS]: {
          component: <SearchByTripForm {...passengersSearchCallbacks[tab]} />,
          label: <TransTitle i18nKey="searchByTrips" />,
        },
      }),
      [tab]
    );

    const handleChangeTab = useCallback(
      (event: SyntheticEvent<Element, Event>, value) => {
        event.stopPropagation();
        dispatch(setPassengersAllocationFilterTab(value));
      },
      [dispatch]
    );

    return (
      <SearchForm title={<TransTitle i18nKey="passengersAllocation" />}>
        <MuiTabs value={tab} onChange={handleChangeTab} sx={{ mt: -2, mb: 2 }}>
          {Object.values(AdmissionSearchGroup).map((group) => (
            <Tab key={group} value={group} label={tabs[group].label} />
          ))}
        </MuiTabs>
        {tabs[tab].component}
      </SearchForm>
    );
  };
