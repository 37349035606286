import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransError = createTrans({
  maxRangeError: (
    <Trans
      i18nKey="error.maxRangeError"
      defaults="Value should contain end date"
    />
  ),
});
