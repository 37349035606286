import { createReducer } from '@reduxjs/toolkit';
import { PostAdmissionsByTripsResponse } from 'dto/api/deviations-management';
import { AdmissionFilterByTrips } from 'dto/passengersAllocation';
import {
  clearTripPassengerOverview,
  getTripPassengerOverview,
  setTripPassengerOverviewFilter,
} from 'features/tripPassengerOverview/tripPassengerOverviewActions';

export interface CommonState {
  filterByTrips: Partial<AdmissionFilterByTrips>;
  listByTrips?: PostAdmissionsByTripsResponse;
}

const initialState: CommonState = {
  filterByTrips: {},
};

export const tripPassengerOverviewReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(setTripPassengerOverviewFilter, (state, action) => {
        state.filterByTrips = action.payload;
      })
      .addCase(clearTripPassengerOverview, (state) => {
        state.listByTrips = undefined;
      })
      .addCase(getTripPassengerOverview.fulfilled, (state, action) => {
        state.listByTrips = action.payload;
      });
  }
);
