import { createReducer } from '@reduxjs/toolkit';
import {
  getBusinessEntities,
  getClassificationGroups,
  getCountries,
  getInventoryClasses,
} from 'features/classification/classificationActions';
import { Classification, ClassificationGroupKey } from 'dto/classification';
import { Classifier } from '@fleet/shared/dto/classifier';
import { GetInventoryClassesItem } from 'dto/api/vehicle-management';
import { BusinessEntity } from '@fleet/shared/dto/businessEntity';

type ClassificationState = Record<ClassificationGroupKey, Array<Classifier>> & {
  [Classification.BUSINESS_ENTITY]: Array<BusinessEntity<number>>;
  [Classification.COUNTRY]: Array<Classifier<number>>;
  [Classification.INVENTORY_CLASS]: Array<GetInventoryClassesItem>;
};

const initialState = {} as ClassificationState;

export const classificationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getClassificationGroups.fulfilled, (state, action) => {
      action.payload.forEach((data) => (state[data.id] = data.classifications));
    })
    .addCase(getBusinessEntities.fulfilled, (state, action) => {
      state[Classification.BUSINESS_ENTITY] = action.payload;
    })
    .addCase(getCountries.fulfilled, (state, action) => {
      state[Classification.COUNTRY] = action.payload;
    })
    .addCase(getInventoryClasses.fulfilled, (state, action) => {
      state[Classification.INVENTORY_CLASS] = action.payload;
    });
});
