import { Period } from '@fleet/shared';
import { Classifier } from '@fleet/shared/dto/classifier';
import {
  PostReAccommodateSearchParams,
  PostReAccommodateTripsRequest,
} from 'dto/api/line-management';

export const passengerReAccommodateStep = {
  selectTrip: 0,
  confirmChanges: 1,
} as const;

export type PassengerReAccommodateStep =
  keyof typeof passengerReAccommodateStep;

export type ReAccommodateTripsFilter = Omit<
  PostReAccommodateTripsRequest,
  | 'stopOneId'
  | 'stopTwoId'
  | 'tripDateFrom'
  | 'tripDateTo'
  | 'createdOnDateFrom'
  | 'createdOnDateTo'
> &
  PostReAccommodateSearchParams & {
    carrierId: number;
    departurePeriod: Period;
    createdOnPeriod: Period;
    stopOne: Classifier;
    stopTwo: Classifier;
  };

export interface ReAccommodateProgressReport {
  errorCount: number;
  isEnded: boolean;
  successCount: number;
  totalCount: number;
}

export interface ReAccommodateProgress {
  progressReport: ReAccommodateProgressReport | null;
}
