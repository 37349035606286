import { createAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from 'store/utils';
import { AdmissionFilterByTrips } from 'dto/passengersAllocation';
import { api } from '@fleet/shared';
import { PostAdmissionsByTripsResponse } from 'dto/api/deviations-management';
import { parseFilterByTrips } from 'helpers/parse';

export const setTripPassengerOverviewFilter = createAction<
  Partial<AdmissionFilterByTrips>
>('tripPassengerOverviewActions/setTripPassengerOverviewFilter');

export const clearTripPassengerOverview = createAction(
  'tripPassengerOverviewActions/clearTripPassengerOverview'
);

export const getTripPassengerOverview = createAsyncThunk<
  PostAdmissionsByTripsResponse,
  Partial<AdmissionFilterByTrips>
>(
  'tripPassengerOverviewActions/getTripPassengerOverview',
  async (values, { getState, dispatch }) => {
    if (values) {
      dispatch(setTripPassengerOverviewFilter(values));
    }

    const {
      tripPassengerOverview: { filterByTrips },
      common: { currentBusinessEntityId },
    } = getState();

    const filter = parseFilterByTrips(filterByTrips, currentBusinessEntityId);

    return (
      await api.post<PostAdmissionsByTripsResponse>(
        '/trips/admissions/search',
        filter,
        {
          shouldRelocatePaginationAndSortingParamsFromPayloadToQueryString:
            true,
        }
      )
    ).data;
  }
);
