import {
  Button,
  Icon,
  SearchResult,
  SortingRules,
  Table,
  TableColumns,
  currentDateTimeFormat,
  formatDate,
  useModal,
  useRowActive,
  useRowExpandToggle,
  useRowSelectRadio,
  useTable,
} from '@fleet/shared';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { CardContent, Divider, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReAccommodateTrip } from 'dto/api/line-management';
import { reAccommodateLoadingSelector } from 'features/loading/loadingSelectors';
import {
  getReAccommodateNewTripsList,
  setReAccommodateActiveStep,
  startReAccommodateCurrentProcess,
} from 'features/reAccommodate/reAccommodateActions';
import {
  reAccommodateNewTripsListSelector,
  reAccommodateNewTripsFilterSelector,
  reAccommodatePassengersListSelector,
} from 'features/reAccommodate/reAccommodateSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransTableHead } from 'i18n/trans/table';
import { FC, useCallback, useMemo } from 'react';
import {
  useExpanded,
  usePagination,
  useRowSelect,
  useSortBy,
} from 'react-table';
import { ReAccommodateStepProps } from 'routes/PassengersAllocation/ReAccommodate/ReAccommodateSteps';
import { SelectTripMismatchModal } from 'routes/PassengersAllocation/ReAccommodate/SelectTrip/Mismatch/SelectTripMismatchModal';
import { ReAccommodateSelectTripSearch } from 'routes/PassengersAllocation/ReAccommodate/SelectTrip/ReAccommodateSelectTripSearch';
import { ReAccommodateSelectTripSubTable } from 'routes/PassengersAllocation/ReAccommodate/SelectTrip/ReAccommodateSelectTripSubTable';
import { useDispatch, useSelector } from 'store/utils';

const useStyles = makeStyles((theme) => ({
  actions: {
    padding: theme.spacing(0, 2, 2, 2),
    '& .MuiButton-text': { padding: 0 },
  },
}));

export const ReAccommodateSelectTrip: FC<ReAccommodateStepProps> = ({
  onCancelReAccommodate,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const passengersList = useSelector(reAccommodatePassengersListSelector);
  const newTripsList = useSelector(reAccommodateNewTripsListSelector);
  const filter = useSelector(reAccommodateNewTripsFilterSelector);
  const loading = useSelector(reAccommodateLoadingSelector);
  const data = useMemo(() => newTripsList?.items ?? [], [newTripsList]);
  const { open: isOpen, onOpen, onClose } = useModal();

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) => {
      await dispatch(
        getReAccommodateNewTripsList({ ...filter, ...paginationParams })
      ).unwrap();
    },
    [dispatch, filter]
  );

  const handleSortingChange = useCallback(
    async (sortBy: SortingRules) => {
      await dispatch(
        getReAccommodateNewTripsList({
          ...filter,
          sortBy,
        })
      ).unwrap();
    },
    [dispatch, filter]
  );

  const columns = useMemo<TableColumns<ReAccommodateTrip>>(
    () => [
      {
        id: 'serviceCode',
        accessor: ({ serviceCode }) => serviceCode ?? ' - ',
        Header: <TransTableHead i18nKey="serviceCode" />,
      },
      {
        id: 'departureDateTime',
        accessor: ({ departureDateTime }) =>
          formatDate(departureDateTime, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="departureTime" />,
      },
      {
        id: 'carrier',
        accessor: ({ carrier }) => carrier?.name ?? '-',
        Header: <TransTableHead i18nKey="carrier" />,
        disableSortBy: true,
      },
      {
        id: 'lineNumber',
        accessor: ({ lineName }) => lineName ?? ' - ',
        Header: <TransTableHead i18nKey="lineNumber" />,
      },
      {
        id: 'name',
        accessor: ({ name }) => name ?? ' - ',
        Header: <TransTableHead i18nKey="tripName" />,
        disableSortBy: true,
      },
      {
        id: 'originStop.stop.name',
        accessor: ({ originStop }) => originStop?.stop?.name ?? ' - ',
        Header: <TransTableHead i18nKey="originStop" />,
      },
      {
        id: 'destinationStop.stop.name',
        accessor: ({ destinationStop }) => destinationStop?.stop?.name ?? ' - ',
        Header: <TransTableHead i18nKey="destinationStop" />,
      },
      {
        id: 'vehicleCompositions',
        accessor: ({ vehicleCompositions }) =>
          (vehicleCompositions ?? []).map(({ name }) => name).join(' + '),
        Header: <TransTableHead i18nKey="vehicleProfile" />,
        disableSortBy: true,
      },
      {
        id: 'hasAdmissions',
        accessor: ({ hasAdmissions }) => (
          <Icon
            name={hasAdmissions ? 'check' : 'close'}
            color={hasAdmissions ? 'success' : 'error'}
          />
        ),
        Header: <TransTableHead i18nKey="hasTickets" />,
      },
      {
        id: 'isActive',
        accessor: ({ isActive }) => (
          <Icon
            name={isActive ? 'check' : 'close'}
            color={isActive ? 'success' : 'error'}
          />
        ),
        Header: <TransTableHead i18nKey="isActive" />,
      },
    ],
    []
  );

  const table = useTable<ReAccommodateTrip>(
    {
      data,
      columns,
      pageCount: -1,
      total: newTripsList?.totalCount,
      offset: newTripsList?.offset,
      limit: filter.limit,
      sortBy: filter.sortBy,
      onPageChange: handlePageChange,
      onSortingChange: handleSortingChange,
    },
    useSortBy,
    useRowActive,
    useExpanded,
    usePagination,
    useRowSelect,
    useRowExpandToggle,
    useRowSelectRadio
  );

  const { selectedFlatRows } = table;
  const tripId = useMemo(
    () => selectedFlatRows[0]?.original.id,
    [selectedFlatRows]
  );

  const handleNextStepClick = useCallback(async () => {
    if (selectedFlatRows.length !== 1) {
      return;
    }

    const { mismatchedAdmissions } = await dispatch(
      startReAccommodateCurrentProcess({
        newTripId: tripId,
        admissions: passengersList.map(
          ({ admissionNumber, trip: { id: oldTripId } }) => ({
            admissionNumber,
            oldTripId,
          })
        ),
      })
    ).unwrap();

    if (mismatchedAdmissions.mismatches?.routes.length) {
      return onOpen();
    }

    dispatch(setReAccommodateActiveStep('confirmChanges'));
  }, [passengersList, dispatch, onOpen, selectedFlatRows.length, tripId]);

  return (
    <>
      <ReAccommodateSelectTripSearch />
      <Divider />
      <SearchResult results={!!data.length} loading={loading}>
        <Table
          data-testid="search-trips-result-table"
          table={table}
          getTableProps={{ sx: { tableLayout: 'fixed' } }}
          getSubRow={({ original: { id } }) => (
            <CardContent>
              <ReAccommodateSelectTripSubTable tripId={id} />
            </CardContent>
          )}
          getRowProps={() => ({
            sx: { backgroundColor: 'common.white' },
            onClick: () => null,
          })}
        />
      </SearchResult>
      <Stack
        direction="row"
        justifyContent="flex-end"
        className={classes.actions}
        spacing={2}
        data-testid="select-trip-table-controller"
      >
        <>
          <Button variant="text" onClick={onCancelReAccommodate}>
            <TransButton i18nKey="cancel" />
          </Button>
          <Button
            startIcon={<Icon name="arrow-right" />}
            disabled={!tripId}
            onClick={handleNextStepClick}
            data-testid="select-trip-submit"
          >
            <TransButton i18nKey="reAccommodate" />
          </Button>
        </>
        <SelectTripMismatchModal isOpen={isOpen} onClose={onClose} />
      </Stack>
    </>
  );
};
