import { OdFilteringMethodType } from 'dto/passengersAllocation';
import { TransField } from 'i18n/trans/field';
import { useMemo } from 'react';

export const useOnFilteringMethodOptions = () => {
  return useMemo(
    () => [
      {
        value: OdFilteringMethodType.EXACT,
        label: <TransField i18nKey="odFilteringMethod.EXACT" />,
      },
      {
        value: OdFilteringMethodType.SUBSEGMENT,
        label: <TransField i18nKey="odFilteringMethod.SUBSEGMENT" />,
      },
      {
        value: OdFilteringMethodType.OVERLAP,
        label: <TransField i18nKey="odFilteringMethod.OVERLAP" />,
      },
    ],
    []
  );
};
