import { Step, StepLabel, Stepper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  PassengerReAccommodateStep,
  passengerReAccommodateStep,
} from 'dto/reAccommodate';
import { passengersAllocationTabSelector } from 'features/passengersAllocation/passengersAllocationSelectors';
import { reAccommodateActiveStepSelector } from 'features/reAccommodate/reAccommodateSelectors';
import { passengersSearchCallbacks } from 'hooks/search/usePassengersSearchForm';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { ReAccommodateConfirmChanges } from 'routes/PassengersAllocation/ReAccommodate/ConfirmChanges/ReAccommodateConfirmChanges';
import { ReAccommodateSelectTrip } from 'routes/PassengersAllocation/ReAccommodate/SelectTrip/ReAccommodateSelectTrip';
import { useDispatch, useSelector } from 'store/utils';

const useStyles = makeStyles((theme) => ({
  stepper: {
    margin: theme.spacing(2, 0),
  },
}));

export interface ReAccommodateStepProps {
  onCancelReAccommodate: () => void;
}

interface ReAccommodateStepsProps {}

export const ReAccommodateSteps: FC<ReAccommodateStepsProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const activeStep = useSelector(reAccommodateActiveStepSelector);
  const tab = useSelector(passengersAllocationTabSelector);
  const { getPassengers, passengersFilterSelector } =
    passengersSearchCallbacks[tab];
  const filter = useSelector(passengersFilterSelector);
  const steps = useMemo(() => Object.keys(passengerReAccommodateStep), []);

  const components: Record<
    PassengerReAccommodateStep,
    FC<ReAccommodateStepProps>
  > = useMemo(
    () => ({
      selectTrip: ReAccommodateSelectTrip,
      confirmChanges: ReAccommodateConfirmChanges,
    }),
    []
  );
  const Component = useMemo(
    () => components[activeStep],
    [activeStep, components]
  );

  const handleCancelReAccommodate = useCallback(async () => {
    history.replace('/passengers-allocation');
    dispatch(getPassengers(filter));
  }, [dispatch, filter, getPassengers, history]);

  return (
    <>
      <Stepper
        activeStep={passengerReAccommodateStep[activeStep]}
        alternativeLabel
        className={classes.stepper}
      >
        {steps.map((step) => (
          <Step key={step}>
            <StepLabel>
              <TransSubtitle i18nKey={step as PassengerReAccommodateStep} />
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Component onCancelReAccommodate={handleCancelReAccommodate} />
    </>
  );
};
