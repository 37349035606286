import { FormField, SelectOption } from '@fleet/shared';
import { SelectValue } from '@fleet/shared/mui/Select';
import { Admission } from 'dto/api/deviations-management';
import { ReAccommodateDetailedTripStop } from 'dto/api/deviations-management';
import { FC } from 'react';
import { CellProps } from 'react-table';
import { MismatchModalStopSelect } from 'routes/PassengersAllocation/ReAccommodate/SelectTrip/Mismatch/StopField/MismatchModalStopSelect';
import { getOuterNewStopFieldName } from 'routes/PassengersAllocation/ReAccommodate/SelectTrip/Mismatch/StopField/utils';

interface MismatchModalStopFieldProps extends CellProps<Admission> {
  isNewOrigin?: boolean;
  stopOptions: Array<SelectOption<SelectValue>>;
}

export const MismatchModalStopField: FC<MismatchModalStopFieldProps> = ({
  row: {
    original: { admissionNumber },
  },
  column: { id },
  isNewOrigin = false,
  stopOptions,
}) => {
  return (
    <FormField<ReAccommodateDetailedTripStop['routeStopId']>
      name={getOuterNewStopFieldName(admissionNumber, isNewOrigin)}
      render={({ input: { value } }) => (
        <MismatchModalStopSelect
          isNewOrigin={isNewOrigin}
          admissionNumber={admissionNumber}
          postfix={id}
          stopOptions={stopOptions}
          outerValue={value}
        />
      )}
    />
  );
};
