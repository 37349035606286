import { useEffect, useMemo, useState } from 'react';
import { LineNumber } from 'dto/api/line-management';
import { fetchLineNumbers } from 'features/common/commonService';
import { useIsMounted } from '@fleet/shared';
import { useServiceOptions } from 'hooks/useServiceOptions';

export const useTripOptionsSearch = (carrierId: number | undefined) => {
  const isMounted = useIsMounted();
  const [lineNumbers, setLineNumbers] = useState<Array<LineNumber>>([]);

  const lineNumberOptions = useMemo(
    () => lineNumbers.map(({ id, number }) => ({ value: id, label: number })),
    [lineNumbers]
  );

  const serviceOptions = useServiceOptions(carrierId);

  useEffect(() => {
    const fetchData = async (carrierId: number) => {
      if (!carrierId) {
        return;
      }

      const lineNumbers = await fetchLineNumbers(carrierId);
      if (isMounted.current) {
        setLineNumbers(lineNumbers);
      }
    };

    if (carrierId) {
      fetchData(carrierId);
    }
  }, [carrierId, isMounted]);

  return { lineNumberOptions, serviceOptions };
};
