import { SearchField, SearchFieldProps } from '@fleet/shared';
import { Classifier } from '@fleet/shared/dto/classifier';
import { TripSelectionModal } from 'components/TripSelectionField/TripSelectionModal';
import { TransButton } from 'i18n/trans/button';
import { FC } from 'react';

interface TripSelectionFieldProps
  extends SearchFieldProps<Classifier<number>> {}

export const TripSelectionField: FC<TripSelectionFieldProps> = (props) => {
  return (
    <SearchField
      {...props}
      modal={TripSelectionModal}
      description={<TransButton i18nKey="selectTrip" />}
    />
  );
};
