import { SearchField, SearchFieldProps } from '@fleet/shared';
import { Classifier } from '@fleet/shared/dto/classifier';
import { TripsModal } from 'components/TripsSelectionField/TripsModal';
import { TransButton } from 'i18n/trans/button';
import { FC } from 'react';

interface TripsSelectionFieldProps
  extends SearchFieldProps<Array<Classifier<number>>> {}

export const TripsSelectionField: FC<TripsSelectionFieldProps> = (props) => {
  return (
    <SearchField
      {...props}
      modal={TripsModal}
      description={<TransButton i18nKey="selectTrip" />}
    />
  );
};
