import { currentDateTimeFormat, formatDate } from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

interface TripNameCellProps {
  name: string;
  date: string;
}

export const TripNameCell: FC<TripNameCellProps> = ({ name, date }) => {
  return (
    <Stack direction="column">
      <Typography variant="body2">
        {formatDate(date, currentDateTimeFormat)}
      </Typography>
      <Typography variant="body2">{name}</Typography>
    </Stack>
  );
};
