import { useEffect, useMemo, useState } from 'react';
import { Service } from 'dto/api/line-management';
import { SelectOption, useIsMounted } from '@fleet/shared';
import { SelectValue } from '@fleet/shared/mui/Select';
import { fetchServices } from 'features/common/commonService';
import _uniqBy from 'lodash/uniqBy';

export const useServiceOptions = (carrierId: number | undefined) => {
  const isMounted = useIsMounted();
  const [services, setServices] = useState<Array<Service>>([]);

  const sortCodes = (a: Service, b: Service) => {
    const formattedCodeA = Number(a.code);
    const formattedCodeB = Number(b.code);

    if (isNaN(formattedCodeA) || isNaN(formattedCodeB)) {
      return 0;
    }

    return formattedCodeA - formattedCodeB;
  };

  const serviceOptions = useMemo(
    () =>
      services.reduce((acc, { code }) => {
        if (code) {
          acc.push({ value: code, label: code });
        }

        return acc;
      }, [] as Array<SelectOption<SelectValue>>),
    [services]
  );

  useEffect(() => {
    const fetchData = async (carrierId: number) => {
      if (!carrierId) {
        return;
      }

      const services = await fetchServices(carrierId);
      if (isMounted.current) {
        setServices(_uniqBy(services, 'code').sort(sortCodes));
      }
    };

    if (carrierId) {
      fetchData(carrierId);
    }
  }, [carrierId, isMounted]);

  return serviceOptions;
};
