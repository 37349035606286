import { Button, Icon, Modal } from '@fleet/shared';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { reAccommodateMismatchesDictionarySelector } from 'features/reAccommodate/reAccommodateSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransTitle } from 'i18n/trans/title';
import { FC, useMemo } from 'react';
import { SelectTripMismatchModalPassengersTable } from 'routes/PassengersAllocation/ReAccommodate/SelectTrip/Mismatch/SelectTripMismatchModalPassengersTable';
import { SelectTripMismatchModalStopsTable } from 'routes/PassengersAllocation/ReAccommodate/SelectTrip/Mismatch/SelectTripMismatchModalStopsTable';
import { useSelector } from 'store/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialogContent-root': {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      gap: theme.spacing(3),
    },
  },
}));

interface SelectTripMismatchModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SelectTripMismatchModal: FC<SelectTripMismatchModalProps> = ({
  isOpen,
  onClose,
}) => {
  const classes = useStyles();
  const mismatchesDictionary = useSelector(
    reAccommodateMismatchesDictionarySelector
  );

  const mismatchesCounter = useMemo(
    () => Object.values(mismatchesDictionary).length,
    [mismatchesDictionary]
  );

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={<TransTitle i18nKey="stopMismatchWarning" />}
      actionButton={
        <Button
          variant="contained"
          type="submit"
          form="newStopsForm"
          startIcon={<Icon name="check" size={20} />}
        >
          <TransButton i18nKey="confirm" />
        </Button>
      }
      maxWidth="lg"
      fullWidth
      className={classes.root}
    >
      <SelectTripMismatchModalPassengersTable onClose={onClose} />
      <Typography>
        <TransField
          i18nKey="stopMismatchWarning"
          values={{
            count: mismatchesCounter,
          }}
        />
      </Typography>
      <SelectTripMismatchModalStopsTable />
    </Modal>
  );
};
