import {
  Button,
  DateField,
  FormControl,
  FormProvider,
  RadioGroupField,
  SearchForm,
  SelectField,
  StopsSelect,
  TextField,
  formSubmit,
  makeClassificationOptions,
  useBusinessEntitiesByType,
  useForm,
} from '@fleet/shared';
import { Grid, Stack } from '@mui/material';
import { TripSelectionField } from 'components/TripSelectionField/TripSearchField';
import { ClassificationGroup } from 'dto/classification';
import { ProblematicPassengersFilter } from 'dto/problematicPassengers';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import {
  getProblematicPassengers,
  setProblematicPassengersFilter,
} from 'features/problematicPassengers/problematicPassengersActions';
import { problematicPassengersFilterSelector } from 'features/problematicPassengers/problematicPassengersSelectors';
import { validateMaxRange } from 'helpers/date';
import { useTripOptionsSearch } from 'hooks/passengersAllocation/useTripOptionsSearch';
import { useCarrierInitValue } from 'hooks/useCarrierInitValue';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransTitle } from 'i18n/trans/title';
import { pick } from 'lodash';
import { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';

interface ProblematicPassengersSearchFormProps {}

export const ProblematicPassengersSearchForm: FC<ProblematicPassengersSearchFormProps> =
  () => {
    const dispatch = useDispatch();
    const filter = useSelector(problematicPassengersFilterSelector);
    const businessEntities = useSelector(businessEntitiesSelector);
    const { CARRIER } = useBusinessEntitiesByType(businessEntities);
    const carrierOptions = makeClassificationOptions(CARRIER);
    const carrierIdFormInitValue = useCarrierInitValue(carrierOptions);
    const problemTypesOptions = useClassificationOptions(
      ClassificationGroup.PROBLEM_TYPE
    );

    const defaultValues = useMemo<Partial<ProblematicPassengersFilter>>(
      () => ({
        carrierId: carrierIdFormInitValue,
        ticketTag: { isIncluded: false },
      }),
      [carrierIdFormInitValue]
    );

    const initialValues = useMemo<Partial<ProblematicPassengersFilter>>(
      () => ({
        ...defaultValues,
        ...filter,
      }),
      [defaultValues, filter]
    );

    const onSubmit = useCallback(
      async (values: ProblematicPassengersFilter) =>
        formSubmit(async () => {
          (document.activeElement as HTMLInputElement)?.blur?.();
          await dispatch(getProblematicPassengers({ ...values, offset: 0 }));
        }),
      [dispatch]
    );

    const { form, handleSubmit, values } = useForm({
      initialValues,
      onSubmit,
      subscription: { values: true },
    });

    const { carrierId } = values;

    const { lineNumberOptions, serviceOptions } =
      useTripOptionsSearch(carrierId);

    const handleCarrierChange = useCallback(
      () =>
        form.batch(() => {
          form.change('lineIds');
          form.change('serviceCodes');
        }),
      [form]
    );

    const handleReset = useCallback(() => {
      form.reset();
      dispatch(
        setProblematicPassengersFilter({
          ...defaultValues,
          ...pick(filter, ['limit', 'offset']),
        })
      );
    }, [defaultValues, dispatch, filter, form]);

    return (
      <SearchForm title={<TransTitle i18nKey="problematicPassengers" />}>
        <FormProvider form={form}>
          <form onSubmit={handleSubmit}>
            <Grid container columns={5} spacing={2}>
              <Grid item xs={1}>
                <SelectField
                  name="carrierId"
                  label={<TransField i18nKey="carrier" />}
                  onChange={handleCarrierChange}
                  options={carrierOptions}
                  required
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="lineIds"
                  label={<TransField i18nKey="lineNumbers" />}
                  options={lineNumberOptions}
                  multiple
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="serviceCodes"
                  label={<TransField i18nKey="serviceCodes" />}
                  options={serviceOptions}
                  multiple
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="bookingNumber"
                  label={<TransField i18nKey="bookingNumber" />}
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="ticketNumber"
                  label={<TransField i18nKey="ticketNumber" />}
                />
              </Grid>
              <Grid item xs={1}>
                <RadioGroupField
                  name="isProblemSolved"
                  label={<TransField i18nKey="isSolved" />}
                  options="BOOL"
                  inline
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="problemTypes"
                  label={<TransField i18nKey="problemTypes" />}
                  options={problemTypesOptions}
                  multiple
                />
              </Grid>
              <Grid item xs={1}>
                <StopsSelect
                  name="originStop"
                  label={<TransField i18nKey="departureStop" />}
                  allowSearch
                />
              </Grid>
              <Grid item xs={1}>
                <StopsSelect
                  name="destinationStop"
                  label={<TransField i18nKey="destinationStop" />}
                  allowSearch
                />
              </Grid>
              <Grid item xs={1}>
                <DateField
                  name="departureDatePeriod"
                  label={<TransField i18nKey="departurePeriod" />}
                  selectsRange
                  maxRange={6}
                  validate={validateMaxRange}
                  isClearable
                />
              </Grid>
              <Grid item xs={1}>
                <DateField
                  name="salesDatePeriod"
                  label={<TransField i18nKey="salesPeriod" />}
                  selectsRange
                  maxRange={6}
                  validate={validateMaxRange}
                  isClearable
                />
              </Grid>
              <Grid item xs={1}>
                <DateField
                  name="problemCreatedPeriod"
                  label={<TransField i18nKey="problemCreatedOnPeriod" />}
                  selectsRange
                  maxRange={6}
                  validate={validateMaxRange}
                  isClearable
                />
              </Grid>
              <Grid item xs={1}>
                <DateField
                  name="problemChangedPeriod"
                  label={<TransField i18nKey="modifiedPeriod" />}
                  selectsRange
                  maxRange={6}
                  validate={validateMaxRange}
                  isClearable
                />
              </Grid>
              <Grid item xs={1}>
                <TripSelectionField
                  name="oldTrip"
                  label={<TransField i18nKey="passengerMovedFromTrip" />}
                />
              </Grid>
              <Grid item xs={1}>
                <TripSelectionField
                  name="newTrip"
                  label={<TransField i18nKey="passengerMovedToTrip" />}
                />
              </Grid>
              <Grid item xs={1}>
                <RadioGroupField
                  name="isPartOfMultiLegJourney"
                  label={<TransField i18nKey="isPartOfMultiLegJourney" />}
                  options="BOOL"
                  inline
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  name="ticketTag.value"
                  label={<TransField i18nKey="ticketTag" />}
                />
              </Grid>
              <Grid item xs={1}>
                <RadioGroupField
                  name="ticketTag.isIncluded"
                  label={<TransField i18nKey="isTicketTagIncluded" />}
                  options="BOOL_ONLY"
                  inline
                />
              </Grid>
              <Grid item xs={1}>
                <RadioGroupField
                  name="hasBeenReallocated"
                  label={<TransField i18nKey="hasBeenReallocated" />}
                  options="BOOL"
                  inline
                />
              </Grid>
              <Grid item xs="auto" sx={{ ml: 'auto' }}>
                <Stack direction="row" spacing={2}>
                  <FormControl label="&nbsp;">
                    <Button
                      sx={{ whiteSpace: 'nowrap' }}
                      variant="text"
                      onClick={handleReset}
                    >
                      <TransButton i18nKey="resetFilters" />
                    </Button>
                  </FormControl>
                  <FormControl label="&nbsp;">
                    <Button variant="contained" type="submit" icon="search">
                      <TransButton i18nKey="search" />
                    </Button>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </SearchForm>
    );
  };
