import React, { FC, useEffect, useMemo } from 'react';
import { Email } from 'routes/SendNotifications/content/sections/email/Email';
import { Divider } from '@mui/material';
import { Sms } from 'routes/SendNotifications/content/sections/sms/Sms';
import { useDispatch, useSelector } from 'store/utils';
import { notificationLanguagesSelector } from 'features/notifications/notificationsSelectors';
import { countTotalRecipients } from 'helpers/mediums';
import { setNotificationSummaryData } from 'features/notifications/notificationsActions';

export const Mediums: FC = () => {
  const dispatch = useDispatch();
  const notificationLanguages = useSelector(notificationLanguagesSelector);
  const recipientCounts = useMemo(
    () => countTotalRecipients(notificationLanguages),
    [notificationLanguages]
  );

  useEffect(() => {
    dispatch(setNotificationSummaryData(recipientCounts));
  }, [dispatch, recipientCounts]);

  return (
    <>
      <Email hasRecipients={Boolean(recipientCounts.totalEmailRecipients)} />
      <Divider sx={{ my: 2 }} />
      <Sms hasRecipients={Boolean(recipientCounts.totalSmsRecipients)} />
      {/*<Divider sx={{ my: 2 }} />*/}
      {/*<Push (will be supported in the future) */}
      {/*  hasRecipients={Boolean(recipientCounts.totalPushRecipients)}*/}
      {/*  templatePushData={push}*/}
      {/*/>*/}
    </>
  );
};
