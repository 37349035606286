import type { FC } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, CardHeader, Icon, Layout, Loadable } from '@fleet/shared';
import {
  Divider,
  Step as MuiStep,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { NotificationFilters } from './filters/NotificationFilters';
import { NotificationContent } from './content/NotificationContent';
import { NotificationAddressees } from 'routes/SendNotifications/NotificationAddressees';
import { TransButton } from 'i18n/trans/button';
import { NotificationsSummary } from 'routes/SendNotifications/NotificationsSummary';
import { useDispatch, useSelector } from 'store/utils';
import { activeStepSelector } from 'features/notifications/notificationsSelectors';
import { TransLabel } from 'i18n/trans/label';
import { notificationsLoadingSelector } from 'features/loading/loadingSelectors';
import { TransTitle } from 'i18n/trans/title';
import { useLocation } from 'react-router';
import {
  getAddresseesByProblematicPassengers,
  getAddresseesByReallocationId,
  setActiveStep,
} from 'features/notifications/notificationsActions';
import { Step } from 'consts';
import _noop from 'lodash/noop';

const steps = [
  <TransLabel i18nKey="notificationFilters" />,
  <TransLabel i18nKey="notificationAddressees" />,
  <TransLabel i18nKey="notificationContent" />,
  <TransLabel i18nKey="notificationSummary" />,
];

export const SendNotifications: FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(notificationsLoadingSelector);
  const activeStep = useSelector(activeStepSelector);
  const { state: { carrierId, processId, problematicPassengerIds } = {} } =
    useLocation<
      | {
          carrierId?: string;
          processId?: string;
          problematicPassengerIds?: Array<number>;
        }
      | undefined
    >();
  const [isFetchingLastStep, setIsFetchingLastStep] = useState(
    Boolean(carrierId && (processId || problematicPassengerIds))
  );

  useEffect(() => {
    const fetchAddressees = async () => {
      let callback: (() => Promise<void>) | null = null;

      if (carrierId && processId) {
        callback = async () => {
          await dispatch(
            getAddresseesByReallocationId({ carrierId, processId })
          ).unwrap();
        };
      } else if (carrierId && problematicPassengerIds) {
        callback = async () => {
          await dispatch(
            getAddresseesByProblematicPassengers({
              carrierId,
              problematicPassengerIds,
            })
          ).unwrap();
        };
      }

      if (callback) {
        try {
          await callback();
          dispatch(setActiveStep(Step.Content));
        } catch (error) {
          _noop(error);
        } finally {
          setIsFetchingLastStep(false);
        }
      }
    };

    fetchAddressees();
    window.history.replaceState({}, '');
  }, [carrierId, dispatch, problematicPassengerIds, processId]);

  const stepComponents = useMemo(
    () => ({
      [Step.Filters]: <NotificationFilters />,
      [Step.Addressees]: <NotificationAddressees />,
      [Step.Content]: <NotificationContent />,
      [Step.Summary]: <NotificationsSummary />, // hidden from stepper
    }),
    []
  );

  return (
    <Loadable loading={isLoading || isFetchingLastStep}>
      <Layout
        header={
          <CardHeader
            sx={{ p: 3, textAlign: 'right' }}
            title={<TransTitle i18nKey="sendNotifications" />}
          >
            <Button
              sx={{ padding: 0, alignSelf: 'end' }}
              disabled
              variant="text"
              size="small"
              startIcon={<Icon name="question" />}
            >
              <Typography variant="body2">
                <TransButton i18nKey="help" />
              </Typography>
            </Button>
          </CardHeader>
        }
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '1156px',
          margin: 'auto',
        }}
        height="fit"
        data-testid="send-notifications-wizard"
      >
        <>
          <Stepper activeStep={activeStep} alternativeLabel sx={{ p: 3 }}>
            {steps.slice(0, 3).map((label, i) => (
              <MuiStep key={i}>
                <StepLabel>{label}</StepLabel>
              </MuiStep>
            ))}
          </Stepper>
          <Divider sx={{ borderWidth: '1px' }} />
          {stepComponents[activeStep]}
        </>
      </Layout>
    </Loadable>
  );
};
