import { createReducer } from '@reduxjs/toolkit';
import { PostProblematicPassengersResponse } from 'dto/api/deviations-management';
import { ProblematicPassengersFilter } from 'dto/problematicPassengers';
import {
  clearProblematicPassengers,
  getProblematicPassengers,
  setProblematicPassengersFilter,
} from 'features/problematicPassengers/problematicPassengersActions';

export interface CommonState {
  filter: Partial<ProblematicPassengersFilter>;
  list?: PostProblematicPassengersResponse;
}

const initialState: CommonState = {
  filter: {},
};

export const problematicPassengersReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(setProblematicPassengersFilter, (state, action) => {
        state.filter = action.payload;
      })
      .addCase(clearProblematicPassengers, (state) => {
        state.list = undefined;
      })
      .addCase(getProblematicPassengers.fulfilled, (state, action) => {
        state.list = action.payload;
      });
  }
);
