import { Icon, SelectField, SelectOption, useFormContext } from '@fleet/shared';
import { SelectValue } from '@fleet/shared/mui/Select';
import { IconButton, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Admission,
  ReAccommodateDetailedTripStop,
} from 'dto/api/deviations-management';
import { reAccommodateMismatchesDictionarySelector } from 'features/reAccommodate/reAccommodateSelectors';
import { FC, useCallback, useMemo } from 'react';
import {
  getOuterNewStopFieldName,
  isOptionDisabled,
} from 'routes/PassengersAllocation/ReAccommodate/SelectTrip/Mismatch/StopField/utils';
import { useSelector } from 'store/utils';

const useStyles = makeStyles({
  root: {
    '& .MuiInputBase-root': {
      fontSize: '0.75rem',
      '& > .MuiInputBase-input.MuiFilledInput-input': {
        padding: 0,
      },
    },
  },
});

interface MismatchModalStopSelectProps {
  isNewOrigin: boolean;
  admissionNumber: Admission['admissionNumber'];
  postfix: string;
  stopOptions: Array<SelectOption<SelectValue>>;
  outerValue: ReAccommodateDetailedTripStop['routeStopId'];
}

export const MismatchModalStopSelect: FC<MismatchModalStopSelectProps> = ({
  isNewOrigin,
  admissionNumber,
  postfix,
  stopOptions,
  outerValue,
}) => {
  const classes = useStyles();
  const form =
    useFormContext<
      Record<
        Admission['admissionNumber'],
        ReAccommodateDetailedTripStop['routeStopId']
      >
    >();
  const mismatchesDictionary = useSelector(
    reAccommodateMismatchesDictionarySelector
  );

  const name = useMemo(
    () => `${admissionNumber}_${postfix}`,
    [admissionNumber, postfix]
  );

  const stopOptionsIndex = useMemo(
    () =>
      stopOptions.reduce((acc, item, index) => {
        if (item.value) {
          acc[item.value] = index;
        }

        return acc;
      }, {} as Record<string, number>),
    [stopOptions]
  );

  const options = useMemo(
    () =>
      stopOptions.map((option, index) => ({
        ...option,
        disabled: isOptionDisabled({
          isNewOrigin,
          index,
          outerIndex: stopOptionsIndex[outerValue],
          length: stopOptions.length,
        }),
      })),
    [isNewOrigin, outerValue, stopOptions, stopOptionsIndex]
  );

  const handleCopy = useCallback(() => {
    const { values } = form.getState();
    const currentValue = values[name];
    const currentValueIndex = stopOptionsIndex[currentValue];

    const keysToChange = Object.entries(mismatchesDictionary).reduce(
      (acc, [key, item]) => {
        const property = isNewOrigin ? 'originStop' : 'destinationStop';
        const currentOuterValue =
          values[getOuterNewStopFieldName(item.admissionNumber, isNewOrigin)];
        const currentOuterValueIndex = stopOptionsIndex[currentOuterValue];

        if (
          currentValue &&
          admissionNumber !== key &&
          mismatchesDictionary[admissionNumber]?.journeyLeg[property].id ===
            item.journeyLeg[property].id &&
          !isOptionDisabled({
            isNewOrigin,
            index: currentValueIndex,
            outerIndex: currentOuterValueIndex,
            length: stopOptions.length,
          })
        ) {
          acc.push(`${item.admissionNumber}_${postfix}`);
        }

        return acc;
      },
      [] as Array<Admission['admissionNumber']>
    );

    form.batch(() => {
      keysToChange.forEach((key) => form.change(key, currentValue));
    });
  }, [
    admissionNumber,
    form,
    isNewOrigin,
    mismatchesDictionary,
    name,
    postfix,
    stopOptions.length,
    stopOptionsIndex,
  ]);

  return (
    <Stack direction="row" gap={0.5}>
      <SelectField
        name={name}
        options={options}
        className={classes.root}
        showEmptyOption
      />
      <IconButton onClick={handleCopy}>
        <Icon name="clone" />
      </IconButton>
    </Stack>
  );
};
