import React from 'react';
import { createTrans } from './index';
import { Trans } from 'react-i18next';

export const TransLabel = createTrans({
  ancillaries: <Trans i18nKey="label.ancillaries" defaults="Ancillaries" />,
  bookingNumber: (
    <Trans i18nKey="label.bookingNumber" defaults="Booking number" />
  ),
  carrier: <Trans i18nKey="label.carrier" defaults="Carrier" />,
  departingBetweenChosenStops: (
    <Trans
      i18nKey="label.departingBetweenChosenStops"
      defaults="Departing between chosen stops"
    />
  ),
  displaySelectedAncillaries: (
    <Trans
      i18nKey="label.displaySelectedAncillaries"
      defaults="Display selected ancillaries"
    />
  ),
  displaySelectedLines: (
    <Trans
      i18nKey="label.displaySelectedLines"
      defaults="Display selected lines"
    />
  ),
  displaySelectedStops: (
    <Trans
      i18nKey="label.displaySelectedStops"
      defaults="Display selected stops"
    />
  ),
  displaySelectedTrips: (
    <Trans
      i18nKey="label.displaySelectedTrips"
      defaults="Display selected trips"
    />
  ),
  fallbackLanguage: (
    <Trans i18nKey="label.fallbackLanguage" defaults="Fallback language" />
  ),
  firstName: <Trans i18nKey="label.firstName" defaults="First name" />,
  fromStops: <Trans i18nKey="label.fromStops" defaults="From stops" />,
  fromToDeparture: (
    <Trans
      i18nKey="label.fromToDeparture"
      defaults="Departure times (from-to)"
    />
  ),
  inventoryClasses: (
    <Trans i18nKey="label.inventoryClasses" defaults="Inventory classes" />
  ),
  lastName: <Trans i18nKey="label.lastName" defaults="Last name" />,
  lines: <Trans i18nKey="label.lines" defaults="Lines" />,
  notificationAddressees: (
    <Trans
      i18nKey="label.notificationAddressees"
      defaults="Notification addressees"
    />
  ),
  notificationBasedOn: (
    <Trans
      i18nKey="label.notificationBasedOn"
      defaults="Notification based on"
    />
  ),
  notificationContent: (
    <Trans
      i18nKey="label.notificationContent"
      defaults="Notification content"
    />
  ),
  notificationFilters: (
    <Trans
      i18nKey="label.notificationFilters"
      defaults="Notification filters"
    />
  ),
  notificationSummary: (
    <Trans
      i18nKey="label.notificationSummary"
      defaults="Notification summary"
    />
  ),
  placeProperties: (
    <Trans i18nKey="label.placeProperties" defaults="Place properties" />
  ),
  sendNotificationTo: (
    <Trans i18nKey="label.sendNotificationTo" defaults="Send notification to" />
  ),
  service: <Trans i18nKey="label.service" defaults="Service" />,
  textHtmlContent: (
    <Trans i18nKey="label.textHtmlContent" defaults="Content (text/html)" />
  ),
  textPlainContent: (
    <Trans i18nKey="label.textPlainContent" defaults="Content (text/plain)" />
  ),
  ticketNumber: <Trans i18nKey="label.ticketNumber" defaults="Ticket number" />,
  toStops: <Trans i18nKey="label.toStops" defaults="To stops" />,
  travelCardNumber: (
    <Trans i18nKey="label.travelCardNumber" defaults="Travel card number" />
  ),
  travellingBetweenChosenStops: (
    <Trans
      i18nKey="label.travellingBetweenChosenStops"
      defaults="Travelling between chosen stops"
    />
  ),
  trips: <Trans i18nKey="label.trips" defaults="Trips" />,
  vehicleProfile: (
    <Trans i18nKey="label.vehicleProfile" defaults="Vehicle profile" />
  ),
});
