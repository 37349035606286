import { GetLinesSearchParams } from 'dto/api/line-management';
import { Grid, Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import React, { FC } from 'react';
import {
  BusinessEntityType,
  Button,
  FormProvider,
  Icon,
  makeClassificationOptions,
  SelectOwnerField,
  TextField,
  useBusinessEntitiesByType,
  useForm,
} from '@fleet/shared';
import { useCarrierInitValue } from 'hooks/useCarrierInitValue';
import { useSelector } from 'store/utils';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';

interface LinesSearchFormProps {
  onSubmit: (filter: Partial<GetLinesSearchParams>) => void;
}

export const LinesSearchForm: FC<LinesSearchFormProps> = ({ onSubmit }) => {
  const businessEntities = useSelector(businessEntitiesSelector);
  const { CARRIER } = useBusinessEntitiesByType(businessEntities);
  const carrierOptions = makeClassificationOptions(CARRIER);
  const currentBusinessEntityId = useCarrierInitValue(carrierOptions);

  const { form, handleSubmit, submitting } = useForm<GetLinesSearchParams>({
    onSubmit,
    subscription: { dirty: true, submitting: true },
    initialValues: {
      carrierId: currentBusinessEntityId,
    },
  });

  const handleReset = () => {
    form.reset({
      carrierId: currentBusinessEntityId,
    });
  };

  return (
    <FormProvider form={form}>
      <form onSubmit={handleSubmit}>
        <Grid container columns={3} spacing={2} alignItems="flex-end">
          <Grid item xs={1}>
            <SelectOwnerField
              name={'carrierId'}
              businessEntities={businessEntities}
              allowedBusinessEntityTypes={[BusinessEntityType.CARRIER]}
              disabled
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              name="number"
              label={<TransField i18nKey="lineNumber" />}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField name="name" label={<TransField i18nKey="lineName" />} />
          </Grid>
          <Grid item xs="auto" sx={{ ml: 'auto' }} alignItems="flex-end">
            <Stack direction="row" spacing={2}>
              <Button
                sx={{ whiteSpace: 'nowrap' }}
                variant="text"
                onClick={handleReset}
              >
                <TransButton i18nKey="resetFilters" />
              </Button>

              <Button
                variant="contained"
                type="submit"
                startIcon={<Icon name="search" size={20} />}
                disabled={submitting}
              >
                <TransButton i18nKey="search" />
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
