import React, { useCallback, useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';
import {
  makeClassificationOptions,
  PeriodField,
  SearchField,
  SelectField,
  SelectOption,
  StopsSelect,
  TextField,
  TimeUnit,
  useBusinessEntitiesByType,
  useFormContext,
} from '@fleet/shared';
import { TransLabel } from 'i18n/trans/label';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { Classification, ClassificationGroup } from 'dto/classification';
import { useSelector } from 'store/utils';
import { selectCurrentBusinessEntityId } from 'features/common/commonSelectors';
import { TransField } from 'i18n/trans/field';
import { AncillariesModal } from 'routes/SendNotifications/filters/Modals/AncillariesModal';
import { LinesModal } from 'routes/SendNotifications/filters/Modals/LinesModal';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { useServiceOptions } from 'hooks/useServiceOptions';
import { TransButton } from 'i18n/trans/button';
import { makeStyles } from '@mui/styles';
import { TripsSelectionField } from 'components/TripsSelectionField/TripsSearchField';
import { useOnFilteringMethodOptions } from 'hooks/useOnFilteringMethodOptions';
import { AddresseesFilter } from 'dto/notificationContent';
import { OdFilteringMethodType } from 'dto/passengersAllocation';

const useStyles = makeStyles((theme) => ({
  modalFieldBorder: {
    '& > div': {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '4px',
      padding: '0 8px',
    },
  },
}));

export const NotificationFiltersFields = () => {
  const classes = useStyles();
  const form = useFormContext<AddresseesFilter>();
  const { tripIds, fromStops, toStops } = form.getState().values;
  const businessEntities = useSelector(businessEntitiesSelector);
  const { CARRIER } = useBusinessEntitiesByType(businessEntities);
  const carrierOptions = makeClassificationOptions(CARRIER);
  const currentBusinessEntityId = useSelector(selectCurrentBusinessEntityId);
  const serviceOptions = useServiceOptions(currentBusinessEntityId);
  const odFilteringMethodOptions = useOnFilteringMethodOptions();

  const seatPropertyOptions = useClassificationOptions(
    ClassificationGroup.SEAT_PROPERTY
  );
  const compartmentPropertyOptions = useClassificationOptions(
    ClassificationGroup.COMPARTMENT_PROPERTY
  );
  const bedPropertyOptions = useClassificationOptions(
    ClassificationGroup.BED_PROPERTY
  );
  const inventoryClassOptions = useClassificationOptions(
    Classification.INVENTORY_CLASS
  );

  const isOdFilteringMethodVisible = useMemo(
    () =>
      Boolean(fromStops?.size === 1 && toStops?.size === 1 && tripIds?.length),
    [fromStops?.size, toStops?.size, tripIds?.length]
  );

  const placePropertyOptions = [
    ...seatPropertyOptions,
    ...compartmentPropertyOptions,
    ...bedPropertyOptions,
  ];

  useEffect(() => {
    form.change(
      'odFilteringMethod',
      isOdFilteringMethodVisible ? OdFilteringMethodType.EXACT : undefined
    );
  }, [form, isOdFilteringMethodVisible]);

  const handleGroupBy = useCallback((option: SelectOption<string>) => {
    if (!option.value) {
      return '';
    }
    return (
      {
        SEAT_PROPERTY: 'Seat property',
        COMPARTMENT_PROPERTY: 'Compartment property',
        BED_PROPERTY: 'Bed property',
      }[option.value.split('.')[0]] || ''
    );
  }, []);

  return (
    <Grid
      container
      columns={1}
      spacing={2}
      sx={{ width: '800px', mx: 'auto', py: 2 }}
      data-testid="notifications-filters-fields"
    >
      <Grid item xs={1}>
        <SelectField
          label={<TransLabel i18nKey="carrier" />}
          name="carrierId"
          options={carrierOptions}
          defaultValue={currentBusinessEntityId}
          disabled
        />
      </Grid>
      <Grid item xs={1}>
        <SearchField
          modal={LinesModal}
          description={<TransButton i18nKey="selectTrip" />}
          label={<TransLabel i18nKey="lines" />}
          name="lineIds"
          className={classes.modalFieldBorder}
        />
      </Grid>
      <Grid item xs={1}>
        <SelectField
          label={<TransLabel i18nKey="service" />}
          name="serviceCodes"
          options={serviceOptions}
          multiple
        />
      </Grid>
      <Grid item xs={1}>
        <PeriodField
          from={{
            name: 'departureFrom',
            label: <TransField i18nKey="departureFrom" />,
          }}
          to={{
            name: 'departureTo',
            label: <TransField i18nKey="departureTo" />,
          }}
          maxRange={{ [TimeUnit.DAYS]: 7 }}
        />
      </Grid>
      <Grid item xs={1}>
        <TripsSelectionField
          label={<TransLabel i18nKey="trips" />}
          name="tripIds"
          className={classes.modalFieldBorder}
        />
      </Grid>
      <Grid item xs={1}>
        <StopsSelect
          name="fromStops"
          label={<TransLabel i18nKey="fromStops" />}
          className={classes.modalFieldBorder}
          allowSearch
          multiple
        />
      </Grid>
      <Grid item xs={1}>
        <StopsSelect
          name="toStops"
          label={<TransLabel i18nKey="toStops" />}
          className={classes.modalFieldBorder}
          allowSearch
          multiple
        />
      </Grid>
      <Grid item xs={1}>
        <SelectField
          name="odFilteringMethod"
          label={<TransField i18nKey="odFilteringMethod" />}
          options={odFilteringMethodOptions}
          disabled={!isOdFilteringMethodVisible}
        />
      </Grid>
      <Grid item xs={1}>
        <SelectField
          name="inventoryClassIds"
          options={inventoryClassOptions}
          label={<TransLabel i18nKey="inventoryClasses" />}
          multiple
        />
      </Grid>
      <Grid item xs={1}>
        <SelectField
          label={<TransLabel i18nKey="placeProperties" />}
          name="placePropertyIds"
          options={placePropertyOptions}
          multiple
          showEmptyOption
          groupBy={handleGroupBy}
        />
      </Grid>
      <Grid item xs={1}>
        <SearchField
          modal={AncillariesModal}
          description={<TransButton i18nKey="selectTrip" />}
          label={<TransLabel i18nKey="ancillaries" />}
          name="ancillaryIds"
          className={classes.modalFieldBorder}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          name="firstName"
          label={<TransLabel i18nKey="firstName" />}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField name="lastName" label={<TransLabel i18nKey="lastName" />} />
      </Grid>
      <Grid item xs={1}>
        <TextField
          name="bookingNumber"
          label={<TransLabel i18nKey="bookingNumber" />}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          name="ticketNumber"
          label={<TransLabel i18nKey="ticketNumber" />}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          name="travelCardNumber"
          label={<TransLabel i18nKey="travelCardNumber" />}
        />
      </Grid>
    </Grid>
  );
};
